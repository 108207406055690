import React, { useState } from 'react';
//import { useHistory } from 'react-router-dom'; // Importer useHistory si vous utilisez react-router-dom pour la navigation
import Header from './components/Header'; // Importation du composant Header
import Footer from './components/Footer'; 

const CartComponent = () => {
  const initialProducts = [
    {
      id: 1,
      name: "Classic Simple Backpack",
      price: 40.00,
      currency: "FCFA",
      imageUrl: "assets/images/shop/12.jpg",
      quantity: 1
    },
    {
      id: 2,
      name: "MELOXICAM",
      price: 60.00,
      currency: "FCFA",
      imageUrl: "assets/images/demos/demo1/products/MELOXICAM.png",
      quantity: 1
    }
  ];

  const [products, setProducts] = useState(initialProducts);
  //const history = useHistory(); // Utilisation de useHistory pour la navigation

  const handleQuantityChange = (id, delta) => {
    setProducts(products.map(product => 
      product.id === id 
      ? { ...product, quantity: Math.max(product.quantity + delta, 1) }
      : product
    ));
  };

  const handleInputChange = (id, value) => {
    const newQuantity = parseInt(value, 10);
    if (!isNaN(newQuantity) && newQuantity > 0) {
      setProducts(products.map(product =>
        product.id === id
        ? { ...product, quantity: newQuantity }
        : product
      ));
    }
  };

  const handleRemoveProduct = (id) => {
    setProducts(products.filter(product => product.id !== id));
  };

  const handleCheckout = () => {
    // Sauvegarder le panier dans le localStorage
    localStorage.setItem('cart', JSON.stringify(products));
    console.log(JSON.stringify(products));
    // Rediriger vers la page de paiement
    //history.push('/checkout');
  };

  return (
    <div id="otherpage">
      <Header />
      <div className="page-header mb-5 mt-5">
            <div className="container">
              <h1 className="page-title mb-0">Mon panier</h1>
            </div>
          </div>
      <div class="page-content cart">
      <div className="container bg-white">

      

        <div className="row gutter-lg mb-10 pt-4">
          <div className="col-lg-8 pr-lg-4 mb-6">
            {products.length === 0 ? (
              <p>Votre panier est vide.</p>
            ) : (
              <table className="shop-table cart-table">
                <thead>
                  <tr>
                    <th className="product-name"><span>Product</span></th>
                    <th></th>
                    <th className="product-price"><span>Price</span></th>
                    <th className="product-quantity"><span>Quantity</span></th>
                    <th className="product-subtotal"><span>Subtotal</span></th>
                  </tr>
                </thead>
                <tbody>
                  {products.map((product) => (
                    <tr key={product.id}>
                      <td className="product-thumbnail">
                        <div className="p-relative">
                          <a href="product-default.html">
                            <figure>
                              <img src={product.imageUrl} alt="product" width="300" height="338" />
                            </figure>
                          </a>
                          <button
                            type="button"
                            className="btn btn-close"
                            onClick={() => handleRemoveProduct(product.id)}>
                            <i className="fas fa-times"></i>
                          </button>
                        </div>
                      </td>
                      <td className="product-name">
                        <a href="product-default.html">
                          {product.name}
                        </a>
                      </td>
                      <td className="product-price"><span className="amount">{product.price.toFixed(2)} {product.currency}</span></td>
                      <td class="product-quantity">
                            <div class="input-group">
                                <input class="quantity form-control" type="number" min="1" max="100000"
                                value={product.quantity}
                                onChange={(e) => handleInputChange(product.id, e.target.value)}/>
                                <button class="quantity-plus w-icon-plus" onClick={() => handleQuantityChange(product.id, 1)}></button>
                                <button class="quantity-minus w-icon-minus" onClick={() => handleQuantityChange(product.id, -1)}></button>
                            </div>
                        </td>
                      <td className="product-subtotal">
                        <span className="amount">{(product.price * product.quantity).toFixed(2)} {product.currency}</span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}

            <div className="cart-action mb-6">
              <a href="#" className="btn btn-dark btn-rounded btn-icon-left btn-shopping mr-auto"><i className="w-icon-long-arrow-left"></i>Continue Shopping</a>
              <button type="button" className="btn btn-rounded btn-default btn-clear" name="clear_cart" value="Clear Cart">Clear Cart</button>
              <button type="button" className="btn btn-rounded btn-update disabled" name="update_cart" value="Update Cart">Update Cart</button>
            </div>

            <form className="coupon">
              <h5 className="title coupon-title font-weight-bold text-uppercase">Coupon Discount</h5>
              <input type="text" className="form-control mb-4" placeholder="Enter coupon code here..." required />
              <button className="btn btn-dark btn-outline btn-rounded">Apply Coupon</button>
            </form>
          </div>

          <div className="col-lg-4 sticky-sidebar-wrapper">
            <div className="pin-wrapper" style={{ height: '791.375px' }}>
              <div className="sticky-sidebar" style={{ borderBottom: '0px none rgb(102, 102, 102)', width: '393.32px' }}>
                <div className="cart-summary mb-4">
                  <h3 className="cart-title text-uppercase">Cart Totals</h3>
                  <div className="cart-subtotal d-flex align-items-center justify-content-between">
                    <label className="ls-25">Subtotal</label>
                    <span>{products.reduce((total, product) => total + (product.price * product.quantity), 0).toFixed(2)} {products.length > 0 ? products[0].currency : ''}</span>
                  </div>

                  <hr className="divider" />

                  <ul className="shipping-methods mb-2">
                    <li>
                      <label className="shipping-title text-dark font-weight-bold">Shipping</label>
                    </li>
                    <li>
                      <div className="custom-radio">
                        <input type="radio" id="free-shipping" className="custom-control-input" name="shipping" />
                        <label htmlFor="free-shipping" className="custom-control-label color-dark">Free Shipping</label>
                      </div>
                    </li>
                    <li>
                      <div className="custom-radio">
                        <input type="radio" id="local-pickup" className="custom-control-input" name="shipping" />
                        <label htmlFor="local-pickup" className="custom-control-label color-dark">Local Pickup</label>
                      </div>
                    </li>
                    <li>
                      <div className="custom-radio">
                        <input type="radio" id="flat-rate" className="custom-control-input" name="shipping" />
                        <label htmlFor="flat-rate" className="custom-control-label color-dark">Flat rate: 5.00 FCFA</label>
                      </div>
                    </li>
                  </ul>

                  {/* <div className="shipping-calculator">
                    <p className="shipping-destination lh-1">Shipping to <strong>CA</strong>.</p>

                    <form className="shipping-calculator-form">
                      <div className="form-group">
                        <div className="select-box">
                          <select name="country" className="form-control form-control-md">
                            <option value="default" selected>United States (US)</option>
                            <option value="us">United States</option>
                            <option value="uk">United Kingdom</option>
                            <option value="fr">France</option>
                            <option value="aus">Australia</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="select-box">
                          <select name="state" className="form-control form-control-md">
                            <option value="default" selected>California</option>
                            <option value="ohaio">Ohaio</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group">
                        <input className="form-control form-control-md" type="text" name="town-city" placeholder="Town / City" />
                      </div>
                      <div className="form-group">
                        <input className="form-control form-control-md" type="text" name="zipcode" placeholder="ZIP" />
                      </div>
                      <button type="button" className="btn btn-dark btn-outline btn-rounded">Update Totals</button>
                    </form>
                  </div> */}

                  <hr className="divider mb-6" />
                  <div className="order-total d-flex justify-content-between align-items-center">
                    <label>Total</label>
                    <span className="ls-50">{products.reduce((total, product) => total + (product.price * product.quantity), 0).toFixed(2)} {products.length > 0 ? products[0].currency : ''}</span>
                  </div>
                  <button
                    className="btn btn-block btn-dark btn-icon-right btn-rounded btn-checkout"
                    onClick={handleCheckout}
                    disabled={products.length === 0}
                  >
                    Proceed to checkout<i className="w-icon-long-arrow-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      
      <Footer />
    </div>
  );
};

export default CartComponent;
