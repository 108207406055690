import React from 'react';
import { useTheme } from '../../contexts/ThemeProvider';
import LogoFooter from './LogoFooter';
import { NavLink } from 'react-router-dom';


function Footer() {
  const { theme, toggleTheme } = useTheme();



  return (
    <>
        
        <footer className="footer">
            <div className="footer-middle">
                <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-4 mb-2 mb-md-0">
                    <div className="widget widget-contact-info">
                        <h4 className="widget-title">Informations de contact</h4>
                        <ul className="contact-info">
                        <li>
                            <span className="contact-info-label">Adresse :</span>1234 Nom de Rue, Ville, Angleterre
                        </li>
                        <li>
                            <span className="contact-info-label">Téléphone :</span>
                            <a href="tel:">(123) 456-7890</a>
                        </li>
                        <li>
                            <span className="contact-info-label">Email :</span>{" "}
                            <a href="https://portotheme.com/cdn-cgi/l/email-protection#6409050d0824011c05091408014a070b09">
                            <span
                                className="__cf_email__"
                                data-cfemail="8ce1ede5e0cce9f4ede1fce0e9a2efe3e1"
                            >
                                [email&nbsp;protégé]
                            </span>
                            </a>
                        </li>
                        <li>
                            <span className="contact-info-label">Jours/heures de travail :</span>
                            Lun - Dim / 9h00 - 20h00
                        </li>
                        </ul>
                        <div className="social-icons">
                        <a
                            href="#"
                            className="social-icon social-facebook icon-facebook"
                            target="_blank"
                            title="Facebook"
                        />
                        <a
                            href="#"
                            className="social-icon social-twitter icon-twitter"
                            target="_blank"
                            title="Twitter"
                        />
                        <a
                            href="#"
                            className="social-icon social-instagram icon-instagram"
                            target="_blank"
                            title="Instagram"
                        />
                        </div>
                        {/* Fin .social-icons */}
                    </div>
                    {/* Fin .widget */}
                    </div>
                    {/* Fin .col-lg-3 */}
                    <div className="col-lg-9 col-md-8">
                    <div className="widget widget-newsletter">
                        <h4 className="widget-title m-b-1 pb-2">Abonnez-vous à la newsletter</h4>
                        <div className="row">
                        <div className="col-lg-6">
                            <p className="pt-2 mb-lg-0">
                            Recevez toutes les dernières informations sur les événements, les soldes et les offres.
                            Inscrivez-vous à la newsletter aujourd'hui.
                            </p>
                        </div>
                        {/* Fin .col-lg-6 */}
                        <div className="col-lg-6">
                            <form action="#" className="d-flex mb-0 w-100">
                            <input
                                type="email"
                                className="form-control mb-0"
                                placeholder="Adresse email"
                                required=""
                            />
                            <input
                                type="submit"
                                className="btn btn-primary shadow-none ls-10"
                                defaultValue="S'abonner"
                            />
                            </form>
                        </div>
                        {/* Fin .col-lg-6 */}
                        </div>
                        {/* Fin .row */}
                    </div>
                    {/* Fin .widget */}
                    <div className="row pt-3">
                        <div className="col-sm-6 col-lg-5">
                        <div className="widget">
                            <h4 className="widget-title">Service Client</h4>
                            <div className="links link-parts row mb-0">
                            <ul className="link-part col-sm-6 mb-0">
                                <li>
                                <a href="demo5-about.html">À propos de nous</a>
                                </li>
                                <li>
                                <a href="demo5-contact.html">Nous contacter</a>
                                </li>
                                <li>
                                <a href="dashboard.html">Mon compte</a>
                                </li>
                            </ul>
                            <ul className="link-part col-sm-6">
                                <li>
                                <a  >Historique des commandes</a>
                                </li>
                                <li>
                                <a  >Recherche avancée</a>
                                </li>
                                <li>
                                <a href="login.html">Connexion</a>
                                </li>
                            </ul>
                            </div>
                        </div>
                        {/* Fin .widget */}
                        </div>
                        {/* Fin .col-sm-6 */}
                        <div className="col-sm-6 col-lg-7">
                        <div className="widget">
                            <h4 className="widget-title">À propos de nous</h4>
                            <div className="links link-parts row mb-0">
                            <ul className="link-part col-xl-6 mb-0">
                                <li>
                                <a  >Modèle HTML ultra rapide</a>
                                </li>
                                <li>
                                <a  >Premier thème Ajax entièrement fonctionnel</a>
                                </li>
                                <li>
                                <a  >33 mises en page uniques pour la boutique</a>
                                </li>
                            </ul>
                            <ul className="link-part col-xl-6">
                                <li>
                                <a  >Panneau d'administration puissant</a>
                                </li>
                                <li>
                                <a  >Optimisé pour mobile &amp; Retina</a>
                                </li>
                            </ul>
                            </div>
                        </div>
                        {/* Fin .widget */}
                        </div>
                        {/* Fin .col-sm-6 */}
                    </div>
                    {/* Fin .row */}
                    </div>
                    {/* Fin .col-lg-9 */}
                </div>
                {/* Fin .row */}
                </div>
                {/* Fin .container */}
            </div>
            <div className="footer-bottom">
                <div className="container d-flex justify-content-between align-items-center flex-wrap">
                <p className="footer-copyright py-3 pr-4 mb-0 ls-n-10">
                    © Porto eCommerce. 2021. Tous droits réservés.
                </p>
                <img
                    src="assets/images/payments.png"
                    alt="méthodes de paiement"
                    className="footer-payments py-3"
                />
                </div>
                {/* Fin .container */}
            </div>
        </footer>

    </>
  );
}

export default Footer;
