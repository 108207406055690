import React, { useState, useEffect } from 'react';
import ReactImageMagnify from 'react-image-magnify';
import classNames from 'classnames';
import Header from './components/Header'; // Importation du composant Header
import Footer from './components/Footer'; 
import ProductSlider from './ProductSlider'; 

const products = [
  {
    id: 1,
    title: "ALBEN ORAL 10 1L",
    brand: "Brand-1",
    category: "Electronics",
    sku: "MS46891340",
    price: 40.00,
    images: [
      'assets/images/products/ALBEN_ORAL 10_1L_PROFIL.jpg',
      'assets/images/products/AMINOVIT_PLUS_1KG_FACE.jpg',
      'assets/images/products/ALBEN_ORAL 10_1L_PROFIL.jpg',
      'assets/images/products/AMINOVIT_PLUS_1KG_FACE.jpg',
      'assets/images/products/ALBEN_ORAL 10_1L_PROFIL.jpg',
      'assets/images/products/AMINOVIT_PLUS_1KG_FACE.jpg',
    ],
    shortDescription: [
      "Ultrices eros in cursus turpis massa cursus mattis.",
      "Volutpat ac tincidunt vitae semper quis lectus.",
      "Aliquam id diam maecenas ultricies mi eget mauris."
    ],
    colors: ["#ffcc01", "#ca6d00", "#1c93cb", "#ccc", "#333"],
    sizes: ["Small", "Medium", "Large", "Extra Large"],
    reviews: 3,
    rating: 80,
  },
  {
    id: 2,
    title: "ALBENVET",
    brand: "Brand-1",
    category: "Electronics",
    sku: "MS46891340",
    price: 40.00,
    images: [
      'assets/images/products/ALBENVET_FACE.jpg',
      'assets/images/products/ALBENVET_FACE.jpg',
      'assets/images/products/ALBENVET_FACE.jpg',
      'assets/images/products/ALBENVET_FACE.jpg',
      'assets/images/products/ALBENVET_FACE.jpg',
      'assets/images/products/ALBENVET_FACE.jpg',
    ],
    shortDescription: [
      "Ultrices eros in cursus turpis massa cursus mattis.",
      "Volutpat ac tincidunt vitae semper quis lectus.",
      "Aliquam id diam maecenas ultricies mi eget mauris."
    ],
    colors: ["#ffcc01", "#ca6d00", "#1c93cb", "#ccc", "#333"],
    sizes: ["Small", "Medium", "Large", "Extra Large"],
    reviews: 3,
    rating: 80,
  },
  // Ajoutez d'autres objets produit ici...
];

const ProductImageZoom = () => {
  const [selectedImage, setSelectedImage] = useState('');
  const [product, setProduct] = useState(null);
  const [activeTab, setActiveTab] = useState('product-tab-specification');

  useEffect(() => {
    const productId = localStorage.getItem('selectedProductId');
    if (productId) {
      const foundProduct = products.find(p => p.id === parseInt(productId, 10));
      if (foundProduct) {
        setProduct(foundProduct);
        setSelectedImage(foundProduct.images[0]);
      }
    }
  }, []);

  if (!product) {
    return <div>Product not found.</div>;
  }

  return (
    <>

    <div id="otherpage">
      <Header />
      <div className="page-content ">
        <div className="container">
          <div className="page-header mb-5 mt-5">
            <div className="container">
              <h1 className="page-title mb-0">Détail du produit</h1>
            </div>
          </div>
          <div className="row gutter-lg">
            <div className="main-content product-cards mb-5">
              <div className="product product-single row">
                  <div className="col-md-6 mb-6">
                    <div style={{ width: '100%', height: '400px', margin: '0 auto' }}>
                      <ReactImageMagnify
                        {...{
                          smallImage: {
                            alt: 'Product Image',
                            isFluidWidth: true,
                            src: selectedImage,
                          },
                          largeImage: {
                            src: selectedImage,
                            width: 1200,
                            height: 1400,
                          },
                          enlargedImageContainerDimensions: {
                            width: '100%',
                            height: '100%',
                          },
                          enlargedImagePosition: 'over',
                          lensStyle: { backgroundColor: 'rgba(0,0,0,.6)' },
                          shouldUsePositiveSpaceLens: true,
                          cursorStyleActive: 'crosshair',
                        }}
                      />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '100px' }}>
                      {product.images.map((image, index) => (
                        <img
                          key={index}
                          src={image}
                          alt={`Product ${index}`}
                          style={{ width: '50px', height: '50px', cursor: 'pointer', margin: '0 5px' }}
                          onClick={() => setSelectedImage(image)}
                        />
                      ))}
                    </div>
                  </div>
                <div className="col-md-6 mb-4 mb-md-6">
                  <div
                    className="product-details"
                    data-sticky-options="{'minWidth': 767}"
                  >
                    <h1 className="product-title">{product.title}</h1>
                    <div className="product-bm-wrapper">
                      <figure className="brand">
                        <img
                          src={`assets/images/products/brand/${product.brand}.jpg`}
                          alt="Brand"
                          width={102}
                          height={48}
                        />
                      </figure>
                      <div className="product-meta">
                        <div className="product-categories">
                          Category:
                          <span className="product-category">
                            <a  >{product.category}</a>
                          </span>
                        </div>
                        <div className="product-sku">
                          SKU: <span>{product.sku}</span>
                        </div>
                      </div>
                    </div>
                    <hr className="product-divider" />
                    <div className="product-price">
                      <ins className="new-price">{product.price.toFixed(2)}</ins>
                    </div>
                    <div className="ratings-container">
                      <div className="ratings-full">
                        <span className="ratings" style={{ width: `${product.rating}%` }} />
                        <span className="tooltiptext tooltip-top" />
                      </div>
                      <a
                        href="#product-tab-reviews"
                        className="rating-reviews scroll-to"
                      >
                        ({product.reviews} Reviews)
                      </a>
                    </div>
                    <div className="product-short-desc">
                      <ul className="list-type-check list-style-none">
                        {product.shortDescription.map((desc, index) => (
                          <li key={index}>{desc}</li>
                        ))}
                      </ul>
                    </div>
                    <hr className="product-divider" />
                    
                    <div className="product-form product-variation-form product-size-swatch">
                      <label className="mb-1">Size:</label>
                      <div className="flex-wrap d-flex align-items-center product-variations">
                        {product.sizes.map((size, index) => (
                          <a href="#" className="size" key={index}>
                            {size}
                          </a>
                        ))}
                      </div>
                      <a href="#" className="product-variation-clean">
                        Clean All
                      </a>
                    </div>
                    <div className="product-variation-price">
                      <span />
                    </div>
                    <div className="fix-bottom product-sticky-content sticky-content">
                      <div className="product-form container">
                        <div className="product-qty-form">
                          <div className="input-group">
                            <input
                              className="quantity form-control"
                              type="number"
                              min={1}
                              max={10000000}
                            />
                            <button className="quantity-plus w-icon-plus" />
                            <button className="quantity-minus w-icon-minus" />
                          </div>
                        </div>
                        <button className="btn btn-primary btn-cart">
                          <i className="w-icon-cart" />
                          <span>Add to Cart</span>
                        </button>
                      </div>
                    </div>

                    <div className="tab tab-nav-boxed tab-nav-underline product-tabs">
                        <ul className="nav nav-tabs" role="tablist">
                          <li className="nav-item">
                            <a className={classNames('nav-link', { active: activeTab === 'product-tab-description' })}
                              onClick={() => setActiveTab('product-tab-description')} >
                              Description
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className={classNames('nav-link', { active: activeTab === 'product-tab-specification' })}
                              onClick={() => setActiveTab('product-tab-specification')} >
                              Specification
                            </a>
                          </li>
                        </ul>
                        <div className="tab-content">
                          <div
                            className={classNames('tab-pane', { active: activeTab === 'product-tab-description' })}
                            id="product-tab-description"
                          >
                            <div className="row mb-4">
                              <div className="col-md-6 mb-5">
                                <h4 className="title tab-pane-title font-weight-bold mb-2">Detail</h4>
                                <p className="mb-4">
                                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt arcu cursus vitae congue mauris. Sagittis id consectetur purus ut. Tellus rutrum tellus pelle Vel pretium lectus quam id leo in vitae turpis massa.
                                </p>
                                <ul className="list-type-check">
                                  <li>Nunc nec porttitor turpis. In eu risus enim. In vitae mollis elit.</li>
                                  <li>Vivamus finibus vel mauris ut vehicula.</li>
                                  <li>Nullam a magna porttitor, dictum risus nec, faucibus sapien.</li>
                                </ul>
                              </div>
                              <div className="col-md-6 mb-5">
                                <div className="banner banner-video product-video br-xs">
                                  <figure className="banner-media">
                                    <a  >
                                      <img
                                        src="assets/images/products/video-banner-610x300.jpg"
                                        alt="banner"
                                        width={610}
                                        height={300}
                                        style={{ backgroundColor: "#bebebe" }}
                                      />
                                    </a>
                                    <a className="btn-play-video btn-iframe" href="assets/video/memory-of-a-woman.mp4" />
                                  </figure>
                                </div>
                              </div>
                            </div>
                            <div className="row cols-md-3">
                              <div className="mb-3">
                                <h5 className="sub-title font-weight-bold">
                                  <span className="mr-3">1.</span>Free Shipping &amp; Return
                                </h5>
                                <p className="detail pl-5">
                                  We offer free shipping for products on orders above 50 and offer free delivery for all orders in US.
                                </p>
                              </div>
                              <div className="mb-3">
                                <h5 className="sub-title font-weight-bold">
                                  <span>2.</span>Free and Easy Returns
                                </h5>
                                <p className="detail pl-5">
                                  We guarantee our products and you could get back all of your money anytime you want in 30 days.
                                </p>
                              </div>
                              <div className="mb-3">
                                <h5 className="sub-title font-weight-bold">
                                  <span>3.</span>Special Financing
                                </h5>
                                <p className="detail pl-5">
                                  Get 20%-50% off items over 50 for a month or over 250 for a year with our special credit card.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            className={classNames('tab-pane', { active: activeTab === 'product-tab-specification' })}
                            id="product-tab-specification"
                          >
                            <ul className="list-none">
                              <li>
                                <label>Model</label>
                                <p>Skysuite 320</p>
                              </li>
                              <li>
                                <label>Color</label>
                                <p>Black</p>
                              </li>
                              <li>
                                <label>Size</label>
                                <p>Large, Small</p>
                              </li>
                              <li>
                                <label>Guarantee Time</label>
                                <p>3 Months</p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                  </div>
                </div>
              </div>
              <section className='container'>
                  <div class="title-link-wrapper mb-4">
                      <h4 class="title text-left ">Produit de subtitution</h4>
                  </div>
                  <ProductSlider />
              </section>
            </div>
            {/* End of Main Content */}
            <aside className="sidebar product-sidebar sidebar-fixed right-sidebar sticky-sidebar-wrapper">
              <div className="sidebar-overlay" />
              <a className="sidebar-close" href="#">
                <i className="close-icon" />
              </a>
              <a href="#" className="sidebar-toggle d-flex d-lg-none">
                <i className="fas fa-chevron-left" />
              </a>
              <div className="sidebar-content scrollable">
                <div className="sticky-sidebar">
                  <div className="widget widget-icon-box mb-6">
                    <div className="icon-box icon-box-side">
                      <span className="icon-box-icon text-dark">
                        <i className="w-icon-truck" />
                      </span>
                      <div className="icon-box-content">
                        <h4 className="icon-box-title">Free Shipping &amp; Returns</h4>
                        <p>For all orders over 99</p>
                      </div>
                    </div>
                    <div className="icon-box icon-box-side">
                      <span className="icon-box-icon text-dark">
                        <i className="w-icon-bag" />
                      </span>
                      <div className="icon-box-content">
                        <h4 className="icon-box-title">Secure Payment</h4>
                        <p>We ensure secure payment</p>
                      </div>
                    </div>
                    <div className="icon-box icon-box-side">
                      <span className="icon-box-icon text-dark">
                        <i className="w-icon-money" />
                      </span>
                      <div className="icon-box-content">
                        <h4 className="icon-box-title">Money Back Guarantee</h4>
                        <p>Any back within 30 days</p>
                      </div>
                    </div>
                  </div>
                  {/* End of Widget Icon Box */}
                  
                </div>
              </div>
              {/* End of Sidebar Content */}
            </aside>
            {/* End of Sidebar */}
          </div>
        </div>
      </div>
      <Footer />
    </div>


    </>
  );
};

export default ProductImageZoom;
