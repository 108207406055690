import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from '../components/Header'; // Importation du composant Header
import Footer from '../components/Footer'; 


const About = () => {

  return (

    <>

    <div id='siteWebwrapper'>
      <div id="otherpage" cl>
        <Header />
        <div className="App">
          <div id='site-web' className="container justify-content">

          <>
          <main className="main about">
              <div className="container container-not-boxed">
                <div
                  className="page-header page-header-bg text-left"
                  style={{
                    background: '50%/cover #D4E1EA url("assets/images/page-header-bg.jpg")'
                  }}
                >
                  <h1>
                    <span>ABOUT US</span>
                    OUR COMPANY
                  </h1>
                  <a href="demo5-contact.html" className="btn btn-dark">
                    Contact
                  </a>
                </div>
                {/* End .container */}
              </div>
              {/* End .page-header */}
              <div className="container container-not-boxed">
                <nav aria-label="breadcrumb" className="breadcrumb-nav">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="demo4.html">
                        <i className="icon-home" />
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      About Us
                    </li>
                  </ol>
                </nav>
              </div>
              {/* End .container */}
              <div className="container">
                <div className="about-section">
                  <h2 className="subtitle">OUR STORY</h2>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum has been the industry's standard dummy text ever
                    since the 1500s, when an unknown printer took a galley of type and
                    scrambled it to make a type specimen book. It has survived not only five
                    centuries, but also the leap into electronic typesetting, remaining
                    essentially unchanged.
                  </p>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum has been the industry's standard dummy text ever
                    since the 1500s, when an unknown printer took a galley of type and
                    scrambled it to make a type specimen book.
                  </p>
                  <p className="lead">
                    “ Many desktop publishing packages and web page editors now use Lorem
                    Ipsum as their default model search for evolved over sometimes by
                    accident, sometimes on purpose ”
                  </p>
                </div>
                {/* End .container */}
              </div>
              {/* End .about-section */}
              <div className="mt-3 mb-2" />
              <div className="container">
                <div className="features-section">
                  <h2 className="subtitle">WHY CHOOSE US</h2>
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="feature-box bg-white">
                        <i className="icon-shipped" />
                        <div className="feature-box-content p-0">
                          <h3>Free Shipping</h3>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry. Lorem Ipsum has been the industr.
                          </p>
                        </div>
                        {/* End .feature-box-content */}
                      </div>
                      {/* End .feature-box */}
                    </div>
                    {/* End .col-lg-4 */}
                    <div className="col-lg-4">
                      <div className="feature-box bg-white">
                        <i className="icon-us-dollar" />
                        <div className="feature-box-content p-0">
                          <h3>100% Money Back Guarantee</h3>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry. Lorem Ipsum has been the industr.
                          </p>
                        </div>
                        {/* End .feature-box-content */}
                      </div>
                      {/* End .feature-box */}
                    </div>
                    {/* End .col-lg-4 */}
                    <div className="col-lg-4">
                      <div className="feature-box bg-white">
                        <i className="icon-online-support" />
                        <div className="feature-box-content p-0">
                          <h3>Online Support 24/7</h3>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry. Lorem Ipsum has been the industr.
                          </p>
                        </div>
                        {/* End .feature-box-content */}
                      </div>
                      {/* End .feature-box */}
                    </div>
                    {/* End .col-lg-4 */}
                  </div>
                  {/* End .row */}
                </div>
                {/* End .container */}
              </div>
              {/* End .features-section */}
              <div className="mt-3 mb-2" />
              <div className="container">
                <div className="testimonials-section">
                  <h2 className="subtitle text-center">HAPPY CLIENTS</h2>
                  <div
                    className="testimonials-carousel owl-carousel owl-theme images-left owl-loaded owl-drag"
                    data-owl-options="{
                        'margin': 20,
                              'lazyLoad': true,
                              'autoHeight': true,
                              'dots': false,
                              'responsive': {
                                  '0': {
                                      'items': 1
                                  },
                                  '992': {
                                      'items': 2
                                  }
                              }
                          }"
                  >
                    {/* End .testimonial */}
                    {/* End .testimonial */}
                    {/* End .testimonial */}
                    <div className="owl-stage-outer owl-height" style={{ height: 185 }}>
                      <div
                        className="owl-stage"
                        style={{
                          transform: "translate3d(-1160px, 0px, 0px)",
                          transition: "all",
                          width: 4060
                        }}
                      >
                        <div
                          className="owl-item cloned"
                          style={{ width: 560, marginRight: 20 }}
                        >
                          <div className="testimonial">
                            <div className="testimonial-owner">
                              <figure>
                                <img src="assets/images/clients/client2.png" alt="client" />
                              </figure>
                              <div>
                                <strong className="testimonial-title">Bob Smith</strong>
                                <span>SMARTWAVE CEO</span>
                              </div>
                            </div>
                            {/* End .testimonial-owner */}
                            <blockquote>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur elitad adipiscing
                                Cras non placerat mipsum dolor sit amet, consectetur elitad
                                adipiscing cas non placerat mi.
                              </p>
                            </blockquote>
                          </div>
                        </div>
                        <div
                          className="owl-item cloned"
                          style={{ width: 560, marginRight: 20 }}
                        >
                          <div className="testimonial">
                            <div className="testimonial-owner">
                              <figure>
                                <img src="assets/images/clients/client1.png" alt="client" />
                              </figure>
                              <div>
                                <strong className="testimonial-title">John Smith</strong>
                                <span>SMARTWAVE CEO</span>
                              </div>
                            </div>
                            {/* End .testimonial-owner */}
                            <blockquote>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur elitad adipiscing
                                Cras non placerat mipsum dolor sit amet, consectetur elitad
                                adipiscing cas non placerat mi.
                              </p>
                            </blockquote>
                          </div>
                        </div>
                        <div
                          className="owl-item active"
                          style={{ width: 560, marginRight: 20 }}
                        >
                          <div className="testimonial">
                            <div className="testimonial-owner">
                              <figure>
                                <img src="assets/images/clients/client1.png" alt="client" />
                              </figure>
                              <div>
                                <strong className="testimonial-title">John Smith</strong>
                                <span>SMARTWAVE CEO</span>
                              </div>
                            </div>
                            {/* End .testimonial-owner */}
                            <blockquote>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur elitad adipiscing
                                Cras non placerat mipsum dolor sit amet, consectetur elitad
                                adipiscing cas non placerat mi.
                              </p>
                            </blockquote>
                          </div>
                        </div>
                        <div
                          className="owl-item active"
                          style={{ width: 560, marginRight: 20 }}
                        >
                          <div className="testimonial">
                            <div className="testimonial-owner">
                              <figure>
                                <img src="assets/images/clients/client2.png" alt="client" />
                              </figure>
                              <div>
                                <strong className="testimonial-title">Bob Smith</strong>
                                <span>SMARTWAVE CEO</span>
                              </div>
                            </div>
                            {/* End .testimonial-owner */}
                            <blockquote>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur elitad adipiscing
                                Cras non placerat mipsum dolor sit amet, consectetur elitad
                                adipiscing cas non placerat mi.
                              </p>
                            </blockquote>
                          </div>
                        </div>
                        <div className="owl-item" style={{ width: 560, marginRight: 20 }}>
                          <div className="testimonial">
                            <div className="testimonial-owner">
                              <figure>
                                <img src="assets/images/clients/client1.png" alt="client" />
                              </figure>
                              <div>
                                <strong className="testimonial-title">John Smith</strong>
                                <span>SMARTWAVE CEO</span>
                              </div>
                            </div>
                            {/* End .testimonial-owner */}
                            <blockquote>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur elitad adipiscing
                                Cras non placerat mipsum dolor sit amet, consectetur elitad
                                adipiscing cas non placerat mi.
                              </p>
                            </blockquote>
                          </div>
                        </div>
                        <div
                          className="owl-item cloned"
                          style={{ width: 560, marginRight: 20 }}
                        >
                          <div className="testimonial">
                            <div className="testimonial-owner">
                              <figure>
                                <img src="assets/images/clients/client1.png" alt="client" />
                              </figure>
                              <div>
                                <strong className="testimonial-title">John Smith</strong>
                                <span>SMARTWAVE CEO</span>
                              </div>
                            </div>
                            {/* End .testimonial-owner */}
                            <blockquote>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur elitad adipiscing
                                Cras non placerat mipsum dolor sit amet, consectetur elitad
                                adipiscing cas non placerat mi.
                              </p>
                            </blockquote>
                          </div>
                        </div>
                        <div
                          className="owl-item cloned"
                          style={{ width: 560, marginRight: 20 }}
                        >
                          <div className="testimonial">
                            <div className="testimonial-owner">
                              <figure>
                                <img src="assets/images/clients/client2.png" alt="client" />
                              </figure>
                              <div>
                                <strong className="testimonial-title">Bob Smith</strong>
                                <span>SMARTWAVE CEO</span>
                              </div>
                            </div>
                            {/* End .testimonial-owner */}
                            <blockquote>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur elitad adipiscing
                                Cras non placerat mipsum dolor sit amet, consectetur elitad
                                adipiscing cas non placerat mi.
                              </p>
                            </blockquote>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="owl-nav disabled">
                      <button
                        type="button"
                        title="nav"
                        role="presentation"
                        className="owl-prev"
                      >
                        <i className="icon-angle-left" />
                      </button>
                      <button
                        type="button"
                        title="nav"
                        role="presentation"
                        className="owl-next"
                      >
                        <i className="icon-angle-right" />
                      </button>
                    </div>
                    <div className="owl-dots disabled" />
                  </div>
                  {/* End .testimonials-slider */}
                </div>
                {/* End .container */}
              </div>
              {/* End .testimonials-section */}
              <div className="container">
                <div className="counters-section">
                  <div className="row">
                    <div className="col-6 col-md-4 count-container">
                      <div className="count-wrapper">
                        <span
                          className="count-to"
                          data-fromvalue={0}
                          data-tovalue={200}
                          data-duration={2000}
                          data-append="+"
                        >
                          200+
                        </span>
                      </div>
                      {/* End .count-wrapper */}
                      <h4 className="count-title">MILLION CUSTOMERS</h4>
                    </div>
                    {/* End .col-md-4 */}
                    <div className="col-6 col-md-4 count-container">
                      <div className="count-wrapper">
                        <span
                          className="count-to"
                          data-fromvalue={0}
                          data-tovalue={1800}
                          data-duration={2000}
                          data-append="+"
                        >
                          1800+
                        </span>
                      </div>
                      {/* End .count-wrapper */}
                      <h4 className="count-title">TEAM MEMBERS</h4>
                    </div>
                    {/* End .col-md-4 */}
                    <div className="col-6 col-md-4 count-container">
                      <div className="count-wrapper line-height-1">
                        <span
                          className="count-to"
                          data-fromvalue={0}
                          data-tovalue={24}
                          data-duration={2000}
                          data-append="HR"
                        >
                          24HR
                        </span>
                      </div>
                      {/* End .count-wrapper */}
                      <h4 className="count-title">SUPPORT AVAILABLE</h4>
                    </div>
                    {/* End .col-md-4 */}
                    <div className="col-6 col-md-4 count-container">
                      <div className="count-wrapper">
                        <span
                          className="count-to"
                          data-fromvalue={0}
                          data-tovalue={265}
                          data-duration={2000}
                          data-append="+"
                        >
                          265+
                        </span>
                      </div>
                      {/* End .count-wrapper */}
                      <h4 className="count-title">SUPPORT AVAILABLE</h4>
                    </div>
                    {/* End .col-md-4 */}
                    <div className="col-6 col-md-4 count-container">
                      <div className="count-wrapper line-height-1">
                        <span
                          className="count-to"
                          data-fromvalue={0}
                          data-tovalue={99}
                          data-duration={2000}
                          data-append="%"
                        >
                          99%
                        </span>
                      </div>
                      {/* End .count-wrapper */}
                      <h4 className="count-title">SUPPORT AVAILABLE</h4>
                    </div>
                    {/* End .col-md-4 */}
                  </div>
                  {/* End .row */}
                </div>
                {/* End .container */}
              </div>
              {/* End .counters-section */}
            </main>

            {/* End .main */}
          </>

          </div>
        </div>
        <Footer />
      </div>
    </div>
      
    </>
    
  );
};

export default About;
