import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ProductSimilaireDetail from './ProductSimilaireDetail'

// Tableau d'objets représentant les catégories
const products = [
    { id: 1, name: 'ALBEN ORAL 10 1L PROFIL', image: "assets/images/products/ALBEN_ORAL 10_1L_PROFIL.jpg", category: 'Accessories', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)' },
    { id: 2, name: 'ALBENVET', image: "assets/images/products/ALBENVET_FACE.jpg", category: 'Fashion', price: 2500, oldprice: 2700, size: 'Large', brand: 'Brand B', color: 'Blue', href: '#', ratings: '85%', reviews: '(20 reviews)' },
    { id: 3, name: 'AMINOVIT PLUS 1KG ', image: "assets/images/products/AMINOVIT_PLUS_1KG_FACE.jpg", category: 'Electronics', price: 3000, oldprice: 3200, size: 'Large', brand: 'Brand C', color: 'Black', href: '#', ratings: '90%', reviews: '(30 reviews)' },
    { id: 4, name: 'AMINOVIT PLUS 150G ', image: "assets/images/products/AMINOVIT_PLUS_150G_FACE.jpg", category: 'Fashion', price: 1800, oldprice: 2000, size: 'Medium', brand: 'Brand A', color: 'Red', href: '#', ratings: '75%', reviews: '(15 reviews)' },
    { id: 5, name: 'AMOXY-C 1KG FACE', image: "assets/images/products/AMOXY-C_1KG_FACE.jpg", category: 'Accessories', price: 2200, oldprice: 2400, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)' },
    { id: 6, name: 'AMPROLE 200 1KG', image: "assets/images/products/AMPROLE_200_1KG_FACE.jpg", category: 'Accessories', price: 2500, oldprice: 2700, size: 'Large', brand: 'Brand B', color: 'Blue', href: '#', ratings: '85%', reviews: '(20 reviews)' },
    { id: 7, name: 'AMPROLE 200 SACHET DE 100G', image: "assets/images/products/AMPROLE_200_SACHET_DE_100G_FACE.jpg", category: 'Electronics', price: 3000, oldprice: 3200, size: 'Large', brand: 'Brand C', color: 'Black', href: '#', ratings: '90%', reviews: '(30 reviews)' },
    { id: 8, name: 'COLISTIN 5 BOU 100 ', image: "assets/images/products/COLISTIN_5_BOU_100_DOS.jpg", category: 'Fashion', price: 1800, oldprice: 2000, size: 'Medium', brand: 'Brand A', color: 'Red', href: '#', ratings: '75%', reviews: '(15 reviews)' },
  // Ajoutez d'autres sections au besoin
  ];

const ProductSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <div className="slick-arrow slick-next"></div>,
    prevArrow: <div className="slick-arrow slick-prev"></div>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ]
  };

  return (
    <Slider  {...settings}>
        {products.map((product, index) => (
            <ProductSimilaireDetail key={index} product={product} />
        ))}
    </Slider>
  );
};

export default ProductSlider;
