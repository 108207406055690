// src/components/Accueil.js
import React, { useState, useEffect, useContext } from 'react'; // Importation de React et des hooks useState et useEffect
import { Link } from 'react-router-dom'; // Importation de Link de react-router-dom pour la navigation
import Slider from 'react-slick'; // Importation du composant Slider de react-slick
import SliderComponent from './SliderComponent'; // Importation d'un composant personnalisé SliderComponent
import Header from '../components/Header'; // Importation du composant Header
import Footer from '../components/Footer'; // Importation du composant Header
import ProductSections from './ProductSections'; // Importation d'un composant personnalisé SliderComponent
import IconeInfo from './IconeInfo'; // Importation d'un composant personnalisé SliderComponent
import HomeSmallBanner from './HomeSmallBanner'; // Importation d'un composant personnalisé SliderComponent
import MobileMenu from'../components/MobileMenu'
import MobileCategoryMenu from'../components/MobileCategoryMenu'
import { useNavigate } from 'react-router-dom'; // Utilisez useNavigate pour la redirection


import { fetchEvenements } from '../../services/apiService'; // Importation de la fonction fetchEvenements depuis le fichier apiService
import { HeaderContext } from '../../contexts/HeaderContext'; // Importer le contexte

// Définition des images pour le slider
const images = [
  { id: 1, STR_SLIDERIMAGE: 'banner-1.jpg' },
  { id: 2, STR_SLIDERIMAGE: 'banner-2.jpg' },
  { id: 3, STR_SLIDERIMAGE: 'banner-2.jpg' },
];

const sectionsData = [
  {
      title: "Nouveautés",
      catImage: "assets/images/demos/demo1/banners/banner-cat.jpg",
      sectionBgClass:"bleuLight",
      products: [
        { id: 1, name: 'ALBEN ORAL 10 1L PROFIL', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Accessories', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)',
          products: [
            { id: 1, name: 'ALBEN ORAL 10 1L PROFIL', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Accessories', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)'},
            { id: 2, name: 'ALBEN ORAL 10 1L PROFIL', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Accessories', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)'},
            { id: 3, name: 'ALBEN ORAL 10 1L PROFIL', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Accessories', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)'},
          ],
        },
        { id: 2, name: 'ALBENVET', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Fashion', price: 2500, oldprice: 2700, size: 'Large', brand: 'Brand B', color: 'Blue', href: '#', ratings: '85%', reviews: '(20 reviews)',
          products: [
            { id: 1, name: 'ALBENVET', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Accessories', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)'},
            { id: 2, name: 'ALBENVET', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Accessories', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)'},
            { id: 3, name: 'ALBENVET', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Accessories', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)'},
          ],
        },
        { id: 3, name: 'AMINOVIT PLUS 1KG ', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Electronics', price: 3000, oldprice: 3200, size: 'Large', brand: 'Brand C', color: 'Black', href: '#', ratings: '90%', reviews: '(30 reviews)',
          products: [
            { id: 1, name: 'AMINOVIT PLUS 1KG ', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Electronics', price: 3000, oldprice: 3200, size: 'Large', brand: 'Brand C', color: 'Black', href: '#', ratings: '90%', reviews: '(30 reviews)' },
          ],
        },
        { id: 4, name: 'AMINOVIT PLUS 150G ', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Fashion', price: 1800, oldprice: 2000, size: 'Medium', brand: 'Brand A', color: 'Red', href: '#', ratings: '75%', reviews: '(15 reviews)' ,
          products: [
            { id: 1, name: 'AMINOVIT PLUS 150G ', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Fashion', price: 1800, oldprice: 2000, size: 'Medium', brand: 'Brand A', color: 'Red', href: '#', ratings: '75%', reviews: '(15 reviews)' },
          ],
        },
        { id: 5, name: 'AMOXY-C 1KG FACE', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Accessories', price: 2200, oldprice: 2400, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)' },
        { id: 6, name: 'AMPROLE 200 1KG', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Accessories', price: 2500, oldprice: 2700, size: 'Large', brand: 'Brand B', color: 'Blue', href: '#', ratings: '85%', reviews: '(20 reviews)' },
        { id: 7, name: 'AMPROLE 200 SACHET DE 100G', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Electronics', price: 3000, oldprice: 3200, size: 'Large', brand: 'Brand C', color: 'Black', href: '#', ratings: '90%', reviews: '(30 reviews)' },
        { id: 8, name: 'COLISTIN 5 BOU 100 ', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Fashion', price: 1800, oldprice: 2000, size: 'Medium', brand: 'Brand A', color: 'Red', href: '#', ratings: '75%', reviews: '(15 reviews)' },
      ],
  },


  {
    title: "Promotions",
    catImage: "assets/images/demos/demo1/banners/banner-cat.jpg",
    sectionBgClass:"",
    products: [
      { id: 1, name: 'ALBEN ORAL 10 1L PROFIL', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Accessories', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)',
        products: [
          { id: 1, name: 'ALBEN ORAL 10 1L PROFIL', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Accessories', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)'},
          { id: 2, name: 'ALBEN ORAL 10 1L PROFIL', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Accessories', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)'},
          { id: 3, name: 'ALBEN ORAL 10 1L PROFIL', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Accessories', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)'},
        ],
      },
      { id: 2, name: 'ALBENVET', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Fashion', price: 2500, oldprice: 2700, size: 'Large', brand: 'Brand B', color: 'Blue', href: '#', ratings: '85%', reviews: '(20 reviews)',
        products: [
          { id: 1, name: 'ALBENVET', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Accessories', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)'},
          { id: 2, name: 'ALBENVET', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Accessories', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)'},
          { id: 3, name: 'ALBENVET', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Accessories', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)'},
        ],
      },
      { id: 3, name: 'AMINOVIT PLUS 1KG ', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Electronics', price: 3000, oldprice: 3200, size: 'Large', brand: 'Brand C', color: 'Black', href: '#', ratings: '90%', reviews: '(30 reviews)',
        products: [
          { id: 1, name: 'AMINOVIT PLUS 1KG ', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Electronics', price: 3000, oldprice: 3200, size: 'Large', brand: 'Brand C', color: 'Black', href: '#', ratings: '90%', reviews: '(30 reviews)' },
        ],
      },
      { id: 4, name: 'AMINOVIT PLUS 150G ', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Fashion', price: 1800, oldprice: 2000, size: 'Medium', brand: 'Brand A', color: 'Red', href: '#', ratings: '75%', reviews: '(15 reviews)' ,
        products: [
          { id: 1, name: 'AMINOVIT PLUS 150G ', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Fashion', price: 1800, oldprice: 2000, size: 'Medium', brand: 'Brand A', color: 'Red', href: '#', ratings: '75%', reviews: '(15 reviews)' },
        ],
      },
      { id: 5, name: 'AMOXY-C 1KG FACE', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Accessories', price: 2200, oldprice: 2400, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)' },
      { id: 6, name: 'AMPROLE 200 1KG', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Accessories', price: 2500, oldprice: 2700, size: 'Large', brand: 'Brand B', color: 'Blue', href: '#', ratings: '85%', reviews: '(20 reviews)' },
      { id: 7, name: 'AMPROLE 200 SACHET DE 100G', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Electronics', price: 3000, oldprice: 3200, size: 'Large', brand: 'Brand C', color: 'Black', href: '#', ratings: '90%', reviews: '(30 reviews)' },
      { id: 8, name: 'COLISTIN 5 BOU 100 ', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Fashion', price: 1800, oldprice: 2000, size: 'Medium', brand: 'Brand A', color: 'Red', href: '#', ratings: '75%', reviews: '(15 reviews)' },
    ],
},

{
  title: "Nouveaux arrivages",
  catImage: "assets/images/demos/demo1/banners/banner-cat.jpg",
  sectionBgClass:"grayLight",
  products: [
    { id: 1, name: 'ALBEN ORAL 10 1L PROFIL', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Accessories', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)',
      products: [
        { id: 1, name: 'ALBEN ORAL 10 1L PROFIL', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Accessories', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)'},
        { id: 2, name: 'ALBEN ORAL 10 1L PROFIL', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Accessories', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)'},
        { id: 3, name: 'ALBEN ORAL 10 1L PROFIL', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Accessories', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)'},
      ],
    },
    { id: 2, name: 'ALBENVET', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Fashion', price: 2500, oldprice: 2700, size: 'Large', brand: 'Brand B', color: 'Blue', href: '#', ratings: '85%', reviews: '(20 reviews)',
      products: [
        { id: 1, name: 'ALBENVET', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Accessories', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)'},
        { id: 2, name: 'ALBENVET', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Accessories', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)'},
        { id: 3, name: 'ALBENVET', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Accessories', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)'},
      ],
    },
    { id: 3, name: 'AMINOVIT PLUS 1KG ', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Electronics', price: 3000, oldprice: 3200, size: 'Large', brand: 'Brand C', color: 'Black', href: '#', ratings: '90%', reviews: '(30 reviews)',
      products: [
        { id: 1, name: 'AMINOVIT PLUS 1KG ', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Electronics', price: 3000, oldprice: 3200, size: 'Large', brand: 'Brand C', color: 'Black', href: '#', ratings: '90%', reviews: '(30 reviews)' },
      ],
    },
    { id: 4, name: 'AMINOVIT PLUS 150G ', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Fashion', price: 1800, oldprice: 2000, size: 'Medium', brand: 'Brand A', color: 'Red', href: '#', ratings: '75%', reviews: '(15 reviews)' ,
      products: [
        { id: 1, name: 'AMINOVIT PLUS 150G ', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Fashion', price: 1800, oldprice: 2000, size: 'Medium', brand: 'Brand A', color: 'Red', href: '#', ratings: '75%', reviews: '(15 reviews)' },
      ],
    },
    { id: 5, name: 'AMOXY-C 1KG FACE', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Accessories', price: 2200, oldprice: 2400, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)' },
    { id: 6, name: 'AMPROLE 200 1KG', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Accessories', price: 2500, oldprice: 2700, size: 'Large', brand: 'Brand B', color: 'Blue', href: '#', ratings: '85%', reviews: '(20 reviews)' },
    { id: 7, name: 'AMPROLE 200 SACHET DE 100G', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Electronics', price: 3000, oldprice: 3200, size: 'Large', brand: 'Brand C', color: 'Black', href: '#', ratings: '90%', reviews: '(30 reviews)' },
    { id: 8, name: 'COLISTIN 5 BOU 100 ', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Fashion', price: 1800, oldprice: 2000, size: 'Medium', brand: 'Brand A', color: 'Red', href: '#', ratings: '75%', reviews: '(15 reviews)' },
  ],
},

{
  title: "Acheter à nouveau",
  catImage: "assets/images/demos/demo1/banners/banner-cat.jpg",
  sectionBgClass:"",
  products: [
    { id: 1, name: 'ALBEN ORAL 10 1L PROFIL', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Accessories', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)',
      products: [
        { id: 1, name: 'ALBEN ORAL 10 1L PROFIL', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Accessories', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)'},
        { id: 2, name: 'ALBEN ORAL 10 1L PROFIL', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Accessories', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)'},
        { id: 3, name: 'ALBEN ORAL 10 1L PROFIL', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Accessories', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)'},
      ],
    },
    { id: 2, name: 'ALBENVET', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Fashion', price: 2500, oldprice: 2700, size: 'Large', brand: 'Brand B', color: 'Blue', href: '#', ratings: '85%', reviews: '(20 reviews)',
      products: [
        { id: 1, name: 'ALBENVET', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Accessories', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)'},
        { id: 2, name: 'ALBENVET', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Accessories', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)'},
        { id: 3, name: 'ALBENVET', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Accessories', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)'},
      ],
    },
    { id: 3, name: 'AMINOVIT PLUS 1KG ', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Electronics', price: 3000, oldprice: 3200, size: 'Large', brand: 'Brand C', color: 'Black', href: '#', ratings: '90%', reviews: '(30 reviews)',
      products: [
        { id: 1, name: 'AMINOVIT PLUS 1KG ', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Electronics', price: 3000, oldprice: 3200, size: 'Large', brand: 'Brand C', color: 'Black', href: '#', ratings: '90%', reviews: '(30 reviews)' },
      ],
    },
    { id: 4, name: 'AMINOVIT PLUS 150G ', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Fashion', price: 1800, oldprice: 2000, size: 'Medium', brand: 'Brand A', color: 'Red', href: '#', ratings: '75%', reviews: '(15 reviews)' ,
      products: [
        { id: 1, name: 'AMINOVIT PLUS 150G ', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Fashion', price: 1800, oldprice: 2000, size: 'Medium', brand: 'Brand A', color: 'Red', href: '#', ratings: '75%', reviews: '(15 reviews)' },
      ],
    },
    { id: 5, name: 'AMOXY-C 1KG FACE', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Accessories', price: 2200, oldprice: 2400, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)' },
    { id: 6, name: 'AMPROLE 200 1KG', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Accessories', price: 2500, oldprice: 2700, size: 'Large', brand: 'Brand B', color: 'Blue', href: '#', ratings: '85%', reviews: '(20 reviews)' },
    { id: 7, name: 'AMPROLE 200 SACHET DE 100G', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Electronics', price: 3000, oldprice: 3200, size: 'Large', brand: 'Brand C', color: 'Black', href: '#', ratings: '90%', reviews: '(30 reviews)' },
    { id: 8, name: 'COLISTIN 5 BOU 100 ', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Fashion', price: 1800, oldprice: 2000, size: 'Medium', brand: 'Brand A', color: 'Red', href: '#', ratings: '75%', reviews: '(15 reviews)' },
  ],
},

  {
      title: "Risque de ruptures",
      catImage: "assets/images/demos/demo1/banners/banner-cat.jpg",
      sectionBgClass:"yellowLight",
      products: [
        { id: 1, name: 'ALBEN ORAL 10 1L PROFIL', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Accessories', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)' },
        { id: 2, name: 'ALBENVET', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Fashion', price: 2500, oldprice: 2700, size: 'Large', brand: 'Brand B', color: 'Blue', href: '#', ratings: '85%', reviews: '(20 reviews)' },
        { id: 3, name: 'AMINOVIT PLUS 1KG ', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Electronics', price: 3000, oldprice: 3200, size: 'Large', brand: 'Brand C', color: 'Black', href: '#', ratings: '90%', reviews: '(30 reviews)' },
        { id: 4, name: 'AMINOVIT PLUS 150G ', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Fashion', price: 1800, oldprice: 2000, size: 'Medium', brand: 'Brand A', color: 'Red', href: '#', ratings: '75%', reviews: '(15 reviews)' },
        { id: 5, name: 'AMOXY-C 1KG FACE', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Accessories', price: 2200, oldprice: 2400, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)' },
        { id: 6, name: 'AMPROLE 200 1KG', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Accessories', price: 2500, oldprice: 2700, size: 'Large', brand: 'Brand B', color: 'Blue', href: '#', ratings: '85%', reviews: '(20 reviews)' },
        { id: 7, name: 'AMPROLE 200 SACHET DE 100G', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Electronics', price: 3000, oldprice: 3200, size: 'Large', brand: 'Brand C', color: 'Black', href: '#', ratings: '90%', reviews: '(30 reviews)' },
        { id: 8, name: 'COLISTIN 5 BOU 100 ', image: "assets/images/products/AMOXY-C1KG-FACE.jpg", category: 'Fashion', price: 1800, oldprice: 2000, size: 'Medium', brand: 'Brand A', color: 'Red', href: '#', ratings: '75%', reviews: '(15 reviews)' },

      ],
  },
  // Ajoutez d'autres sections au besoin
];




const menuItems = [
    {
      title: "Accueil",
      href: "index.html",
      class: "active",
    },
    {
      title: "Shop",
      href: "shop-banner-sidebar.html",
      submenu: [
        {
          title: "Shop Pages",
          submenu: [
            { title: "Banner With Sidebar", href: "shop-banner-sidebar.html" },
            { title: "Boxed Banner", href: "shop-boxed-banner.html" },
          ],
        },
        {
          title: "Shop Layouts",
          submenu: [
            { title: "3 Columns Mode", href: "shop-grid-3cols.html" },
            { title: "4 Columns Mode", href: "shop-grid-4cols.html" },
          ],
        },
        {
          title: "Product Pages",
          submenu: [
            { title: "Without Sidebar", href: "product-without-sidebar.html" },
            { title: "360° & Video", href: "product-video.html", new: true },
          ],
        },
        {
          title: "Product Layouts",
          submenu: [
            { title: "Default", href: "product-default.html", hot: true },
            { title: "Vertical Thumbs", href: "product-vertical.html" },
          ],
        },
      ],
    },
    {
      title: "Vendor",
      href: "vendor-dokan-store.html",
      submenu: [
        {
          title: "Store Listing",
          submenu: [
            { title: "Store listing 1", href: "vendor-dokan-store-list.html" },
          ],
        },
        {
          title: "Vendor Store",
          submenu: [
            { title: "Vendor Store 1", href: "vendor-dokan-store.html" },
          ],
        },
      ],
    },
    {
      title: "Blog",
      href: "blog.html",
      submenu: [
        { title: "Classic", href: "blog.html" },
        { title: "Listing", href: "blog-listing.html" },
        {
          title: "Grid",
          submenu: [
            { title: "Grid 2 columns", href: "blog-grid-2cols.html" },
            { title: "Grid 3 columns", href: "blog-grid-3cols.html" },
          ],
        },
        {
          title: "Masonry",
          submenu: [
            { title: "Masonry 2 columns", href: "blog-masonry-2cols.html" },
            { title: "Masonry 3 columns", href: "blog-masonry-3cols.html" },
          ],
        },
        {
          title: "Mask",
          submenu: [
            { title: "Blog mask grid", href: "blog-mask-grid.html" },
            { title: "Blog mask masonry", href: "blog-mask-masonry.html" },
          ],
        },
        { title: "Single Post", href: "post-single.html" },
      ],
    },
    {
      title: "Pages",
      href: "about-us.html",
      submenu: [
        { title: "About Us", href: "about-us.html" },
        { title: "Become A Vendor", href: "become-a-vendor.html" },
        { title: "Contact Us", href: "contact-us.html" },
      ],
    },
    {
      title: "Elements",
      href: "elements.html",
      submenu: [
        { title: "Accordions", href: "element-accordions.html" },
      ],
    },
];



  

  

const ModelCard = ({ evenement }) => {
  const handleClick = () => {
    localStorage.setItem('LG_EVEID', evenement.LG_EVEID);
  };
  return (
    <div className="item mx-1 event-item">
      <Link className='w-100' to={`/detail-event?id=${evenement.STR_EVENPICTURE}`} onClick={handleClick}>
        <div className="event-grid">
          <div className="movie-thumb c-thumb">
            <a href="#0">
              <img src={`eticketbackend/${evenement.STR_EVEPIC}`} alt="movie" />
            </a>
            <div className="event-date">
              <span className="badge badge-danger">Gratuit</span>
            </div>
          </div>
          <div className="movie-content bg-white pb-2">
            <h5 className="title m-0 text-uppercase">
              <a href="#0">{evenement.STR_EVENAME}</a>
            </h5>
            <p className='event-home-desc text-color mt-1 mb-1 text-left'>
              {evenement.STR_EVEDESCRIPTION}
            </p>
            <div className="social-and-duration mt-1">
              <div className="duration-area row">
                <div className="col-lg-12 fs-12 lh-2">
                  <i className="fas fa-calendar-alt text-dabger"></i><span>{evenement.STR_EVENNAME} {evenement.DT_EVEBEGIN}</span> &nbsp; | &nbsp; <i className="far fa-clock"></i><span> {evenement.HR_EVEBEGIN}</span>
                </div>
                <div className="col-lg-12 fs-12 lh-2">
                  <i className="fas fa-map-pin"></i><span> {evenement.STR_EVEPLACE}</span>
                </div>
              </div>
            </div>
            <div className='pull-right'>
              <span className='event-home-desc'>Prix: </span><span className="badge badge-info ">{evenement.STR_EVEPLACE} FCFA</span>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

const CustomArrow = ({ onClick, direction, iconClass }) => {
  return (
    <div className={`cast-${direction}`} onClick={onClick}>
      <i className={iconClass}></i>
    </div>
  );
};

// const ModelSlider = ({ evenements, title }) => {
//   const groupedModelsByGenre = evenements.reduce((acc, evenement) => {
//     if (!acc[evenement.STR_EVENAME]) {
//       acc[evenement.STR_EVENAME] = [];
//     }
//     acc[evenement.STR_EVENAME].push(evenement);
//     return acc;
//   }, {});

//   const nbreLigneAfficher = 1; 
//   const nbreColloneAfficher = 4; 

//   return (
//     <div>
//       <div className="section-header-1">
//         <h5 className="title mt-2">{title}</h5>
//       </div>
//       {Object.entries(groupedModelsByGenre).map(([STR_EVENAME, evenements]) => {
//         const rows = [];
//         for (let i = 0; i < evenements.length; i += nbreLigneAfficher) {
//           rows.push(evenements.slice(i, i + nbreLigneAfficher));
//         }

//         const sliderSettings = {
//           dots: false,
//           infinite: evenements.length > nbreColloneAfficher,
//           speed: 500,
//           slidesToShow: evenements.length >= 1 ? nbreColloneAfficher : evenements.length,
//           slidesToScroll: 1,
//           autoplay: true,
//           autoplaySpeed: 2000,
//           responsive: [
//             {
//               breakpoint: 768,
//               settings: {
//                 slidesToShow: 2,
//                 slidesToScroll: 2,
//               },
//             },
//             {
//               breakpoint: 480,
//               settings: {
//                 slidesToShow: 2,
//                 slidesToScroll: 1,
//               },
//             },
//           ],
//           prevArrow: <CustomArrow iconClass="flaticon-double-right-arrows-angles" direction="prev" />,
//           nextArrow: <CustomArrow iconClass="flaticon-double-right-arrows-angles" direction="next" />
//         };

//         return (
//           <div className="model-slider" key={STR_EVENAME}>
//             <h6 className='border-dashed pb-2'>{STR_EVENAME}</h6>
//             <Slider className='mt-5' {...sliderSettings}>
//               {rows.map((row, rowIndex) => (
//                 <div key={rowIndex} className="model-row">
//                   {row.map((evenement, colIndex) => (
//                     <div key={colIndex}>
//                       <ModelCard evenement={evenement} />
//                     </div>
//                   ))}
//                 </div>
//               ))}
//             </Slider>
//           </div>
//         );
//       })}
//     </div>
//   );
// };

const Accueil = () => {
  const [activitesData, setData] = useState([]); 
  const { searchTerm } = useContext(HeaderContext); 
  const navigate = useNavigate(); 


  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    console.log(user);
    if (!user) {
      navigate('/'); // Rediriger vers la page de connexion si l'utilisateur n'est pas connecté
    }
  }, [navigate]);



  useEffect(() => {
    const params = {
      mode: 'listEvenementFront',
      STR_UTITOKEN: 'wzpjku2dvokriz3phgop',
      DT_BEGIN: '2024-05-01',
      DT_END: '2024-05-27',
    };

    fetchEvenements(params)
      .then(response => {
        setData(response.data.data); 
      })
      .catch(error => {
        console.error('Error fetching data:', error); 
      });
  }, []);

  const filteredBlocks = activitesData.map((activite) => {
    const filteredModels = activite.evenements.filter((evenement) =>
      searchTerm
        .toLowerCase()
        .split(/\s+/)
        .some((word) => evenement.STR_EVENAME.toLowerCase().includes(word) || evenement.STR_EVEDESCRIPTION.toLowerCase().includes(word))
    );

    return filteredModels.length > 0 ? { ...activite, evenements: filteredModels } : null;
  }).filter((activite) => activite !== null);

  return (
    <>
      <Header /> 

      <>
  <main className="main">
    <div className="container container-not-boxed">
      <div
        className="info-boxes-slider owl-carousel owl-theme"
        data-owl-options="{
						'dots': false,
                  'loop': false,
						'responsive': {
							'0': {
								'items': 1
                      },
                      '480': {
								'items': 2
                      },
							'992': {
								'items': 3
							}
						}
					}"
      >
        <div className="info-box info-box-icon-left">
          <i className="icon-shipping font-35 pt-1" />
          <div className="info-box-content pt-1">
            <h4>FREE SHIPPING &amp; RETURN</h4>
            <p className="text-body">Free shipping on all orders over $99</p>
          </div>
          {/* End .info-box-content */}
        </div>
        {/* End .info-box */}
        <div className="info-box info-box-icon-left">
          <i className="icon-money pt-1" />
          <div className="info-box-content">
            <h4>MONEY BACK GUARANTEE</h4>
            <p className="text-body">100% money back guarantee</p>
          </div>
          {/* End .info-box-content */}
        </div>
        {/* End .info-box */}
        <div className="info-box info-box-icon-left">
          <i className="icon-support pt-1" />
          <div className="info-box-content">
            <h4>ONLINE SUPPORT 24/7</h4>
            <p className="text-body">Lorem ipsum dolor sit amet.</p>
          </div>
          {/* End .info-box-content */}
        </div>
        {/* End .info-box */}
      </div>
      {/* End .info-boxes-slider */}
      <div className="home-slider outer-container w-auto owl-carousel owl-theme owl-carousel-lazy show-nav-hover nav-large nav-outer mb-2">
        <div className="home-slide home-slide1 banner banner-md-vw bg-white">
          <img
            className="slide-bg"
            src="assets/images/demoes/demo5/slider/slide-1.jpg"
            style={{ backgroundColor: "#fff" }}
            alt="slider image"
          />
          <div className="banner-layer banner-layer-middle">
            <h4 className="m-b-3">Find the Boundaries. Push Through!</h4>
            <h2 className="font-script mb-0">Jeans Sale</h2>
            <h3 className="upto-text">
              <em>
                up
                <br />
                to
              </em>
              80% OFF
            </h3>
            <h5 className="d-inline-block mb-0 text-uppercase ls-n-20">
              Starting At
              <b className="coupon-sale-text text-white bg-secondary">
                $<em>199</em>99
              </b>
            </h5>
            <a href="demo5-shop.html" className="btn btn-dark btn-lg ls-10">
              Shop Now!
            </a>
          </div>
          {/* End .banner-layer */}
        </div>
        {/* End .home-slide */}
        <div className="home-slide home-slide2 banner banner-md-vw">
          <img
            className="slide-bg"
            src="assets/images/demoes/demo5/slider/slide-2.jpg"
            style={{ backgroundColor: "#ccc" }}
            alt="slider image"
          />
          <div className="banner-layer banner-layer-middle">
            <h2 className="ls-n-25 mb-0">Women's Business Fashion</h2>
            <h3 className="upto-text">
              <em>
                up
                <br />
                to
              </em>
              60% OFF
            </h3>
            <h5 className="text-uppercase d-inline-block mb-0 align-top ls-n-20">
              Starting at
              <span className="text-secondary ml-2 mr-5 pr-sm-5">
                $<em className="ls-n-20">29</em>99
              </span>
            </h5>
            <a
              href="demo5-shop.html"
              className="btn btn-dark btn-outline ls-10 btn-xl mt-0"
            >
              Shop Now!
            </a>
          </div>
        </div>
        {/* End .home-slide */}
      </div>
      {/* End .home-slider */}
      <div className="home-products-container text-center">
        <div className="row">
          <div className="col-md-4 mb-2">
            <div className="home-products bg-white px-4 pb-2 pt-4">
              <h3 className="section-sub-title mt-1 m-b-1">
                Featured Products
              </h3>
              <div
                className="owl-carousel owl-theme nav-image-center nav-thin px-3 "
                data-owl-options="{
										'dots': false,
										'nav': true,
										'responsive': {
											'480': {
												'items': 2
											},
											'768': {
												'items': 1
											}
										}
									}"
              >
                <div className="product-default">
                  <figure>
                    <a href="demo5-product.html">
                      <img
                        src="assets/images/demoes/demo5/products/product-3.jpg"
                        alt="product"
                        width={300}
                        height={300}
                      />
                      <img
                        src="assets/images/demoes/demo5/products/product-4.jpg"
                        alt="product"
                        width={300}
                        height={300}
                      />
                    </a>
                  </figure>
                  <div className="product-details">
                    <div className="category-list">
                      <a href="demo5-shop.html" className="product-category">
                        category
                      </a>
                    </div>
                    <h3 className="product-title">
                      <a href="demo5-product.html">Red Men Shoes</a>
                    </h3>
                    <div className="ratings-container">
                      <div className="product-ratings">
                        <span className="ratings" style={{ width: "100%" }} />
                        {/* End .ratings */}
                        <span className="tooltiptext tooltip-top" />
                      </div>
                      {/* End .product-ratings */}
                    </div>
                    {/* End .product-container */}
                    <div className="price-box">
                      <span className="old-price">$59.00</span>
                      <span className="product-price">$49.00</span>
                    </div>
                    {/* End .price-box */}
                  </div>
                  {/* End .product-details */}
                </div>
                <div className="product-default">
                  <figure>
                    <a href="demo5-product.html">
                      <img
                        src="assets/images/demoes/demo5/products/product-7.jpg"
                        alt="product"
                        width={300}
                        height={300}
                      />
                      <img
                        src="assets/images/demoes/demo5/products/product-10.jpg"
                        alt="product"
                        width={300}
                        height={300}
                      />
                    </a>
                  </figure>
                  <div className="product-details">
                    <div className="category-list">
                      <a href="demo5-shop.html" className="product-category">
                        category
                      </a>
                    </div>
                    <h3 className="product-title">
                      <a href="demo5-product.html">Men Spring Casual Shoes</a>
                    </h3>
                    <div className="ratings-container">
                      <div className="product-ratings">
                        <span className="ratings" style={{ width: "100%" }} />
                        {/* End .ratings */}
                        <span className="tooltiptext tooltip-top" />
                      </div>
                      {/* End .product-ratings */}
                    </div>
                    {/* End .product-container */}
                    <div className="price-box">
                      <span className="old-price">$59.00</span>
                      <span className="product-price">$49.00</span>
                    </div>
                    {/* End .price-box */}
                  </div>
                  {/* End .product-details */}
                </div>
              </div>
            </div>
          </div>
          {/* End .col-md-4 */}
          <div className="col-md-4 mb-2">
            <div className="home-products bg-white px-4 pb-2 pt-4">
              <h3 className="section-sub-title mt-1 m-b-1">Best Sellers</h3>
              <div
                className="owl-carousel owl-theme nav-image-center nav-thin px-3"
                data-owl-options="{
										'dots': false,
										'nav': true,
										'responsive': {
											'480': {
												'items': 2
											},
											'768': {
												'items': 1
											}
										}
                              }"
              >
                <div className="product-default">
                  <figure>
                    <a href="demo5-product.html">
                      <img
                        src="assets/images/demoes/demo5/products/product-8.jpg"
                        alt="product"
                        width={300}
                        height={300}
                      />
                      <img
                        src="assets/images/demoes/demo5/products/product-15.jpg"
                        alt="product"
                        width={300}
                        height={300}
                      />
                    </a>
                  </figure>
                  <div className="product-details">
                    <div className="category-list">
                      <a href="demo5-shop.html" className="product-category">
                        category
                      </a>
                    </div>
                    <h3 className="product-title">
                      <a href="demo5-product.html">Grey Men Sports Cap</a>
                    </h3>
                    <div className="ratings-container">
                      <div className="product-ratings">
                        <span className="ratings" style={{ width: "100%" }} />
                        {/* End .ratings */}
                        <span className="tooltiptext tooltip-top" />
                      </div>
                      {/* End .product-ratings */}
                    </div>
                    {/* End .product-container */}
                    <div className="price-box">
                      <span className="old-price">$59.00</span>
                      <span className="product-price">$49.00</span>
                    </div>
                    {/* End .price-box */}
                  </div>
                  {/* End .product-details */}
                </div>
                <div className="product-default">
                  <figure>
                    <a href="demo5-product.html">
                      <img
                        src="assets/images/demoes/demo5/products/product-5.jpg"
                        alt="product"
                        width={300}
                        height={300}
                      />
                    </a>
                  </figure>
                  <div className="product-details">
                    <div className="category-list">
                      <a href="demo5-shop.html" className="product-category">
                        category
                      </a>
                    </div>
                    <h3 className="product-title">
                      <a href="demo5-product.html">Black Women Shoes</a>
                    </h3>
                    <div className="ratings-container">
                      <div className="product-ratings">
                        <span className="ratings" style={{ width: "100%" }} />
                        {/* End .ratings */}
                        <span className="tooltiptext tooltip-top" />
                      </div>
                      {/* End .product-ratings */}
                    </div>
                    {/* End .product-container */}
                    <div className="price-box">
                      <span className="old-price">$59.00</span>
                      <span className="product-price">$49.00</span>
                    </div>
                    {/* End .price-box */}
                  </div>
                  {/* End .product-details */}
                </div>
              </div>
            </div>
          </div>
          {/* End .col-md-4 */}
          <div className="col-md-4 mb-2">
            <div className="home-products bg-white px-4 pb-2 pt-4">
              <h3 className="section-sub-title mt-1 m-b-1">New Arrivals</h3>
              <div
                className="owl-carousel owl-theme nav-image-center nav-thin px-3"
                data-owl-options="{
										'dots': false,
										'nav': true,
										'responsive': {
											'480': {
												'items': 2
											},
											'768': {
												'items': 1
											}
										}
                              }"
              >
                <div className="product-default">
                  <figure>
                    <a href="demo5-product.html">
                      <img
                        src="assets/images/demoes/demo5/products/product-2.jpg"
                        alt="product"
                        width={300}
                        height={300}
                      />
                      <img
                        src="assets/images/demoes/demo5/products/product-16.jpg"
                        alt="product"
                        width={300}
                        height={300}
                      />
                    </a>
                  </figure>
                  <div className="product-details">
                    <div className="category-list">
                      <a href="demo5-shop.html" className="product-category">
                        category
                      </a>
                    </div>
                    <h3 className="product-title">
                      <a href="demo5-product.html">Black Men Casual Belt</a>
                    </h3>
                    <div className="ratings-container">
                      <div className="product-ratings">
                        <span className="ratings" style={{ width: "100%" }} />
                        {/* End .ratings */}
                        <span className="tooltiptext tooltip-top" />
                      </div>
                      {/* End .product-ratings */}
                    </div>
                    {/* End .product-container */}
                    <div className="price-box">
                      <span className="old-price">$59.00</span>
                      <span className="product-price">$49.00</span>
                    </div>
                    {/* End .price-box */}
                  </div>
                  {/* End .product-details */}
                </div>
                <div className="product-default">
                  <figure>
                    <a href="demo5-product.html">
                      <img
                        src="assets/images/demoes/demo5/products/product-17.jpg"
                        alt="product"
                        width={300}
                        height={300}
                      />
                    </a>
                  </figure>
                  <div className="product-details">
                    <div className="category-list">
                      <a href="demo5-shop.html" className="product-category">
                        category
                      </a>
                    </div>
                    <h3 className="product-title">
                      <a href="demo5-product.html">Grey Travel Bag</a>
                    </h3>
                    <div className="ratings-container">
                      <div className="product-ratings">
                        <span className="ratings" style={{ width: "100%" }} />
                        {/* End .ratings */}
                        <span className="tooltiptext tooltip-top" />
                      </div>
                      {/* End .product-ratings */}
                    </div>
                    {/* End .product-container */}
                    <div className="price-box">
                      <span className="old-price">$59.00</span>
                      <span className="product-price">$49.00</span>
                    </div>
                    {/* End .price-box */}
                  </div>
                  {/* End .product-details */}
                </div>
              </div>
            </div>
          </div>
          {/* End .col-md-4 */}
        </div>
        {/* End .row */}
      </div>
      {/* End .row.home-products */}
      <div className="banners-slider owl-carousel owl-theme mb-4 mb-md-2">
        <div className="banner banner1 banner-sm-vw">
          <figure>
            <img
              src="assets/images/demoes/demo5/banners/banner-1.jpg"
              style={{ backgroundColor: "#fff" }}
              alt="banner"
            />
          </figure>
          <div className="banner-layer banner-layer-middle">
            <h3 className="m-b-2">Porto Watches</h3>
            <h4 className="m-b-3 text-primary">
              <sup className="text-dark mr-1">
                <del>20%</del>
              </sup>
              30%<sup>OFF</sup>
            </h4>
            <a href="demo5-shop.html" className="btn btn-sm btn-dark ls-10">
              Shop Now
            </a>
          </div>
        </div>
        {/* End .banner */}
        <div className="banner banner2 banner-sm-vw text-uppercase">
          <figure>
            <img
              src="assets/images/demoes/demo5/banners/banner-2.jpg"
              style={{ backgroundColor: "#fff" }}
              alt="banner"
            />
          </figure>
          <h4 className="banner-layer-circle banner-layer-circle1">
            <span>50</span>
            <sup>
              %<small>OFF</small>
            </sup>
          </h4>
          <h4 className="banner-layer-circle banner-layer-circle2">
            <span>70</span>
            <sup>
              %<small>OFF</small>
            </sup>
          </h4>
          <h4 className="banner-layer-circle banner-layer-circle3 mb-0">
            <span>30</span>
            <sup>
              %<small>OFF</small>
            </sup>
          </h4>
          <div className="banner-layer banner-layer-middle text-center pr-lg-3">
            <div className="row align-items-lg-center">
              <div className="col-lg-7 text-lg-right">
                <h3 className="m-b-1 ls-10">Deal Promos</h3>
                <h4 className="pb-4 pb-lg-0 mb-0 text-body ls-10">
                  Starting at $99
                </h4>
              </div>
              <div className="col-lg-5 text-lg-left px-0 px-xl-3">
                <a href="demo5-shop.html" className="btn btn-sm btn-dark ls-10">
                  Shop Now
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* End .banner */}
        <div className="banner banner3 banner-sm-vw">
          <figure>
            <img
              src="assets/images/demoes/demo5/banners/banner-3.jpg"
              style={{ backgroundColor: "#fff" }}
              alt="banner"
            />
          </figure>
          <h4 className="banner-layer-circle bg-secondary pt-2">
            40
            <sup>
              %<small>OFF</small>
            </sup>
          </h4>
          <div className="banner-layer banner-layer-bottom banner-layer-left banner-layer-brand">
            <img
              src="assets/images/demoes/demo5/banners/banner-brand.png"
              alt="banner brand"
            />
          </div>
          <div className="banner-layer banner-layer-middle text-right">
            <h3 className="mb-1">Handbags</h3>
            <h4 className="m-b-1 ls-10 pb-3 text-secondary text-uppercase">
              Starting at $99
            </h4>
            <a href="demo5-shop.html" className="btn btn-sm btn-dark ls-10">
              Shop Now
            </a>
          </div>
        </div>
        {/* End .banner */}
      </div>
    </div>
    {/* End .container-not-boxed */}
    <div className="container">
      <div className="products-container bg-white text-center mb-2">
        <div className="row product-ajax-grid mb-2">
          <div className="col-6 col-sm-4 col-md-3 col-xl-5col">
            <div className="product-default inner-quickview inner-icon">
              <figure>
                <a href="demo5-product.html">
                  <img
                    src="assets/images/demoes/demo5/products/product-1.jpg"
                    width={212}
                    height={212}
                    alt="product"
                  />
                  <img
                    src="assets/images/demoes/demo5/products/product-16.jpg"
                    width={212}
                    height={212}
                    alt="product"
                  />
                </a>
                <div className="btn-icon-group">
                  <a
                    href="#"
                    title="Add To Cart"
                    className="btn-icon btn-add-cart product-type-simple"
                  >
                    <i className="icon-shopping-cart" />
                  </a>
                </div>
                <a
                  href="ajax/product-quick-view.html"
                  className="btn-quickview"
                  title="Quick View"
                >
                  Quick View
                </a>
              </figure>
              <div className="product-details">
                <div className="category-wrap">
                  <div className="category-list">
                    <a href="demo5-shop.html" className="product-category">
                      category
                    </a>
                  </div>
                  <a
                    href="wishlist.html"
                    title="Add to Wishlist"
                    className="btn-icon-wish"
                  >
                    <i className="icon-heart" />
                  </a>
                </div>
                <h3 className="product-title">
                  <a href="demo5-product.html">Brown Men Casual Belt</a>
                </h3>
                <div className="ratings-container">
                  <div className="product-ratings">
                    <span className="ratings" style={{ width: "100%" }} />
                    {/* End .ratings */}
                    <span className="tooltiptext tooltip-top" />
                  </div>
                  {/* End .product-ratings */}
                </div>
                {/* End .product-container */}
                <div className="price-box">
                  <span className="old-price">$59.00</span>
                  <span className="product-price">$49.00</span>
                </div>
                {/* End .price-box */}
                <div className="product-nav-filter product-nav-thumbs">
                  <a href="#" className="active">
                    <img
                      src="assets/images/demoes/demo5/products/product-1.jpg"
                      width={30}
                      height={30}
                      alt="prod-thumnail"
                    />
                  </a>
                  <a href="#">
                    <img
                      src="assets/images/demoes/demo5/products/product-16.jpg"
                      width={30}
                      height={30}
                      alt="prod-thumnail"
                    />
                  </a>
                </div>
              </div>
              {/* End .product-details */}
            </div>
          </div>
          {/* End .col-xl-5col */}
          <div className="col-6 col-sm-4 col-md-3 col-xl-5col">
            <div className="product-default inner-quickview inner-icon">
              <figure>
                <a href="demo5-product.html">
                  <img
                    src="assets/images/demoes/demo5/products/product-2.jpg"
                    width={212}
                    height={212}
                    alt="product"
                  />
                  <img
                    src="assets/images/demoes/demo5/products/product-16.jpg"
                    width={212}
                    height={212}
                    alt="product"
                  />
                </a>
                <div className="btn-icon-group">
                  <a
                    href="demo5-product.html"
                    className="btn-icon btn-add-cart"
                  >
                    <i className="fa fa-arrow-right" />
                  </a>
                </div>
                <a
                  href="ajax/product-quick-view.html"
                  className="btn-quickview"
                  title="Quick View"
                >
                  Quick View
                </a>
              </figure>
              <div className="product-details">
                <div className="category-wrap">
                  <div className="category-list">
                    <a href="demo5-shop.html" className="product-category">
                      category
                    </a>
                  </div>
                  <a
                    href="wishlist.html"
                    title="Add to Wishlist"
                    className="btn-icon-wish"
                  >
                    <i className="icon-heart" />
                  </a>
                </div>
                <h3 className="product-title">
                  <a href="demo5-product.html">Black Men Carousel Melt</a>
                </h3>
                <div className="ratings-container">
                  <div className="product-ratings">
                    <span className="ratings" style={{ width: "100%" }} />
                    {/* End .ratings */}
                    <span className="tooltiptext tooltip-top" />
                  </div>
                  {/* End .product-ratings */}
                </div>
                {/* End .product-container */}
                <div className="price-box">
                  <span className="old-price">$59.00</span>
                  <span className="product-price">$49.00</span>
                </div>
                {/* End .price-box */}
              </div>
              {/* End .product-details */}
            </div>
          </div>
          {/* End .col-xl-5col */}
          <div className="col-6 col-sm-4 col-md-3 col-xl-5col">
            <div className="product-default inner-quickview inner-icon">
              <figure>
                <a href="demo5-product.html">
                  <img
                    src="assets/images/demoes/demo5/products/product-3.jpg"
                    width={212}
                    height={212}
                    alt="product"
                  />
                  <img
                    src="assets/images/demoes/demo5/products/product-4.jpg"
                    width={212}
                    height={212}
                    alt="product"
                  />
                </a>
                <div className="btn-icon-group">
                  <a
                    href="#"
                    title="Add To Cart"
                    className="btn-icon btn-add-cart product-type-simple"
                  >
                    <i className="icon-shopping-cart" />
                  </a>
                </div>
                <a
                  href="ajax/product-quick-view.html"
                  className="btn-quickview"
                  title="Quick View"
                >
                  Quick View
                </a>
              </figure>
              <div className="product-details">
                <div className="category-wrap">
                  <div className="category-list">
                    <a href="demo5-shop.html" className="product-category">
                      category
                    </a>
                  </div>
                  <a
                    href="wishlist.html"
                    title="Add to Wishlist"
                    className="btn-icon-wish"
                  >
                    <i className="icon-heart" />
                  </a>
                </div>
                <h3 className="product-title">
                  <a href="demo5-product.html">Red Men Shoes</a>
                </h3>
                <div className="ratings-container">
                  <div className="product-ratings">
                    <span className="ratings" style={{ width: "100%" }} />
                    {/* End .ratings */}
                    <span className="tooltiptext tooltip-top" />
                  </div>
                  {/* End .product-ratings */}
                </div>
                {/* End .product-container */}
                <div className="price-box">
                  <span className="old-price">$59.00</span>
                  <span className="product-price">$49.00</span>
                </div>
                {/* End .price-box */}
                <div className="product-nav-filter product-nav-dots">
                  <a
                    href="#"
                    className="active"
                    style={{ background: "#cc9966" }}
                  >
                    <span className="sr-only">Color name</span>
                  </a>
                  <a href="#" style={{ background: "#7fc5ed" }}>
                    <span className="sr-only">Color name</span>
                  </a>
                  <a href="#" style={{ background: "#e8c97a" }}>
                    <span className="sr-only">Color name</span>
                  </a>
                </div>
              </div>
              {/* End .product-details */}
            </div>
          </div>
          {/* End .col-xl-5col */}
          <div className="col-6 col-sm-4 col-md-3 col-xl-5col">
            <div className="product-default inner-quickview inner-icon">
              <figure>
                <a href="demo5-product.html">
                  <img
                    src="assets/images/demoes/demo5/products/product-11.jpg"
                    width={212}
                    height={212}
                    alt="product"
                  />
                  <img
                    src="assets/images/demoes/demo5/products/product-12.jpg"
                    width={212}
                    height={212}
                    alt="product"
                  />
                </a>
                <div className="btn-icon-group">
                  <a
                    href="demo5-product.html"
                    className="btn-icon btn-add-cart"
                  >
                    <i className="fa fa-arrow-right" />
                  </a>
                </div>
                <a
                  href="ajax/product-quick-view.html"
                  className="btn-quickview"
                  title="Quick View"
                >
                  Quick View
                </a>
              </figure>
              <div className="product-details">
                <div className="category-wrap">
                  <div className="category-list">
                    <a href="demo5-shop.html" className="product-category">
                      category
                    </a>
                  </div>
                  <a
                    href="wishlist.html"
                    title="Add to Wishlist"
                    className="btn-icon-wish"
                  >
                    <i className="icon-heart" />
                  </a>
                </div>
                <h3 className="product-title">
                  <a href="demo5-product.html">Brown Bag</a>
                </h3>
                <div className="ratings-container">
                  <div className="product-ratings">
                    <span className="ratings" style={{ width: "100%" }} />
                    {/* End .ratings */}
                    <span className="tooltiptext tooltip-top" />
                  </div>
                  {/* End .product-ratings */}
                </div>
                {/* End .product-container */}
                <div className="price-box">
                  <span className="old-price">$59.00</span>
                  <span className="product-price">$49.00</span>
                </div>
                {/* End .price-box */}
              </div>
              {/* End .product-details */}
            </div>
          </div>
          {/* End .col-xl-5col */}
          <div className="col-6 col-sm-4 col-md-3 col-xl-5col">
            <div className="product-default inner-quickview inner-icon">
              <figure>
                <a href="demo5-product.html">
                  <img
                    src="assets/images/demoes/demo5/products/product-5.jpg"
                    width={212}
                    height={212}
                    alt="product"
                  />
                </a>
                <div className="label-group">
                  <span className="product-label label-hot">HOT</span>
                  <span className="product-label label-sale">-30%</span>
                </div>
                <div className="btn-icon-group">
                  <a
                    href="#"
                    title="Add To Cart"
                    className="btn-icon btn-add-cart product-type-simple"
                  >
                    <i className="icon-shopping-cart" />
                  </a>
                </div>
                <a
                  href="ajax/product-quick-view.html"
                  className="btn-quickview"
                  title="Quick View"
                >
                  Quick View
                </a>
              </figure>
              <div className="product-details">
                <div className="category-wrap">
                  <div className="category-list">
                    <a href="demo5-shop.html" className="product-category">
                      category
                    </a>
                  </div>
                  <a
                    href="wishlist.html"
                    title="Add to Wishlist"
                    className="btn-icon-wish"
                  >
                    <i className="icon-heart" />
                  </a>
                </div>
                <h3 className="product-title">
                  <a href="demo5-product.html">Black Women Shoes</a>
                </h3>
                <div className="ratings-container">
                  <div className="product-ratings">
                    <span className="ratings" style={{ width: "100%" }} />
                    {/* End .ratings */}
                    <span className="tooltiptext tooltip-top" />
                  </div>
                  {/* End .product-ratings */}
                </div>
                {/* End .product-container */}
                <div className="price-box">
                  <span className="old-price">$59.00</span>
                  <span className="product-price">$49.00</span>
                </div>
                {/* End .price-box */}
              </div>
              {/* End .product-details */}
            </div>
          </div>
          {/* End .col-xl-5col */}
          <div className="col-6 col-sm-4 col-md-3 col-xl-5col">
            <div className="product-default inner-quickview inner-icon">
              <figure>
                <a href="demo5-product.html">
                  <img
                    src="assets/images/demoes/demo5/products/product-6.jpg"
                    width={212}
                    height={212}
                    alt="product"
                  />
                </a>
                <div className="btn-icon-group">
                  <a
                    href="demo5-product.html"
                    className="btn-icon btn-add-cart"
                  >
                    <i className="fa fa-arrow-right" />
                  </a>
                </div>
                <a
                  href="ajax/product-quick-view.html"
                  className="btn-quickview"
                  title="Quick View"
                >
                  Quick View
                </a>
              </figure>
              <div className="product-details">
                <div className="category-wrap">
                  <div className="category-list">
                    <a href="demo5-shop.html" className="product-category">
                      category
                    </a>
                  </div>
                  <a
                    href="wishlist.html"
                    title="Add to Wishlist"
                    className="btn-icon-wish"
                  >
                    <i className="icon-heart" />
                  </a>
                </div>
                <h3 className="product-title">
                  <a href="demo5-product.html">Blue Sports Clothes</a>
                </h3>
                <div className="ratings-container">
                  <div className="product-ratings">
                    <span className="ratings" style={{ width: "100%" }} />
                    {/* End .ratings */}
                    <span className="tooltiptext tooltip-top" />
                  </div>
                  {/* End .product-ratings */}
                </div>
                {/* End .product-container */}
                <div className="price-box">
                  <span className="old-price">$59.00</span>
                  <span className="product-price">$49.00</span>
                </div>
                {/* End .price-box */}
              </div>
              {/* End .product-details */}
            </div>
          </div>
          {/* End .col-xl-5col */}
          <div className="col-6 col-sm-4 col-md-3 col-xl-5col">
            <div className="product-default inner-quickview inner-icon">
              <figure>
                <a href="demo5-product.html">
                  <img
                    src="assets/images/demoes/demo5/products/product-7.jpg"
                    width={212}
                    height={212}
                    alt="product"
                  />
                  <img
                    src="assets/images/demoes/demo5/products/product-10.jpg"
                    width={212}
                    height={212}
                    alt="product"
                  />
                </a>
                <div className="btn-icon-group">
                  <a
                    href="#"
                    title="Add To Cart"
                    className="btn-icon btn-add-cart product-type-simple"
                  >
                    <i className="icon-shopping-cart" />
                  </a>
                </div>
                <a
                  href="ajax/product-quick-view.html"
                  className="btn-quickview"
                  title="Quick View"
                >
                  Quick View
                </a>
              </figure>
              <div className="product-details">
                <div className="category-wrap">
                  <div className="category-list">
                    <a href="demo5-shop.html" className="product-category">
                      category
                    </a>
                  </div>
                  <a
                    href="wishlist.html"
                    title="Add to Wishlist"
                    className="btn-icon-wish"
                  >
                    <i className="icon-heart" />
                  </a>
                </div>
                <h3 className="product-title">
                  <a href="demo5-product.html">Blue Spring Casual Shoes</a>
                </h3>
                <div className="ratings-container">
                  <div className="product-ratings">
                    <span className="ratings" style={{ width: "100%" }} />
                    {/* End .ratings */}
                    <span className="tooltiptext tooltip-top" />
                  </div>
                  {/* End .product-ratings */}
                </div>
                {/* End .product-container */}
                <div className="price-box">
                  <span className="old-price">$59.00</span>
                  <span className="product-price">$49.00</span>
                </div>
                {/* End .price-box */}
                <div className="product-nav-filter product-nav-thumbs">
                  <a href="#" className="active">
                    <img
                      src="assets/images/demoes/demo5/products/product-1.jpg"
                      width={30}
                      height={30}
                      alt="prod-thumnail"
                    />
                  </a>
                  <a href="#">
                    <img
                      src="assets/images/demoes/demo5/products/product-16.jpg"
                      width={30}
                      height={30}
                      alt="prod-thumnail"
                    />
                  </a>
                </div>
              </div>
              {/* End .product-details */}
            </div>
          </div>
          {/* End .col-xl-5col */}
          <div className="col-6 col-sm-4 col-md-3 col-xl-5col">
            <div className="product-default inner-quickview inner-icon">
              <figure>
                <a href="demo5-product.html">
                  <img
                    src="assets/images/demoes/demo5/products/product-8.jpg"
                    width={212}
                    height={212}
                    alt="product"
                  />
                  <img
                    src="assets/images/demoes/demo5/products/product-15.jpg"
                    width={212}
                    height={212}
                    alt="product"
                  />
                </a>
                <div className="btn-icon-group">
                  <a
                    href="#"
                    title="Add To Cart"
                    className="btn-icon btn-add-cart product-type-simple"
                  >
                    <i className="icon-shopping-cart" />
                  </a>
                </div>
                <a
                  href="ajax/product-quick-view.html"
                  className="btn-quickview"
                  title="Quick View"
                >
                  Quick View
                </a>
              </figure>
              <div className="product-details">
                <div className="category-wrap">
                  <div className="category-list">
                    <a href="demo5-shop.html" className="product-category">
                      category
                    </a>
                  </div>
                  <a
                    href="wishlist.html"
                    title="Add to Wishlist"
                    className="btn-icon-wish"
                  >
                    <i className="icon-heart" />
                  </a>
                </div>
                <h3 className="product-title">
                  <a href="demo5-product.html">Grey Men Sports Cap</a>
                </h3>
                <div className="ratings-container">
                  <div className="product-ratings">
                    <span className="ratings" style={{ width: "100%" }} />
                    {/* End .ratings */}
                    <span className="tooltiptext tooltip-top" />
                  </div>
                  {/* End .product-ratings */}
                </div>
                {/* End .product-container */}
                <div className="price-box">
                  <span className="old-price">$59.00</span>
                  <span className="product-price">$49.00</span>
                </div>
                {/* End .price-box */}
              </div>
              {/* End .product-details */}
            </div>
          </div>
          {/* End .col-xl-5col */}
          <div className="col-6 col-sm-4 col-md-3 col-xl-5col">
            <div className="product-default inner-quickview inner-icon">
              <figure>
                <a href="demo5-product.html">
                  <img
                    src="assets/images/demoes/demo5/products/product-9.jpg"
                    width={212}
                    height={212}
                    alt="product"
                  />
                </a>
                <div className="label-group">
                  <span className="product-label label-hot">HOT</span>
                  <span className="product-label label-sale">-10%</span>
                </div>
                <div className="btn-icon-group">
                  <a
                    href="#"
                    title="Add To Cart"
                    className="btn-icon btn-add-cart product-type-simple"
                  >
                    <i className="icon-shopping-cart" />
                  </a>
                </div>
                <a
                  href="ajax/product-quick-view.html"
                  className="btn-quickview"
                  title="Quick View"
                >
                  Quick View
                </a>
              </figure>
              <div className="product-details">
                <div className="category-wrap">
                  <div className="category-list">
                    <a href="demo5-shop.html" className="product-category">
                      category
                    </a>
                  </div>
                  <a
                    href="wishlist.html"
                    title="Add to Wishlist"
                    className="btn-icon-wish"
                  >
                    <i className="icon-heart" />
                  </a>
                </div>
                <h3 className="product-title">
                  <a href="demo5-product.html">Men Black Jacket</a>
                </h3>
                <div className="ratings-container">
                  <div className="product-ratings">
                    <span className="ratings" style={{ width: "100%" }} />
                    {/* End .ratings */}
                    <span className="tooltiptext tooltip-top" />
                  </div>
                  {/* End .product-ratings */}
                </div>
                {/* End .product-container */}
                <div className="price-box">
                  <span className="old-price">$59.00</span>
                  <span className="product-price">$49.00</span>
                </div>
                {/* End .price-box */}
              </div>
              {/* End .product-details */}
            </div>
          </div>
          {/* End .col-xl-5col */}
          <div className="col-6 col-sm-4 col-md-3 col-xl-5col">
            <div className="product-default inner-quickview inner-icon">
              <figure>
                <a href="demo5-product.html">
                  <img
                    src="assets/images/demoes/demo5/products/product-13.jpg"
                    width={212}
                    height={212}
                    alt="product"
                  />
                  <img
                    src="assets/images/demoes/demo5/products/product-14.jpg"
                    width={212}
                    height={212}
                    alt="product"
                  />
                </a>
                <div className="btn-icon-group">
                  <a
                    href="demo5-product.html"
                    className="btn-icon btn-add-cart"
                  >
                    <i className="fa fa-arrow-right" />
                  </a>
                </div>
                <a
                  href="ajax/product-quick-view.html"
                  className="btn-quickview"
                  title="Quick View"
                >
                  Quick View
                </a>
              </figure>
              <div className="product-details">
                <div className="category-wrap">
                  <div className="category-list">
                    <a href="demo5-shop.html" className="product-category">
                      category
                    </a>
                  </div>
                  <a
                    href="wishlist.html"
                    title="Add to Wishlist"
                    className="btn-icon-wish"
                  >
                    <i className="icon-heart" />
                  </a>
                </div>
                <h3 className="product-title">
                  <a href="demo5-product.html">Porto Brown Bag</a>
                </h3>
                <div className="ratings-container">
                  <div className="product-ratings">
                    <span className="ratings" style={{ width: "100%" }} />
                    {/* End .ratings */}
                    <span className="tooltiptext tooltip-top" />
                  </div>
                  {/* End .product-ratings */}
                </div>
                {/* End .product-container */}
                <div className="price-box">
                  <span className="old-price">$59.00</span>
                  <span className="product-price">$49.00</span>
                </div>
                {/* End .price-box */}
              </div>
              {/* End .product-details */}
            </div>
          </div>
          {/* End .col-xl-5col */}
        </div>
        {/* End .row */}
        <a
          href="ajax/demo5-ajax-products.html"
          className="btn btn-dark btn-md font1 loadmore mb-1"
        >
          Load more...
        </a>
      </div>
      {/* End .container-box */}
    </div>
    {/* End .container */}
    <div className="container container-not-boxed mt-2">
      <div
        className="banner banner-big-sale mb-5"
        data-parallax="{'speed': 3.2, 'enableOnMobile': true}"
        data-image-src="assets/images/demoes/demo5/banners/banner-4.jpg"
      >
        <div className="banner-content row align-items-center py-3 mx-0">
          <div className="col-xl-9 col-sm-8 pt-3">
            <h2 className="text-white text-uppercase mb-md-0 px-3 text-center text-sm-left">
              <b className="d-inline-block mr-3 mb-1">Big Sale</b>
              All new fashion brands items up to 70% off
              <small className="text-transform-none align-middle">
                Online Purchases Only
              </small>
            </h2>
          </div>
          <div className="col-xl-3 col-sm-4 py-3 text-center text-sm-right">
            <a className="btn btn-light btn-lg mr-3 ls-10" href="#">
              View Sale
            </a>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="feature-boxes-container row mt-6 mb-1">
          <div className="col-md-4">
            <div className="feature-box px-sm-5 px-md-4 feature-box-simple text-center">
              <i className="sicon-earphones-alt" />
              <div className="feature-box-content">
                <h3 className="mb-0 pb-1">Customer Support</h3>
                <h5 className="m-b-3">Need Assistance?</h5>
                <p>
                  We really care about you and your website as much as you do.
                  Purchasing Porto or any other theme from us you get 100% free
                  support.
                </p>
              </div>
              {/* End .feature-box-content */}
            </div>
            {/* End .feature-box */}
          </div>
          {/* End .col-md-4 */}
          <div className="col-md-4">
            <div className="feature-box px-sm-5 px-md-4 feature-box-simple text-center">
              <i className="sicon-credit-card" />
              <div className="feature-box-content">
                <h3 className="mb-0 pb-1">Secured Payment</h3>
                <h5 className="m-b-3">Safe &amp; Fast</h5>
                <p>
                  With Porto you can customize the layout, colors and styles
                  within only a few minutes. Start creating an amazing website
                  right now!
                </p>
              </div>
              {/* End .feature-box-content */}
            </div>
            {/* End .feature-box */}
          </div>
          {/* End .col-md-4 */}
          <div className="col-md-4">
            <div className="feature-box px-sm-5 px-md-4 feature-box-simple text-center">
              <i className="sicon-action-undo" />
              <div className="feature-box-content">
                <h3 className="mb-0 pb-1">Returns</h3>
                <h5 className="m-b-3">Easy &amp; Free</h5>
                <p>
                  Porto has very powerful admin features to help customer to
                  build their own shop in minutes without any special skills in
                  web development.
                </p>
              </div>
              {/* End .feature-box-content */}
            </div>
            {/* End .feature-box */}
          </div>
          {/* End .col-md-4 */}
        </div>
        {/* End .feature-boxes-container.row */}
        <hr className="mt-0 m-b-5" />
        <div
          className="brands-slider owl-carousel owl-theme images-center"
          data-owl-options="{
                      'margin': 10,
                      'responsive': {
                          '991': {
                              'margin': 0
                          },
                          '1200': {
                              'margin': 0
                          }
                      }
                  }"
        >
          <img
            src="assets/images/brands/small/brand1.png"
            width={140}
            height={60}
            alt="brand"
          />
          <img
            src="assets/images/brands/small/brand2.png"
            width={140}
            height={60}
            alt="brand"
          />
          <img
            src="assets/images/brands/small/brand3.png"
            width={140}
            height={60}
            alt="brand"
          />
          <img
            src="assets/images/brands/small/brand4.png"
            width={140}
            height={60}
            alt="brand"
          />
          <img
            src="assets/images/brands/small/brand5.png"
            width={140}
            height={60}
            alt="brand"
          />
          <img
            src="assets/images/brands/small/brand6.png"
            width={140}
            height={60}
            alt="brand"
          />
        </div>
        {/* End .brands-slider */}
      </div>
      <hr className="mt-4 mb-0" />
    </div>
    {/* End .container-not-boxed */}
  </main>
  {/* End .main */}
</>



   

      <Footer /> 
    </>
  );
};

export default Accueil;
