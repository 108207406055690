import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

const ShopSidebar = ({ applyFilters }) => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);

  // Fonction pour appliquer les filtres automatiquement
  useEffect(() => {
    const filters = {
      categories: selectedCategories,
      sizes: selectedSizes,
      brands: selectedBrands,
      colors: selectedColors,
    };
    applyFilters(filters);
  }, [selectedCategories, selectedSizes, selectedBrands, selectedColors]);

  const handleCheckboxChange = (setter, selectedValues, value) => {
    if (selectedValues.includes(value)) {
      setter(selectedValues.filter(item => item !== value));
    } else {
      setter([...selectedValues, value]);
    }
  };

  const handleClearFilters = () => {
    setSelectedCategories([]);
    setSelectedSizes([]);
    setSelectedBrands([]);
    setSelectedColors([]);
    applyFilters({}); // Réinitialiser les filtres
  };

  return (
    <>
    <aside className="sidebar-shop col-lg-3 order-lg-first mobile-sidebar">
  <div className="pin-wrapper" style={{ height: "975.641px" }}>
    <div
      className="sidebar-wrapper"
      style={{ borderBottom: "0px none rgb(119, 119, 119)", width: 280 }}
    >
      <CollapsibleWidget title="Par espèces" defaultOpen={true}>
            <ul className="widget-body filter-items item-check mt-1">
              {['Aviculture', 'Élevage Lapins', 'Élevage Porcs', 'Élevage Ruminants', 'Pisciculture'].map((category) => (
                <li key={category}>
                  <label>
                    <input
                      type="checkbox"
                      checked={selectedCategories.includes(category)}
                      onChange={() => handleCheckboxChange(setSelectedCategories, selectedCategories, category)}
                    />
                    {category}
                  </label>
                </li>
              ))}
            </ul>
          </CollapsibleWidget>

          <CollapsibleWidget title="Par fournisseurs">
            <ul className="widget-body filter-items item-check">
              {['Biovac', 'Bioveta', 'Boehringer Ingelheim', 'Canavet', 'Ceva', 'Coophavet', 'Hipra', 'Huvepharma', 'Inovet', 'Lanavet', 'Laprovet', 'Medivet', 'Mixscience', 'Nutriblock', 'Pantex', 'Vetoquinol', 'Vial', 'Yors'].map((category) => (
                <li key={category}>
                  <label>
                    <input
                      type="checkbox"
                      checked={selectedCategories.includes(category)}
                      onChange={() => handleCheckboxChange(setSelectedCategories, selectedCategories, category)}
                    />
                    {category}
                  </label>
                </li>
              ))}
            </ul>
          </CollapsibleWidget>
          
          <CollapsibleWidget title="Types de produits">
            <ul className="widget-body filter-items item-check mt-1">
              {['Accessoires'].map((size) => (
                <li key={size}>
                  <label>
                    <input
                      type="checkbox"
                      checked={selectedSizes.includes(size)}
                      onChange={() => handleCheckboxChange(setSelectedSizes, selectedSizes, size)}
                    />
                    {size}
                  </label>
                </li>
              ))}
            </ul>
          </CollapsibleWidget>

          <CollapsibleWidget title="Nouveauté">
            <ul className="widget-body filter-items item-check mt-1">
              {['Nos nouveaux produits'].map((size) => (
                <li key={size}>
                  <label>
                    <input
                      type="checkbox"
                      checked={selectedSizes.includes(size)}
                      onChange={() => handleCheckboxChange(setSelectedSizes, selectedSizes, size)}
                    />
                    {size}
                  </label>
                </li>
              ))}
            </ul>
          </CollapsibleWidget>

          <CollapsibleWidget title="Promotions">
            <ul className="widget-body filter-items item-check mt-1">
              {['Nos promotions'].map((size) => (
                <li key={size}>
                  <label>
                    <input
                      type="checkbox"
                      checked={selectedSizes.includes(size)}
                      onChange={() => handleCheckboxChange(setSelectedSizes, selectedSizes, size)}
                    />
                    {size}
                  </label>
                </li>
              ))}
            </ul>
          </CollapsibleWidget>
          <CollapsibleWidget title="De retour dans les stocks">
            <ul className="widget-body filter-items item-check mt-1">
              {['nos promotions'].map((size) => (
                <li key={size}>
                  <label>
                    <input
                      type="checkbox"
                      checked={selectedSizes.includes(size)}
                      onChange={() => handleCheckboxChange(setSelectedSizes, selectedSizes, size)}
                    />
                    {size}
                  </label>
                </li>
              ))}
            </ul>
          </CollapsibleWidget>

          <CollapsibleWidget title="Brand">
            
          </CollapsibleWidget>

          <CollapsibleWidget title="Color">
            <ul className="widget-body filter-items item-check">
              {['Black', 'Blue', 'Red', 'Green', 'Grey', 'Orange', 'Yellow'].map((color) => (
                <li key={color}>
                  <label>
                    <input
                      type="checkbox"
                      checked={selectedColors.includes(color)}
                      onChange={() => handleCheckboxChange(setSelectedColors, selectedColors, color)}
                    />
                    {color}
                  </label>
                </li>
              ))}
            </ul>
          </CollapsibleWidget>
      <div className="widget">
        <h3 className="widget-title">
          <a
            data-toggle="collapse"
            href="#widget-body-2"
            role="button"
            aria-expanded="false"
            aria-controls="widget-body-2"
            className="collapsed"
          >
            Categories
          </a>
        </h3>

        
        <div className="collapse" id="widget-body-2" style={{}}>
          <div className="widget-body">
            <ul className="cat-list">
              <li>
                <a
                  href="#widget-category-1"
                  data-toggle="collapse"
                  role="button"
                  aria-expanded="true"
                  aria-controls="widget-category-1"
                >
                  Accessories<span className="products-count">(3)</span>
                  <span className="toggle" />
                </a>
                <div className="collapse show" id="widget-category-1">
                  <ul className="cat-sublist">
                    <li>
                      Caps<span className="products-count">(1)</span>
                    </li>
                    <li>
                      Watches<span className="products-count">(2)</span>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <a
                  href="#widget-category-2"
                  className="collapsed"
                  data-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="widget-category-2"
                >
                  Dress<span className="products-count">(4)</span>
                  <span className="toggle" />
                </a>
                <div className="collapse" id="widget-category-2">
                  <ul className="cat-sublist">
                    <li>
                      Clothing<span className="products-count">(4)</span>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <a
                  href="#widget-category-3"
                  className="collapsed"
                  data-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="widget-category-3"
                >
                  Electronics<span className="products-count">(2)</span>
                  <span className="toggle" />
                </a>
                <div className="collapse" id="widget-category-3">
                  <ul className="cat-sublist">
                    <li>
                      Headphone<span className="products-count">(1)</span>
                    </li>
                    <li>
                      Watch<span className="products-count">(1)</span>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <a
                  href="#widget-category-4"
                  className="collapsed"
                  data-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="widget-category-4"
                >
                  Fashion<span className="products-count">(6)</span>
                  <span className="toggle" />
                </a>
                <div className="collapse" id="widget-category-4">
                  <ul className="cat-sublist">
                    <li>
                      Shoes<span className="products-count">(4)</span>
                    </li>
                    <li>
                      Bag<span className="products-count">(2)</span>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <a  >
                  Music<span className="products-count">(2)</span>
                </a>
              </li>
            </ul>
          </div>
          {/* End .widget-body */}
        </div>
        {/* End .collapse */}
      </div>
      {/* End .widget */}
      <div className="widget">
        <h3 className="widget-title">
          <a
            data-toggle="collapse"
            href="#widget-body-3"
            role="button"
            aria-expanded="true"
            aria-controls="widget-body-3"
          >
            Price
          </a>
        </h3>
        <div className="collapse show" id="widget-body-3">
        <ul className="widget-body filter-items item-check mt-1">
              {['Brand A', 'Brand B', 'Brand C', 'Brand D'].map((brand) => (
                <li key={brand}>
                  <label>
                    <input
                      type="checkbox"
                      checked={selectedBrands.includes(brand)}
                      onChange={() => handleCheckboxChange(setSelectedBrands, selectedBrands, brand)}
                    />
                    {brand}
                  </label>
                </li>
              ))}
            </ul>
          {/* End .widget-body */}
        </div>
        {/* End .collapse */}
      </div>
      {/* End .widget */}
      <div className="widget widget-color">
        <h3 className="widget-title">
          <a
            data-toggle="collapse"
            href="#widget-body-4"
            role="button"
            aria-expanded="true"
            aria-controls="widget-body-4"
          >
            Color
          </a>
        </h3>
        <div className="collapse show" id="widget-body-4">
          <div className="widget-body pb-0">
            <ul className="config-swatch-list">
              <li className="active">
                <a href="#" style={{ backgroundColor: "#000" }} />
              </li>
              <li>
                <a href="#" style={{ backgroundColor: "#0188cc" }} />
              </li>
              <li>
                <a href="#" style={{ backgroundColor: "#81d742" }} />
              </li>
              <li>
                <a href="#" style={{ backgroundColor: "#6085a5" }} />
              </li>
              <li>
                <a href="#" style={{ backgroundColor: "#ab6e6e" }} />
              </li>
            </ul>
          </div>
          {/* End .widget-body */}
        </div>
        {/* End .collapse */}
      </div>
      {/* End .widget */}
      <div className="widget widget-size">
        <h3 className="widget-title">
          <a
            data-toggle="collapse"
            href="#widget-body-5"
            role="button"
            aria-expanded="true"
            aria-controls="widget-body-5"
          >
            Sizes
          </a>
        </h3>
        <div className="collapse show" id="widget-body-5">
          <div className="widget-body pb-0">
            <ul className="config-size-list">
              <li className="active">
                <a  >XL</a>
              </li>
              <li>
                <a  >L</a>
              </li>
              <li>
                <a  >M</a>
              </li>
              <li>
                <a  >S</a>
              </li>
            </ul>
          </div>
          {/* End .widget-body */}
        </div>
        {/* End .collapse */}
      </div>
      {/* End .widget */}
      <div className="widget widget-featured">
        <h3 className="widget-title">Featured</h3>
        <div className="widget-body">
          <div className="owl-carousel widget-featured-products owl-loaded owl-drag">
            {/* End .featured-col */}
            {/* End .featured-col */}
            <div className="owl-stage-outer owl-height" style={{ height: 300 }}>
              <div
                className="owl-stage"
                style={{
                  transform: "translate3d(-516px, 0px, 0px)",
                  transition: "all",
                  width: 1548
                }}
              >
                <div
                  className="owl-item cloned"
                  style={{ width: 238, marginRight: 20 }}
                >
                  <div className="featured-col">
                    <div className="product-default left-details product-widget">
                      <figure>
                        <a href="product.html">
                          <img
                            src="assets/images/products/small/product-4.jpg"
                            width={75}
                            height={75}
                            alt="product"
                          />
                          <img
                            src="assets/images/products/small/product-4-2.jpg"
                            width={75}
                            height={75}
                            alt="product"
                          />
                        </a>
                      </figure>
                      <div className="product-details">
                        <h3 className="product-title">
                          {" "}
                          <a href="product.html">
                            Blue Backpack for the Young - S
                          </a>{" "}
                        </h3>
                        <div className="ratings-container">
                          <div className="product-ratings">
                            <span
                              className="ratings"
                              style={{ width: "100%" }}
                            />
                            {/* End .ratings */}
                            <span className="tooltiptext tooltip-top" />
                          </div>
                          {/* End .product-ratings */}
                        </div>
                        {/* End .product-container */}
                        <div className="price-box">
                          <span className="product-price">$49.00</span>
                        </div>
                        {/* End .price-box */}
                      </div>
                      {/* End .product-details */}
                    </div>
                    <div className="product-default left-details product-widget">
                      <figure>
                        <a href="product.html">
                          <img
                            src="assets/images/products/small/product-5.jpg"
                            width={75}
                            height={75}
                            alt="product"
                          />
                          <img
                            src="assets/images/products/small/product-5-2.jpg"
                            width={75}
                            height={75}
                            alt="product"
                          />
                        </a>
                      </figure>
                      <div className="product-details">
                        <h3 className="product-title">
                          {" "}
                          <a href="product.html">
                            Casual Spring Blue Shoes
                          </a>{" "}
                        </h3>
                        <div className="ratings-container">
                          <div className="product-ratings">
                            <span
                              className="ratings"
                              style={{ width: "100%" }}
                            />
                            {/* End .ratings */}
                            <span className="tooltiptext tooltip-top" />
                          </div>
                          {/* End .product-ratings */}
                        </div>
                        {/* End .product-container */}
                        <div className="price-box">
                          <span className="product-price">$49.00</span>
                        </div>
                        {/* End .price-box */}
                      </div>
                      {/* End .product-details */}
                    </div>
                    <div className="product-default left-details product-widget">
                      <figure>
                        <a href="product.html">
                          <img
                            src="assets/images/products/small/product-6.jpg"
                            width={75}
                            height={75}
                            alt="product"
                          />
                          <img
                            src="assets/images/products/small/product-6-2.jpg"
                            width={75}
                            height={75}
                            alt="product"
                          />
                        </a>
                      </figure>
                      <div className="product-details">
                        <h3 className="product-title">
                          {" "}
                          <a href="product.html">Men Black Gentle Belt</a>{" "}
                        </h3>
                        <div className="ratings-container">
                          <div className="product-ratings">
                            <span
                              className="ratings"
                              style={{ width: "100%" }}
                            />
                            {/* End .ratings */}
                            <span className="tooltiptext tooltip-top" />
                          </div>
                          {/* End .product-ratings */}
                        </div>
                        {/* End .product-container */}
                        <div className="price-box">
                          <span className="product-price">$49.00</span>
                        </div>
                        {/* End .price-box */}
                      </div>
                      {/* End .product-details */}
                    </div>
                  </div>
                </div>
                <div
                  className="owl-item cloned"
                  style={{ width: 238, marginRight: 20 }}
                >
                  <div className="featured-col">
                    <div className="product-default left-details product-widget">
                      <figure>
                        <a href="product.html">
                          <img
                            src="assets/images/products/small/product-1.jpg"
                            width={75}
                            height={75}
                            alt="product"
                          />
                          <img
                            src="assets/images/products/small/product-1-2.jpg"
                            width={75}
                            height={75}
                            alt="product"
                          />
                        </a>
                      </figure>
                      <div className="product-details">
                        <h3 className="product-title">
                          {" "}
                          <a href="product.html">
                            Ultimate 3D Bluetooth Speaker
                          </a>{" "}
                        </h3>
                        <div className="ratings-container">
                          <div className="product-ratings">
                            <span
                              className="ratings"
                              style={{ width: "100%" }}
                            />
                            {/* End .ratings */}
                            <span className="tooltiptext tooltip-top" />
                          </div>
                          {/* End .product-ratings */}
                        </div>
                        {/* End .product-container */}
                        <div className="price-box">
                          <span className="product-price">$49.00</span>
                        </div>
                        {/* End .price-box */}
                      </div>
                      {/* End .product-details */}
                    </div>
                    <div className="product-default left-details product-widget">
                      <figure>
                        <a href="product.html">
                          <img
                            src="assets/images/products/small/product-2.jpg"
                            width={75}
                            height={75}
                            alt="product"
                          />
                          <img
                            src="assets/images/products/small/product-2-2.jpg"
                            width={75}
                            height={75}
                            alt="product"
                          />
                        </a>
                      </figure>
                      <div className="product-details">
                        <h3 className="product-title">
                          {" "}
                          <a href="product.html">
                            Brown Women Casual HandBag
                          </a>{" "}
                        </h3>
                        <div className="ratings-container">
                          <div className="product-ratings">
                            <span
                              className="ratings"
                              style={{ width: "100%" }}
                            />
                            {/* End .ratings */}
                            <span className="tooltiptext tooltip-top" />
                          </div>
                          {/* End .product-ratings */}
                        </div>
                        {/* End .product-container */}
                        <div className="price-box">
                          <span className="product-price">$49.00</span>
                        </div>
                        {/* End .price-box */}
                      </div>
                      {/* End .product-details */}
                    </div>
                    <div className="product-default left-details product-widget">
                      <figure>
                        <a href="product.html">
                          <img
                            src="assets/images/products/small/product-3.jpg"
                            width={75}
                            height={75}
                            alt="product"
                          />
                          <img
                            src="assets/images/products/small/product-3-2.jpg"
                            width={75}
                            height={75}
                            alt="product"
                          />
                        </a>
                      </figure>
                      <div className="product-details">
                        <h3 className="product-title">
                          {" "}
                          <a href="product.html">
                            Circled Ultimate 3D Speaker
                          </a>{" "}
                        </h3>
                        <div className="ratings-container">
                          <div className="product-ratings">
                            <span
                              className="ratings"
                              style={{ width: "100%" }}
                            />
                            {/* End .ratings */}
                            <span className="tooltiptext tooltip-top" />
                          </div>
                          {/* End .product-ratings */}
                        </div>
                        {/* End .product-container */}
                        <div className="price-box">
                          <span className="product-price">$49.00</span>
                        </div>
                        {/* End .price-box */}
                      </div>
                      {/* End .product-details */}
                    </div>
                  </div>
                </div>
                <div
                  className="owl-item active"
                  style={{ width: 238, marginRight: 20 }}
                >
                  <div className="featured-col">
                    <div className="product-default left-details product-widget">
                      <figure>
                        <a href="product.html">
                          <img
                            src="assets/images/products/small/product-4.jpg"
                            width={75}
                            height={75}
                            alt="product"
                          />
                          <img
                            src="assets/images/products/small/product-4-2.jpg"
                            width={75}
                            height={75}
                            alt="product"
                          />
                        </a>
                      </figure>
                      <div className="product-details">
                        <h3 className="product-title">
                          {" "}
                          <a href="product.html">
                            Blue Backpack for the Young - S
                          </a>{" "}
                        </h3>
                        <div className="ratings-container">
                          <div className="product-ratings">
                            <span
                              className="ratings"
                              style={{ width: "100%" }}
                            />
                            {/* End .ratings */}
                            <span className="tooltiptext tooltip-top" />
                          </div>
                          {/* End .product-ratings */}
                        </div>
                        {/* End .product-container */}
                        <div className="price-box">
                          <span className="product-price">$49.00</span>
                        </div>
                        {/* End .price-box */}
                      </div>
                      {/* End .product-details */}
                    </div>
                    <div className="product-default left-details product-widget">
                      <figure>
                        <a href="product.html">
                          <img
                            src="assets/images/products/small/product-5.jpg"
                            width={75}
                            height={75}
                            alt="product"
                          />
                          <img
                            src="assets/images/products/small/product-5-2.jpg"
                            width={75}
                            height={75}
                            alt="product"
                          />
                        </a>
                      </figure>
                      <div className="product-details">
                        <h3 className="product-title">
                          {" "}
                          <a href="product.html">
                            Casual Spring Blue Shoes
                          </a>{" "}
                        </h3>
                        <div className="ratings-container">
                          <div className="product-ratings">
                            <span
                              className="ratings"
                              style={{ width: "100%" }}
                            />
                            {/* End .ratings */}
                            <span className="tooltiptext tooltip-top" />
                          </div>
                          {/* End .product-ratings */}
                        </div>
                        {/* End .product-container */}
                        <div className="price-box">
                          <span className="product-price">$49.00</span>
                        </div>
                        {/* End .price-box */}
                      </div>
                      {/* End .product-details */}
                    </div>
                    <div className="product-default left-details product-widget">
                      <figure>
                        <a href="product.html">
                          <img
                            src="assets/images/products/small/product-6.jpg"
                            width={75}
                            height={75}
                            alt="product"
                          />
                          <img
                            src="assets/images/products/small/product-6-2.jpg"
                            width={75}
                            height={75}
                            alt="product"
                          />
                        </a>
                      </figure>
                      <div className="product-details">
                        <h3 className="product-title">
                          {" "}
                          <a href="product.html">Men Black Gentle Belt</a>{" "}
                        </h3>
                        <div className="ratings-container">
                          <div className="product-ratings">
                            <span
                              className="ratings"
                              style={{ width: "100%" }}
                            />
                            {/* End .ratings */}
                            <span className="tooltiptext tooltip-top" />
                          </div>
                          {/* End .product-ratings */}
                        </div>
                        {/* End .product-container */}
                        <div className="price-box">
                          <span className="product-price">$49.00</span>
                        </div>
                        {/* End .price-box */}
                      </div>
                      {/* End .product-details */}
                    </div>
                  </div>
                </div>
                <div
                  className="owl-item"
                  style={{ width: 238, marginRight: 20 }}
                >
                  <div className="featured-col">
                    <div className="product-default left-details product-widget">
                      <figure>
                        <a href="product.html">
                          <img
                            src="assets/images/products/small/product-1.jpg"
                            width={75}
                            height={75}
                            alt="product"
                          />
                          <img
                            src="assets/images/products/small/product-1-2.jpg"
                            width={75}
                            height={75}
                            alt="product"
                          />
                        </a>
                      </figure>
                      <div className="product-details">
                        <h3 className="product-title">
                          {" "}
                          <a href="product.html">
                            Ultimate 3D Bluetooth Speaker
                          </a>{" "}
                        </h3>
                        <div className="ratings-container">
                          <div className="product-ratings">
                            <span
                              className="ratings"
                              style={{ width: "100%" }}
                            />
                            {/* End .ratings */}
                            <span className="tooltiptext tooltip-top" />
                          </div>
                          {/* End .product-ratings */}
                        </div>
                        {/* End .product-container */}
                        <div className="price-box">
                          <span className="product-price">$49.00</span>
                        </div>
                        {/* End .price-box */}
                      </div>
                      {/* End .product-details */}
                    </div>
                    <div className="product-default left-details product-widget">
                      <figure>
                        <a href="product.html">
                          <img
                            src="assets/images/products/small/product-2.jpg"
                            width={75}
                            height={75}
                            alt="product"
                          />
                          <img
                            src="assets/images/products/small/product-2-2.jpg"
                            width={75}
                            height={75}
                            alt="product"
                          />
                        </a>
                      </figure>
                      <div className="product-details">
                        <h3 className="product-title">
                          {" "}
                          <a href="product.html">
                            Brown Women Casual HandBag
                          </a>{" "}
                        </h3>
                        <div className="ratings-container">
                          <div className="product-ratings">
                            <span
                              className="ratings"
                              style={{ width: "100%" }}
                            />
                            {/* End .ratings */}
                            <span className="tooltiptext tooltip-top" />
                          </div>
                          {/* End .product-ratings */}
                        </div>
                        {/* End .product-container */}
                        <div className="price-box">
                          <span className="product-price">$49.00</span>
                        </div>
                        {/* End .price-box */}
                      </div>
                      {/* End .product-details */}
                    </div>
                    <div className="product-default left-details product-widget">
                      <figure>
                        <a href="product.html">
                          <img
                            src="assets/images/products/small/product-3.jpg"
                            width={75}
                            height={75}
                            alt="product"
                          />
                          <img
                            src="assets/images/products/small/product-3-2.jpg"
                            width={75}
                            height={75}
                            alt="product"
                          />
                        </a>
                      </figure>
                      <div className="product-details">
                        <h3 className="product-title">
                          {" "}
                          <a href="product.html">
                            Circled Ultimate 3D Speaker
                          </a>{" "}
                        </h3>
                        <div className="ratings-container">
                          <div className="product-ratings">
                            <span
                              className="ratings"
                              style={{ width: "100%" }}
                            />
                            {/* End .ratings */}
                            <span className="tooltiptext tooltip-top" />
                          </div>
                          {/* End .product-ratings */}
                        </div>
                        {/* End .product-container */}
                        <div className="price-box">
                          <span className="product-price">$49.00</span>
                        </div>
                        {/* End .price-box */}
                      </div>
                      {/* End .product-details */}
                    </div>
                  </div>
                </div>
                <div
                  className="owl-item cloned"
                  style={{ width: 238, marginRight: 20 }}
                >
                  <div className="featured-col">
                    <div className="product-default left-details product-widget">
                      <figure>
                        <a href="product.html">
                          <img
                            src="assets/images/products/small/product-4.jpg"
                            width={75}
                            height={75}
                            alt="product"
                          />
                          <img
                            src="assets/images/products/small/product-4-2.jpg"
                            width={75}
                            height={75}
                            alt="product"
                          />
                        </a>
                      </figure>
                      <div className="product-details">
                        <h3 className="product-title">
                          {" "}
                          <a href="product.html">
                            Blue Backpack for the Young - S
                          </a>{" "}
                        </h3>
                        <div className="ratings-container">
                          <div className="product-ratings">
                            <span
                              className="ratings"
                              style={{ width: "100%" }}
                            />
                            {/* End .ratings */}
                            <span className="tooltiptext tooltip-top" />
                          </div>
                          {/* End .product-ratings */}
                        </div>
                        {/* End .product-container */}
                        <div className="price-box">
                          <span className="product-price">$49.00</span>
                        </div>
                        {/* End .price-box */}
                      </div>
                      {/* End .product-details */}
                    </div>
                    <div className="product-default left-details product-widget">
                      <figure>
                        <a href="product.html">
                          <img
                            src="assets/images/products/small/product-5.jpg"
                            width={75}
                            height={75}
                            alt="product"
                          />
                          <img
                            src="assets/images/products/small/product-5-2.jpg"
                            width={75}
                            height={75}
                            alt="product"
                          />
                        </a>
                      </figure>
                      <div className="product-details">
                        <h3 className="product-title">
                          {" "}
                          <a href="product.html">
                            Casual Spring Blue Shoes
                          </a>{" "}
                        </h3>
                        <div className="ratings-container">
                          <div className="product-ratings">
                            <span
                              className="ratings"
                              style={{ width: "100%" }}
                            />
                            {/* End .ratings */}
                            <span className="tooltiptext tooltip-top" />
                          </div>
                          {/* End .product-ratings */}
                        </div>
                        {/* End .product-container */}
                        <div className="price-box">
                          <span className="product-price">$49.00</span>
                        </div>
                        {/* End .price-box */}
                      </div>
                      {/* End .product-details */}
                    </div>
                    <div className="product-default left-details product-widget">
                      <figure>
                        <a href="product.html">
                          <img
                            src="assets/images/products/small/product-6.jpg"
                            width={75}
                            height={75}
                            alt="product"
                          />
                          <img
                            src="assets/images/products/small/product-6-2.jpg"
                            width={75}
                            height={75}
                            alt="product"
                          />
                        </a>
                      </figure>
                      <div className="product-details">
                        <h3 className="product-title">
                          {" "}
                          <a href="product.html">Men Black Gentle Belt</a>{" "}
                        </h3>
                        <div className="ratings-container">
                          <div className="product-ratings">
                            <span
                              className="ratings"
                              style={{ width: "100%" }}
                            />
                            {/* End .ratings */}
                            <span className="tooltiptext tooltip-top" />
                          </div>
                          {/* End .product-ratings */}
                        </div>
                        {/* End .product-container */}
                        <div className="price-box">
                          <span className="product-price">$49.00</span>
                        </div>
                        {/* End .price-box */}
                      </div>
                      {/* End .product-details */}
                    </div>
                  </div>
                </div>
                <div
                  className="owl-item cloned"
                  style={{ width: 238, marginRight: 20 }}
                >
                  <div className="featured-col">
                    <div className="product-default left-details product-widget">
                      <figure>
                        <a href="product.html">
                          <img
                            src="assets/images/products/small/product-1.jpg"
                            width={75}
                            height={75}
                            alt="product"
                          />
                          <img
                            src="assets/images/products/small/product-1-2.jpg"
                            width={75}
                            height={75}
                            alt="product"
                          />
                        </a>
                      </figure>
                      <div className="product-details">
                        <h3 className="product-title">
                          {" "}
                          <a href="product.html">
                            Ultimate 3D Bluetooth Speaker
                          </a>{" "}
                        </h3>
                        <div className="ratings-container">
                          <div className="product-ratings">
                            <span
                              className="ratings"
                              style={{ width: "100%" }}
                            />
                            {/* End .ratings */}
                            <span className="tooltiptext tooltip-top" />
                          </div>
                          {/* End .product-ratings */}
                        </div>
                        {/* End .product-container */}
                        <div className="price-box">
                          <span className="product-price">$49.00</span>
                        </div>
                        {/* End .price-box */}
                      </div>
                      {/* End .product-details */}
                    </div>
                    <div className="product-default left-details product-widget">
                      <figure>
                        <a href="product.html">
                          <img
                            src="assets/images/products/small/product-2.jpg"
                            width={75}
                            height={75}
                            alt="product"
                          />
                          <img
                            src="assets/images/products/small/product-2-2.jpg"
                            width={75}
                            height={75}
                            alt="product"
                          />
                        </a>
                      </figure>
                      <div className="product-details">
                        <h3 className="product-title">
                          {" "}
                          <a href="product.html">
                            Brown Women Casual HandBag
                          </a>{" "}
                        </h3>
                        <div className="ratings-container">
                          <div className="product-ratings">
                            <span
                              className="ratings"
                              style={{ width: "100%" }}
                            />
                            {/* End .ratings */}
                            <span className="tooltiptext tooltip-top" />
                          </div>
                          {/* End .product-ratings */}
                        </div>
                        {/* End .product-container */}
                        <div className="price-box">
                          <span className="product-price">$49.00</span>
                        </div>
                        {/* End .price-box */}
                      </div>
                      {/* End .product-details */}
                    </div>
                    <div className="product-default left-details product-widget">
                      <figure>
                        <a href="product.html">
                          <img
                            src="assets/images/products/small/product-3.jpg"
                            width={75}
                            height={75}
                            alt="product"
                          />
                          <img
                            src="assets/images/products/small/product-3-2.jpg"
                            width={75}
                            height={75}
                            alt="product"
                          />
                        </a>
                      </figure>
                      <div className="product-details">
                        <h3 className="product-title">
                          {" "}
                          <a href="product.html">
                            Circled Ultimate 3D Speaker
                          </a>{" "}
                        </h3>
                        <div className="ratings-container">
                          <div className="product-ratings">
                            <span
                              className="ratings"
                              style={{ width: "100%" }}
                            />
                            {/* End .ratings */}
                            <span className="tooltiptext tooltip-top" />
                          </div>
                          {/* End .product-ratings */}
                        </div>
                        {/* End .product-container */}
                        <div className="price-box">
                          <span className="product-price">$49.00</span>
                        </div>
                        {/* End .price-box */}
                      </div>
                      {/* End .product-details */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="owl-nav">
              <button
                type="button"
                title="nav"
                role="presentation"
                className="owl-prev"
              >
                <i className="icon-angle-left" />
              </button>
              <button
                type="button"
                title="nav"
                role="presentation"
                className="owl-next"
              >
                <i className="icon-angle-right" />
              </button>
            </div>
            <div className="owl-dots disabled" />
          </div>
          {/* End .widget-featured-slider */}
        </div>
        {/* End .widget-body */}
      </div>
      {/* End .widget */}
      <div className="widget widget-block">
        <h3 className="widget-title">Custom HTML Block</h3>
        <h5>This is a custom sub-title.</h5>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras non
          placerat mi. Etiam non tellus{" "}
        </p>
      </div>
      {/* End .widget */}
    </div>
  </div>
  {/* End .sidebar-wrapper */}
</aside>

    <aside className="sidebar shop-sidebar left-sidebar sticky-sidebar-wrapper sidebar-fixed">
      <div className="sidebar-overlay"></div>
      <a className="sidebar-close" href="#"><i className="close-icon"></i></a>

      <div className="sidebar-content scrollable p-3">
        <div className="sticky-sidebar">
          <div className="filter-actions">
            <label>Filtre :</label>
            <button className="btn btn-primary btn-rounded" onClick={handleClearFilters}>Réinitialiser</button>
          </div>

          
        </div>
      </div>
    </aside>

    </>
  );
};

const CollapsibleWidget = ({ title, defaultOpen, children }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="widget widget-collapsible">
      <div className="widget-header" onClick={toggleOpen}>
        <h3 className="widget-title w-100">
          {title}
          <FontAwesomeIcon icon={isOpen ? faMinus : faPlus} className="ml-2" />
        </h3>
      </div>
      {isOpen && <div className="widget-body">{children}</div>}
    </div>
  );
};

export default ShopSidebar;
