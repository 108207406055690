import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CatalogueListe from './CatalogueListe';

const Catalogue = () => {
  const [searchTerm, setSearchTerm] = useState("");

  return (

    <>

    <div id='siteWebwrapper'>
      <div id="otherpage" cl>
        <Header />
        <div className="App">
          <div id='site-web' className="container justify-content">

          <>
          <main className="main contact-two">
            <nav aria-label="breadcrumb" className="breadcrumb-nav mb-0">
                <div className="container">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                    <a href="demo5.html">Home</a>
                    </li>
                    <li className="breadcrumb-item">
                    <a  >Pages</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                    Contact Us
                    </li>
                </ol>
                </div>
                {/* End .container */}
            </nav>
            
            <div className=" bg-white mb-0 p-4">
            <CatalogueListe searchTerm={searchTerm} />
            </div>
           </main>
          </>

          </div>
        </div>
        <Footer />
      </div>
    </div>
      
    </>
    
    // <>
    //   <div className="body-container">
    //     <div className="container">
    //       <Header />
          
    //       <div className='about-pg-1'>
    //         <CatalogueListe searchTerm={searchTerm} />
    //       </div>
    //     </div>
    //   </div>

    //   <div className='tc-footer-style3'>
    //     <div className='container'>
    //       <Footer />
    //     </div>
    //   </div>
    // </>
  );
};

export default Catalogue;
