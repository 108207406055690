import React from 'react';
import { NavLink } from 'react-router-dom';


const ProductSimilaireDetail = ({ product }) => {
  // Vérifier si les données utilisateur sont présentes dans localStorage
  const userData = JSON.parse(localStorage.getItem('user'));
  localStorage.setItem('selectedProductId', product.id);


  return (
    <>
    
      <div className="mx-3">
      <NavLink to={"/detail-produit"} >
        <div className="product-wrap product text-center">
          <figure className="product-media">
            <a href={product.href}><img src={product.image} alt={product.name} width="216" height="243" /></a>
            <div className="product-action-vertical">
              <a href="#" className="btn-product-icon btn-cart w-icon-cart" title="Ajouter au panier"></a>
              <a href="#" className="btn-product-icon btn-wishlist w-icon-heart" title="Add to wishlist"></a>
              <a href="#" className="btn-product-icon btn-quickview w-icon-search" title="Quickview"></a>
              <a href="#" className="btn-product-icon btn-compare w-icon-compare" title="Add to Compare"></a>
            </div>
          </figure>
          <div className="product-details">
            <h4 className="product-name"><a href={product.href}>{product.name}</a></h4>
            {/* <div className="ratings-container">
              <div className="ratings-full">
                <span className="ratings" style={{ width: product.ratings }}></span>
                <span className="tooltiptext tooltip-top"></span>
              </div>
              <a href={product.href} className="rating-reviews">{product.reviews}</a>
            </div> */}
            {userData ? (
              // Vérifier si userData est défini (c'est-à-dire si localStorage contient 'user')
              <div className="product-price">
                <ins className="new-price">{product.price} FCFA</ins>
                <del className="old-price">{product.oldprice} FCFA</del>
              </div>
            ) : (
              <div className="product-price">
                {/* <span>Connectez-vous pour voir le prix</span> */}
              </div>
            )}
          </div>
        </div>
        </NavLink>
      </div>
      
    </>
  );
};

export default ProductSimilaireDetail;
