// apiService.js
const API_URL = 'http://160.120.155.165:8082/v1';
const API_KEY = 'ZghY887665YhGH';
const LOGIN_CREDENTIALS = {
  login: 'CLIENTREST',
  password: '123'
};

async function getToken() {
  try {
    const response = await fetch(`${API_URL}/login/user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'api_key': API_KEY
      },
      body: new URLSearchParams(LOGIN_CREDENTIALS)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data.access_token; // Assuming the response contains the token in this format
  } catch (error) {
    console.error('Error fetching token:', error);
    throw error;
  }
}

async function getProducts(
  token,
  nbByPage = 20,
  page = 1,
  query = '',
) {
  try {
    const response = await fetch(`${API_URL}/products?nb_by_page=${nbByPage}&page=${page}&query=${query}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'api_key': API_KEY,
        'token': token
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return {
      products: data.products,
      nb_pages: parseInt(data.nb_pages, 10),
      current_page: parseInt(data.current_page, 10)
    }; // Returning additional pagination info
  } catch (error) {
    console.error('Error fetching products:', error);
    throw error;
  }
}

export { getToken, getProducts };
