import React, { useState } from 'react';

const ShopSidebar = ({ applyFilters }) => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);

  const handleApplyFilters = () => {
    const filters = {
      categories: selectedCategories,
      sizes: selectedSizes,
      brands: selectedBrands,
      colors: selectedColors,
    };
    applyFilters(filters);
  };

  const handleClearFilters = () => {
    setSelectedCategories([]);
    setSelectedSizes([]);
    setSelectedBrands([]);
    setSelectedColors([]);
    applyFilters({}); // Réinitialiser les filtres
  };

  const handleCheckboxChange = (setter, selectedValues, value) => {
    if (selectedValues.includes(value)) {
      setter(selectedValues.filter(item => item !== value));
    } else {
      setter([...selectedValues, value]);
    }
  };


  return (
    <aside className="sidebar shop-sidebar left-sidebar sticky-sidebar-wrapper sidebar-fixed ">
      <div className="sidebar-overlay"></div>
      <a className="sidebar-close" href="#"><i className="close-icon"></i></a>

      <div className="sidebar-content scrollable p-3">
        <div className="sticky-sidebar">
          <div className="filter-actions">
            <label>Filter :</label>
            <a href="#" className="btn btn-dark btn-link filter-clean" onClick={handleClearFilters}>Clean All</a>
          </div>

          <CollapsibleWidget title="Par fournisseurs">
            <ul className="widget-body filter-items item-check">
              {['Biovac' ,'Bioveta' ,'Boehringer Ingelheim' ,'Canavet' ,'Ceva' ,'Coophavet' ,'Hipra' ,'Huvepharma' ,'Inovet' ,'Lanavet' ,'Laprovet' ,'Medivet' ,'Mixscience' ,'Nutriblock' ,'Pantex' ,'Vetoquinol' ,'Vial' ,'Yors'].map((category) => (
                <li key={category}>
                  <label>
                    <input
                      type="checkbox"
                      checked={selectedCategories.includes(category)}
                      onChange={() => handleCheckboxChange(setSelectedCategories, selectedCategories, category)}
                    />
                    {category}
                  </label>
                </li>
              ))}
            </ul>
          </CollapsibleWidget>

          <CollapsibleWidget title="Par espèces">
            <ul className="widget-body filter-items item-check mt-1">
              {['Aviculture','Élevage Lapins','Élevage Porcs','Élevage Ruminants','Pisciculture'].map((size) => (
                <li key={size}>
                  <label>
                    <input
                      type="checkbox"
                      checked={selectedSizes.includes(size)}
                      onChange={() => handleCheckboxChange(setSelectedSizes, selectedSizes, size)}
                    />
                    {size}
                  </label>
                </li>
              ))}
            </ul>
          </CollapsibleWidget>

          {/* <CollapsibleWidget title="Brand">
            <ul className="widget-body filter-items item-check mt-1">
              {['Brand A', 'Brand B', 'Brand C', 'Brand D'].map((brand) => (
                <li key={brand}>
                  <label>
                    <input
                      type="checkbox"
                      checked={selectedBrands.includes(brand)}
                      onChange={() => handleCheckboxChange(setSelectedBrands, selectedBrands, brand)}
                    />
                    {brand}
                  </label>
                </li>
              ))}
            </ul>
          </CollapsibleWidget> */}

          {/* <CollapsibleWidget title="Color">
            <ul className="widget-body filter-items item-check">
              {['Black', 'Blue', 'Red', 'Green', 'Grey', 'Orange', 'Yellow'].map((color) => (
                <li key={color}>
                  <label>
                    <input
                      type="checkbox"
                      checked={selectedColors.includes(color)}
                      onChange={() => handleCheckboxChange(setSelectedColors, selectedColors, color)}
                    />
                    {color}
                  </label>
                </li>
              ))}
            </ul>
          </CollapsibleWidget> */}

          <div className="filter-actions">
            <button className="btn btn-primary btn-rounded" onClick={handleApplyFilters}>Apply Filters</button>
          </div>
        </div>
      </div>
    </aside>
  );
};

const CollapsibleWidget = ({ title, children }) => (
  <div className="widget widget-collapsible">
    <h3 className="widget-title"><label>{title}</label></h3>
    {children}
  </div>
);

export default ShopSidebar;
