// ProductList.js
import React, { useEffect, useState } from 'react';
import { getToken, getProducts } from './apiService';
import ProductCard from './ProductCard';
import './product_css.css';


const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const params = {
    token: '',
    nbByPage: 10,
    page: currentPage,
    modDate: '2024-01-01', // Example date
    query: 'TYLODOX PLUS SACHET 100G', //COF
    categ: '',
    souscateg: '',
    sousouscateg: '',
    imagein: '',
    imagesize: '',
    brand: '',
    brands: 'X',
    productPrice: ''
  };

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        const token = await getToken();
        const { products, nb_pages } = await getProducts(token, 10, currentPage, params.query);
        setProducts(products || []);
        setTotalPages(nb_pages);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    const user = localStorage.getItem('user');
    if (user) {
      setUserData(JSON.parse(user));
    }

    fetchProducts();
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleClick = (productId) => {
    console.log(`Product ID: ${productId}`);
    // Implement additional logic when a product is clicked
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
    <div className="product-list">
      <div className="row">
        {products.length > 0 ? (
          products.map((product) => (
            <ProductCard 
              key={product.ArtID} 
              product={product} 
              handleClick={handleClick} 
              userData={userData} 
            />
          ))
        ) : (
          <div>No products available</div>
        )}
      </div>
    </div>
    <div className='col-12'>
    <nav aria-label="Page navigation example">
      <ul className="pagination justify-content-center">
        {Array.from({ length: totalPages }, (_, index) => (
          <li key={index + 1} className={`page-item ${index + 1 === currentPage ? 'active' : ''}`}>
            <button
              className="page-link"
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  </div>
  </>
  );
};

export default ProductList;
