import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from '../components/Header'; // Importation du composant Header
import Footer from '../components/Footer'; 


const IndexSite = () => {

  const fullUrl = localStorage.getItem("fullUrl");

  const products = [
    { id: 1, title: 'Boulon 8x40mm', 
      images: [
        "assets/images/products/BOUL-10X50.png",
        "assets/images/products/BOUL-10X50.png",
      ],
    },
    { id: 2, title: 'Boulon 8x50mm', 
      images: [
        "assets/images/products/BOUL-10X50.png",
        "assets/images/products/BOUL-10X50.png",
      ],
    },
    { id: 3, title: 'Boulon 8x60mm', 
      images: [
        "assets/images/products/BOUL-10X50.png",
        "assets/images/products/BOUL-10X50.png",
      ],
    },
    { id: 4, title: 'Boulon 10x40mm',
      images: [
        "assets/images/products/BOUL-10X50.png",
        "assets/images/products/BOUL-10X50.png",
      ],
    },
    { id: 5, title: 'Boulon 10x50mm',
      images: [
        "assets/images/products/BOUL-10X50.png",
        "assets/images/products/BOUL-10X50.png",
      ],
    },
    
    
    // Ajoute d'autres objets pour chaque produit ici...
  ];

  const features = [
    {
      icon: "sicon-earphones-alt",
      title: "Customer Support",
      subtitle: "Need Assistance?",
      content: "We really care about you and your website as much as you do. Purchasing Porto or any other theme from us you get 100% free support."
    },
    {
      icon: "sicon-credit-card",
      title: "Secured Payment",
      subtitle: "Safe & Fast",
      content: "With Porto you can customize the layout, colors and styles within only a few minutes. Start creating an amazing website right now!"
    },
    {
      icon: "sicon-action-undo",
      title: "Returns",
      subtitle: "Easy & Free",
      content: "Porto has very powerful admin features to help customer to build their own shop in minutes without any special skills in web development."
    }
  ];
  
  return (

    <>

    <div id='siteWebwrapper'>
      <div id="otherpage" cl>
        <Header />
        <div className="App">
          <div id='site-web' className="container justify-content">

          <>
            <main className="main">
              <div className="container container-not-boxed">
                <div
                  className="info-boxes-slider owl-carousel owl-theme"
                  data-owl-options="{
                      'dots': false,
                            'loop': false,
                      'responsive': {
                        '0': {
                          'items': 1
                                },
                                '480': {
                          'items': 2
                                },
                        '992': {
                          'items': 3
                        }
                      }
                    }"
                >
                  <div className="info-box info-box-icon-left">
                    <i className="icon-shipping font-35 pt-1" />
                    <div className="info-box-content pt-1">
                      <h4>FREE SHIPPING &amp; RETURN</h4>
                      <p className="text-body">Free shipping on all orders over $99</p>
                    </div>
                    {/* End .info-box-content */}
                  </div>
                  {/* End .info-box */}
                  <div className="info-box info-box-icon-left">
                    <i className="icon-money pt-1" />
                    <div className="info-box-content">
                      <h4>MONEY BACK GUARANTEE</h4>
                      <p className="text-body">100% money back guarantee</p>
                    </div>
                    {/* End .info-box-content */}
                  </div>
                  {/* End .info-box */}
                  <div className="info-box info-box-icon-left">
                    <i className="icon-support pt-1" />
                    <div className="info-box-content">
                      <h4>ONLINE SUPPORT 24/7</h4>
                      <p className="text-body">Lorem ipsum dolor sit amet.</p>
                    </div>
                    {/* End .info-box-content */}
                  </div>
                  {/* End .info-box */}
                </div>
                {/* End .info-boxes-slider */}
                <div className="home-slider outer-container w-auto owl-carousel owl-theme owl-carousel-lazy show-nav-hover nav-large nav-outer mb-2">
                  <div className="home-slide home-slide1 banner banner-md-vw bg-white">
                    <img
                      className="slide-bg"
                      src={fullUrl + "assets/images/slider/banner-emtop.png" }
                      style={{ backgroundColor: "#fff" }}
                      alt="slider image"
                    />
                  </div>
                  <div className="home-slide home-slide2 banner banner-md-vw">
                    <img
                      className="slide-bg"
                      src={fullUrl + "assets/images/slider/slide-2.jpg"}
                      style={{ backgroundColor: "#ccc" }}
                      alt="slider image"
                    />
                  </div>
                </div>
                
                {/* End .row.home-products */}
                <div className="banners-slider owl-carousel owl-theme mb-4 mb-md-2">
                  <div className="banner banner1 banner-sm-vw">
                    <figure>
                      <img
                        src={fullUrl + "assets/images/banners/banner-emtop.png"}
                        style={{ backgroundColor: "#fff" }}
                        alt="banner"
                      />
                    </figure>
                    
                  </div>
                  {/* End .banner */}
                  <div className="banner banner3 banner-sm-vw text-uppercase">
                    <figure>
                      <img
                        src={fullUrl + "assets/images/banners/slide-2.jpg"}
                        style={{ backgroundColor: "#fff" }}
                        alt="banner"
                      />
                    </figure>
                    
                    
                  </div>
                  {/* End .banner */}
                  <div className="banner banner3 banner-sm-vw">
                    <figure>
                      <img
                        src={fullUrl + "assets/images/banners/slide-2.jpg"}
                        style={{ backgroundColor: "#fff" }}
                        alt="banner"
                      />
                    </figure>
                    
                  </div>
                  {/* End .banner */}
                </div>
              </div>
              {/* End .container-not-boxed */}
              <div className="container">
                <div className="products-container bg-white text-center mb-2">
                  <div className="row product-ajax-grid mb-2">
                    {products.map((product) => (
                      <div key={product.id} className="col-6 col-sm-4 col-md-3 col-xl-5col">
                        <div className="product-default inner-quickview inner-icon">
                          <figure>
                            <a  >
                              {product.images.map((image, index) => (
                                <img
                                  key={index}
                                  src={fullUrl+ image}
                                  width={212}
                                  height={212}
                                  alt="product"
                                />
                              ))}
                            </a>
                            <div className="btn-icon-group">
                              <a
                                href="#"
                                title="Add To Cart"
                                className="btn-icon btn-add-cart product-type-simple"
                              >
                                <i className="icon-shopping-cart" />
                              </a>
                            </div>
                            <a
                              href="ajax/product-quick-view.html"
                              className="btn-quickview"
                              title="Quick View"
                            >
                              Quick View
                            </a>
                          </figure>
                          <div className="product-details">
                            <div className="category-wrap">
                              <div className="category-list">
                                <a href="demo5-shop.html" className="product-category">
                                  {product.category}
                                </a>
                              </div>
                              <a
                                href="wishlist.html"
                                title="Add to Wishlist"
                                className="btn-icon-wish"
                              >
                                <i className="icon-heart" />
                              </a>
                            </div>
                            <h3 className="product-title">
                              <a href="demo5-product.html">{product.title}</a>
                            </h3>
                            <div className="ratings-container">
                              <div className="product-ratings">
                                <span className="ratings" style={{ width: "100%" }} />
                                <span className="tooltiptext tooltip-top" />
                              </div>
                            </div>
                            <div className="price-box">
                              <span className="old-price">{product.oldPrice}</span>
                              <span className="product-price">{product.price}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <a
                    href="ajax/demo5-ajax-products.html"
                    className="btn btn-dark btn-md font1 loadmore mb-1"
                  >
                    Load more...
                  </a>
                </div>
              </div>

              {/* End .container */}
              <div className="container container-not-boxed mt-2">
                <div
                  className="banner banner-big-sale mb-5"
                  data-parallax="{'speed': 3.2, 'enableOnMobile': true}"
                  data-image-src="assets/images/banners/banner-4.jpg"
                >
                  <div className="banner-content row align-items-center py-3 mx-0">
                    <div className="col-xl-9 col-sm-8 pt-3">
                      <h2 className="text-white text-uppercase mb-md-0 px-3 text-center text-sm-left">
                        <b className="d-inline-block mr-3 mb-1">Big Sale</b>
                        All new fashion brands items up to 70% off
                        <small className="text-transform-none align-middle">
                          Online Purchases Only
                        </small>
                      </h2>
                    </div>
                    <div className="col-xl-3 col-sm-4 py-3 text-center text-sm-right">
                      <a className="btn btn-light btn-lg mr-3 ls-10" href="#">
                        View Sale
                      </a>
                    </div>
                  </div>
                </div>
                <div className="container">
                <div className="feature-boxes-container row mt-6 mb-1">
                  {features.map((feature, index) => (
                    <div className="col-md-4" key={index}>
                      <div className="feature-box px-sm-5 px-md-4 feature-box-simple text-center">
                        <i className={feature.icon} />
                        <div className="feature-box-content">
                          <h3 className="mb-0 pb-1">{feature.title}</h3>
                          <h5 className="m-b-3">{feature.subtitle}</h5>
                          <p>{feature.content}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <hr className="mt-0 m-b-5" />
                <div
                  className="brands-slider owl-carousel owl-theme images-center"
                  data-owl-options="{
                    'margin': 10,
                    'responsive': {
                      '991': {
                        'margin': 0
                      },
                      '1200': {
                        'margin': 0
                      }
                    }
                  }"
                >
                  <img
                    src={fullUrl + "assets/images/brands/small/banner-3.jpg"}
                    width={140}
                    height={60}
                    alt="brand"
                  />
                  <img
                    src={fullUrl + "assets/images/brands/small/banner-3.jpg"}
                    width={140}
                    height={60}
                    alt="brand"
                  />
                  <img
                    src={fullUrl + "assets/images/brands/small/banner-3.jpg"}
                    width={140}
                    height={60}
                    alt="brand"
                  />
                  <img
                    src={fullUrl + "assets/images/brands/small/banner-3.jpg"}
                    width={140}
                    height={60}
                    alt="brand"
                  />
                  <img
                    src={fullUrl + "assets/images/brands/small/banner-3.jpg"}
                    width={140}
                    height={60}
                    alt="brand"
                  />
                  <img
                    src={fullUrl + "assets/images/brands/small/banner-3.jpg"}
                    width={140}
                    height={60}
                    alt="brand"
                  />
                </div>
              </div>

                <hr className="mt-4 mb-0" />
              </div>
              {/* End .container-not-boxed */}
            </main>
            {/* End .main */}
          </>

          </div>
        </div>
        <Footer />
      </div>
    </div>
      
    </>
    
  );
};

export default IndexSite;
