// App.js
import ProductList from './ProductList';

import React, { useState, useEffect } from 'react';
import ShopSidebar from './ShopSidebar';
// import MainContent from './MainContent';
import Header from '../pages/components/Header'; // Importation du composant Header
import Footer from '../pages/components/Footer'; 
import { useNavigate } from 'react-router-dom'; // Utilisez useNavigate pour la redirection

//import './App.css'; // Assurez-vous d'importer votre fichier CSS

const DisplayData = () => {

  
  const navigate = useNavigate(); 
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    console.log(user);
    if (!user) {
      navigate('/'); // Rediriger vers la page de connexion si l'utilisateur n'est pas connecté
    }
  }, [navigate]);



  // Exemple de données de produits
  const [products] = useState([
    { id: 1, name: 'ALBEN ORAL 10 1L PROFIL', image: "assets/images/products/ALBEN_ORAL 10_1L_PROFIL.jpg", category: 'Accessories', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)' },
        { id: 2, name: 'ALBENVET', image: "assets/images/products/ALBENVET_FACE.jpg", category: 'Fashion', price: 2500, oldprice: 2700, size: 'Large', brand: 'Brand B', color: 'Blue', href: '#', ratings: '85%', reviews: '(20 reviews)' },
        { id: 3, name: 'AMINOVIT PLUS 1KG ', image: "assets/images/products/AMINOVIT_PLUS_1KG_FACE.jpg", category: 'Electronics', price: 3000, oldprice: 3200, size: 'Large', brand: 'Brand C', color: 'Black', href: '#', ratings: '90%', reviews: '(30 reviews)' },
        { id: 4, name: 'AMINOVIT PLUS 150G ', image: "assets/images/products/AMINOVIT_PLUS_150G_FACE.jpg", category: 'Fashion', price: 1800, oldprice: 2000, size: 'Medium', brand: 'Brand A', color: 'Red', href: '#', ratings: '75%', reviews: '(15 reviews)' },
        { id: 5, name: 'AMOXY-C 1KG FACE', image: "assets/images/products/AMOXY-C_1KG_FACE.jpg", category: 'Accessories', price: 2200, oldprice: 2400, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)' },
        { id: 6, name: 'AMPROLE 200 1KG', image: "assets/images/products/AMPROLE_200_1KG_FACE.jpg", category: 'Accessories', price: 2500, oldprice: 2700, size: 'Large', brand: 'Brand B', color: 'Blue', href: '#', ratings: '85%', reviews: '(20 reviews)' },
        { id: 7, name: 'AMPROLE 200 SACHET DE 100G', image: "assets/images/products/AMPROLE_200_SACHET_DE_100G_FACE.jpg", category: 'Electronics', price: 3000, oldprice: 3200, size: 'Large', brand: 'Brand C', color: 'Black', href: '#', ratings: '90%', reviews: '(30 reviews)' },
        { id: 8, name: 'COLISTIN 5 BOU 100 ', image: "assets/images/products/COLISTIN_5_BOU_100_DOS.jpg", category: 'Fashion', price: 1800, oldprice: 2000, size: 'Medium', brand: 'Brand A', color: 'Red', href: '#', ratings: '75%', reviews: '(15 reviews)' },

        { id: 1, name: 'ALBEN ORAL 10 1L PROFIL', image: "assets/images/products/ALBEN_ORAL 10_1L_PROFIL.jpg", category: 'Accessories', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)' },
        { id: 2, name: 'ALBENVET', image: "assets/images/products/ALBENVET_FACE.jpg", category: 'Fashion', price: 2500, oldprice: 2700, size: 'Large', brand: 'Brand B', color: 'Blue', href: '#', ratings: '85%', reviews: '(20 reviews)' },
        { id: 3, name: 'AMINOVIT PLUS 1KG ', image: "assets/images/products/AMINOVIT_PLUS_1KG_FACE.jpg", category: 'Electronics', price: 3000, oldprice: 3200, size: 'Large', brand: 'Brand C', color: 'Black', href: '#', ratings: '90%', reviews: '(30 reviews)' },
        { id: 4, name: 'AMINOVIT PLUS 150G ', image: "assets/images/products/AMINOVIT_PLUS_150G_FACE.jpg", category: 'Fashion', price: 1800, oldprice: 2000, size: 'Medium', brand: 'Brand A', color: 'Red', href: '#', ratings: '75%', reviews: '(15 reviews)' },
        { id: 5, name: 'AMOXY-C 1KG FACE', image: "assets/images/products/AMOXY-C_1KG_FACE.jpg", category: 'Accessories', price: 2200, oldprice: 2400, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)' },
        { id: 6, name: 'AMPROLE 200 1KG', image: "assets/images/products/AMPROLE_200_1KG_FACE.jpg", category: 'Accessories', price: 2500, oldprice: 2700, size: 'Large', brand: 'Brand B', color: 'Blue', href: '#', ratings: '85%', reviews: '(20 reviews)' },
        { id: 7, name: 'AMPROLE 200 SACHET DE 100G', image: "assets/images/products/AMPROLE_200_SACHET_DE_100G_FACE.jpg", category: 'Electronics', price: 3000, oldprice: 3200, size: 'Large', brand: 'Brand C', color: 'Black', href: '#', ratings: '90%', reviews: '(30 reviews)' },
        { id: 8, name: 'COLISTIN 5 BOU 100 ', image: "assets/images/products/COLISTIN_5_BOU_100_DOS.jpg", category: 'Fashion', price: 1800, oldprice: 2000, size: 'Medium', brand: 'Brand A', color: 'Red', href: '#', ratings: '75%', reviews: '(15 reviews)' },
    // { id: 10, name: 'Product 10', image: "assets/images/products/image1.jpg", category: 'Fashion', price: 2500, oldprice: 2700, size: 'Large', brand: 'Brand B', color: 'Blue', href: '#', ratings: '88%', reviews: '(18 reviews)' },
    // { id: 11, name: 'Product 11', image: "assets/images/products/image1.jpg", category: 'Electronics', price: 3000, oldprice: 3200, size: 'Large', brand: 'Brand C', color: 'Black', href: '#', ratings: '92%', reviews: '(22 reviews)' },
    // { id: 12, name: 'Product 12', image: "assets/images/products/image1.jpg", category: 'Fashion', price: 1800, oldprice: 2000, size: 'Medium', brand: 'Brand A', color: 'Red', href: '#', ratings: '79%', reviews: '(19 reviews)' },
    // { id: 13, name: 'Product 1', image: "assets/images/products/image1.jpg", category: 'Accessories', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)' },
    // { id: 14, name: 'Product 2', image: "assets/images/products/image1.jpg", category: 'Fashion', price: 2500, oldprice: 2700, size: 'Large', brand: 'Brand B', color: 'Blue', href: '#', ratings: '85%', reviews: '(20 reviews)' },
    // { id: 15, name: 'Product 3', image: "assets/images/products/image1.jpg", category: 'Electronics', price: 3000, oldprice: 3200, size: 'Large', brand: 'Brand C', color: 'Black', href: '#', ratings: '90%', reviews: '(30 reviews)' },
    // { id: 16, name: 'Product 4', image: "assets/images/products/image1.jpg", category: 'Fashion', price: 1800, oldprice: 2000, size: 'Medium', brand: 'Brand A', color: 'Red', href: '#', ratings: '75%', reviews: '(15 reviews)' },
    // { id: 17, name: 'Product 5', image: "assets/images/products/image1.jpg", category: 'Accessories', price: 2200, oldprice: 2400, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)' },
    // { id: 18, name: 'Product 6', image: "assets/images/products/image1.jpg", category: 'Accessories', price: 2500, oldprice: 2700, size: 'Large', brand: 'Brand B', color: 'Blue', href: '#', ratings: '85%', reviews: '(20 reviews)' },
    // { id: 19, name: 'Product 7', image: "assets/images/products/image1.jpg", category: 'Electronics', price: 3000, oldprice: 3200, size: 'Large', brand: 'Brand C', color: 'Black', href: '#', ratings: '90%', reviews: '(30 reviews)' },
    // { id: 20, name: 'Product 8', image: "assets/images/products/image1.jpg", category: 'Fashion', price: 1800, oldprice: 2000, size: 'Medium', brand: 'Brand A', color: 'Red', href: '#', ratings: '75%', reviews: '(15 reviews)' },
    // { id: 21, name: 'Product 9', image: "assets/images/products/image1.jpg", category: 'Electronics', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '85%', reviews: '(25 reviews)' },
    // { id: 22, name: 'Product 10', image: "assets/images/products/image1.jpg", category: 'Fashion', price: 2500, oldprice: 2700, size: 'Large', brand: 'Brand B', color: 'Blue', href: '#', ratings: '88%', reviews: '(18 reviews)' },
    // { id: 23, name: 'Product 11', image: "assets/images/products/image1.jpg", category: 'Electronics', price: 3000, oldprice: 3200, size: 'Large', brand: 'Brand C', color: 'Black', href: '#', ratings: '92%', reviews: '(22 reviews)' },
    // { id: 24, name: 'Product 12', image: "assets/images/products/image1.jpg", category: 'Fashion', price: 1800, oldprice: 2000, size: 'Medium', brand: 'Brand A', color: 'Red', href: '#', ratings: '79%', reviews: '(19 reviews)' },
  ]);

  

  

  const [filteredProducts, setFilteredProducts] = useState(products);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;

  const applyFilters = (filters) => {
    let filtered = [...products];
  
    // Filtrer par catégories multiples
    if (filters.categories && filters.categories.length > 0) {
      filtered = filtered.filter(product => filters.categories.includes(product.category));
    }
  
    // Filtrer par tailles multiples
    if (filters.sizes && filters.sizes.length > 0) {
      filtered = filtered.filter(product => filters.sizes.includes(product.size));
    }
  
    // Filtrer par marques multiples
    if (filters.brands && filters.brands.length > 0) {
      filtered = filtered.filter(product => filters.brands.includes(product.brand));
    }
  
    // Filtrer par couleurs multiples
    if (filters.colors && filters.colors.length > 0) {
      filtered = filtered.filter(product => filters.colors.includes(product.color));
    }
  
    setFilteredProducts(filtered);
    setCurrentPage(1); // Retour à la première page après le filtrage
  };
  

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastProduct = currentPage * itemsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);
  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);

  return (
    <div id="otherpage">
      <Header />
      <div className="App">
        <div className="container mt-5">
          {/* <div className="shop-default-banner shop-boxed-banner banner d-flex align-items-center mb-6" style={{ backgroundImage: `url(assets/images/shop/banner2.jpg)`, backgroundSize: "cover", backgroundPosition: "center" }}>
            <div className="container banner-content">
              <h4 className="banner-subtitle font-weight-bold">Accessories Collection</h4>
              <h3 className="banner-title text-white text-uppercase font-weight-bolder ls-10">Smart Watches</h3>
              <a href="shop-banner-sidebar.html" className="btn btn-dark btn-rounded btn-icon-right">Discover Now<i className="w-icon-long-arrow-right"></i></a>
            </div>
          </div> */}

          <div className="row">
            <div className="col-lg-3">
              <ShopSidebar applyFilters={applyFilters} />
            </div>
            <div className="col-lg-9">
            <ProductList />

              {/* <MainContent
                products={currentProducts}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                totalPages={totalPages}
                paginate={paginate}
                filteredCount={filteredProducts.length}
              /> */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DisplayData;

