import React, { useState, useEffect } from 'react';
import ShopSidebar from './ShopSidebar';
import MainContent from '../MainContent';
import Header from '../components/Header'; // Importation du composant Header
import Footer from '../components/Footer'; 
import { useNavigate } from 'react-router-dom'; // Utilisez useNavigate pour la redirection

const Shop = () => {
  const navigate = useNavigate(); 
  // useEffect(() => {
  //   const user = JSON.parse(localStorage.getItem('user'));
  //   console.log(user);
  //   if (!user) {
  //     navigate('/'); // Rediriger vers la page de connexion si l'utilisateur n'est pas connecté
  //   }
  // }, [navigate]);

  

const [products] = useState([
    { id: 1, name: 'Boulon 8x40mm', image: "assets/images/products/BOUL-8X40.png", category: 'Fixations', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)' },
    { id: 2, name: 'Boulon 8x50mm', image: "assets/images/products/BOUL-8X50.png", category: 'Fixations', price: 2500, oldprice: 2700, size: 'Large', brand: 'Brand B', color: 'Blue', href: '#', ratings: '85%', reviews: '(20 reviews)' },
    { id: 3, name: 'Boulon 8x60mm', image: "assets/images/products/BOUL-8X60.png", category: 'Fixations', price: 3000, oldprice: 3200, size: 'Large', brand: 'Brand C', color: 'Black', href: '#', ratings: '90%', reviews: '(30 reviews)' },
    { id: 4, name: 'Boulon 10x40mm', image: "assets/images/products/BOUL-10X40.png", category: 'Fixations', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)' },
    { id: 5, name: 'Boulon 10x50mm', image: "assets/images/products/BOUL-10X50.png", category: 'Fixations', price: 2500, oldprice: 2700, size: 'Large', brand: 'Brand B', color: 'Blue', href: '#', ratings: '85%', reviews: '(20 reviews)' },
    { id: 6, name: 'Boulon 10x60mm', image: "assets/images/products/BOUL-10X60.png", category: 'Fixations', price: 3000, oldprice: 3200, size: 'Large', brand: 'Brand C', color: 'Black', href: '#', ratings: '90%', reviews: '(30 reviews)' },
    { id: 7, name: 'Cheville M10 HKB', image: "assets/images/products/CHE-M10HKB.png", category: 'Fixations', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)' },
    { id: 8, name: 'EC-16', image: "assets/images/products/EC-16.png", category: 'Équipements', price: 2500, oldprice: 2700, size: 'Large', brand: 'Brand B', color: 'Blue', href: '#', ratings: '85%', reviews: '(20 reviews)' },
    { id: 9, name: 'EC-18', image: "assets/images/products/EC-18.png", category: 'Équipements', price: 3000, oldprice: 3200, size: 'Large', brand: 'Brand C', color: 'Black', href: '#', ratings: '90%', reviews: '(30 reviews)' },
    { id: 10, name: 'EC-20', image: "assets/images/products/EC-20.png", category: 'Équipements', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)' },
    { id: 11, name: 'EC-24', image: "assets/images/products/EC-24.png", category: 'Équipements', price: 2500, oldprice: 2700, size: 'Large', brand: 'Brand B', color: 'Blue', href: '#', ratings: '85%', reviews: '(20 reviews)' },
    { id: 12, name: 'EC-27', image: "assets/images/products/EC-27.png", category: 'Équipements', price: 3000, oldprice: 3200, size: 'Large', brand: 'Brand C', color: 'Black', href: '#', ratings: '90%', reviews: '(30 reviews)' },
    { id: 13, name: 'EC-M6', image: "assets/images/products/EC-M6.png", category: 'Équipements', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)' },
    { id: 14, name: 'EC-M8', image: "assets/images/products/EC-M8.png", category: 'Équipements', price: 2500, oldprice: 2700, size: 'Large', brand: 'Brand B', color: 'Blue', href: '#', ratings: '85%', reviews: '(20 reviews)' },
    { id: 15, name: 'EC-M10', image: "assets/images/products/EC-M10.png", category: 'Équipements', price: 3000, oldprice: 3200, size: 'Large', brand: 'Brand C', color: 'Black', href: '#', ratings: '90%', reviews: '(30 reviews)' },
    { id: 16, name: 'EC-M12', image: "assets/images/products/EC-M12.png", category: 'Équipements', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)' },
    { id: 17, name: 'EC-M14', image: "assets/images/products/EC-M14.png", category: 'Équipements', price: 2500, oldprice: 2700, size: 'Large', brand: 'Brand B', color: 'Blue', href: '#', ratings: '85%', reviews: '(20 reviews)' },
    { id: 18, name: 'P-AC10', image: "assets/images/products/P-AC10.jpg", category: 'Accessoires', price: 3000, oldprice: 3200, size: 'Large', brand: 'Brand C', color: 'Black', href: '#', ratings: '90%', reviews: '(30 reviews)' },
    { id: 19, name: 'Paumelle Encoche', image: "assets/images/products/PAUM-ENC.jpg", category: 'Accessoires', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)' },
    { id: 20, name: 'SFGK 1840', image: "assets/images/products/SFGK1840.jpg", category: 'Accessoires', price: 2500, oldprice: 2700, size: 'Large', brand: 'Brand B', color: 'Blue', href: '#', ratings: '85%', reviews: '(20 reviews)' },
    { id: 21, name: 'SFK 1425', image: "assets/images/products/SFK1425.jpg", category: 'Accessoires', price: 3000, oldprice: 3200, size: 'Large', brand: 'Brand C', color: 'Black', href: '#', ratings: '90%', reviews: '(30 reviews)' },
    { id: 22, name: 'TF 8', image: "assets/images/products/TF-8.png", category: 'Équipements', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)' },
    { id: 23, name: 'TF 20', image: "assets/images/products/TF-20.png", category: 'Équipements', price: 2500, oldprice: 2700, size: 'Large', brand: 'Brand B', color: 'Blue', href: '#', ratings: '85%', reviews: '(20 reviews)' },
    { id: 24, name: 'TF 24', image: "assets/images/products/TF-24.png", category: 'Équipements', price: 3000, oldprice: 3200, size: 'Large', brand: 'Brand C', color: 'Black', href: '#', ratings: '90%', reviews: '(30 reviews)' },
    { id: 25, name: 'TF M6', image: "assets/images/products/TF-M6.png", category: 'Équipements', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)' },
    { id: 26, name: 'TF M27', image: "assets/images/products/TF-M27.png", category: 'Équipements', price: 2500, oldprice: 2700, size: 'Large', brand: 'Brand B', color: 'Blue', href: '#', ratings: '85%', reviews: '(20 reviews)' },
    { id: 27, name: 'Tige Filetée M10', image: "assets/images/products/TIGE-FILETEE-M10.png", category: 'Équipements', price: 3000, oldprice: 3200, size: 'Large', brand: 'Brand C', color: 'Black', href: '#', ratings: '90%', reviews: '(30 reviews)' },
    { id: 28, name: 'TIR 8x80mm', image: "assets/images/products/TIR-8X80.png", category: 'Fixations', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)' },
    { id: 29, name: 'TIR 8x100mm', image: "assets/images/products/TIR-8X100.png", category: 'Fixations', price: 2500, oldprice: 2700, size: 'Large', brand: 'Brand B', color: 'Blue', href: '#', ratings: '85%', reviews: '(20 reviews)' },
    { id: 30, name: 'TIRF 8x40mm', image: "assets/images/products/TIRF-8X40.png", category: 'Fixations', price: 3000, oldprice: 3200, size: 'Large', brand: 'Brand C', color: 'Black', href: '#', ratings: '90%', reviews: '(30 reviews)' },
    { id: 31, name: 'TIRF 8x50mm', image: "assets/images/products/TIRF-8X50.png", category: 'Fixations', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)' },
    { id: 32, name: 'TIRF 8x60mm', image: "assets/images/products/TIRF-8X60.png", category: 'Fixations', price: 2500, oldprice: 2700, size: 'Large', brand: 'Brand B', color: 'Blue', href: '#', ratings: '85%', reviews: '(20 reviews)' },
    { id: 33, name: 'TIRF 10x40mm', image: "assets/images/products/TIRF-10X40.png", category: 'Fixations', price: 3000, oldprice: 3200, size: 'Large', brand: 'Brand C', color: 'Black', href: '#', ratings: '90%', reviews: '(30 reviews)' },
    { id: 34, name: 'TIRF 10x50mm', image: "assets/images/products/TIRF-10X50.png", category: 'Fixations', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)' },
    { id: 35, name: 'TIRF 10x60mm', image: "assets/images/products/TIRF-10X60.png", category: 'Fixations', price: 2500, oldprice: 2700, size: 'Large', brand: 'Brand B', color: 'Blue', href: '#', ratings: '85%', reviews: '(20 reviews)' },
    { id: 36, name: 'TIRF 10x80mm', image: "assets/images/products/TIRF-10X80.png", category: 'Fixations', price: 3000, oldprice: 3200, size: 'Large', brand: 'Brand C', color: 'Black', href: '#', ratings: '90%', reviews: '(30 reviews)' },
    { id: 37, name: 'Vis 3x16mm', image: "assets/images/products/VIS-3X16.png", category: 'Fixations', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)' },
    { id: 38, name: 'Vis 3x20mm', image: "assets/images/products/VIS-3X20.png", category: 'Fixations', price: 2500, oldprice: 2700, size: 'Large', brand: 'Brand B', color: 'Blue', href: '#', ratings: '85%', reviews: '(20 reviews)' },
    { id: 39, name: 'Vis 3x25mm', image: "assets/images/products/VIS-3X25.png", category: 'Fixations', price: 3000, oldprice: 3200, size: 'Large', brand: 'Brand C', color: 'Black', href: '#', ratings: '90%', reviews: '(30 reviews)' },
    { id: 40, name: 'Vis 4x20mm', image: "assets/images/products/VIS-4X20.png", category: 'Fixations', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)' },
    { id: 41, name: 'Vis 4x25mm', image: "assets/images/products/VIS-4X25.png", category: 'Fixations', price: 2500, oldprice: 2700, size: 'Large', brand: 'Brand B', color: 'Blue', href: '#', ratings: '85%', reviews: '(20 reviews)' },
    { id: 42, name: 'Vis 4x30mm', image: "assets/images/products/VIS-4X30.png", category: 'Fixations', price: 3000, oldprice: 3200, size: 'Large', brand: 'Brand C', color: 'Black', href: '#', ratings: '90%', reviews: '(30 reviews)' },
    { id: 43, name: 'Vis 4x35mm', image: "assets/images/products/VIS-4X35.png", category: 'Fixations', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)' },
    { id: 44, name: 'Vis 4x40mm', image: "assets/images/products/VIS-4X40.png", category: 'Fixations', price: 2500, oldprice: 2700, size: 'Large', brand: 'Brand B', color: 'Blue', href: '#', ratings: '85%', reviews: '(20 reviews)' },
    { id: 45, name: 'Vis L', image: "assets/images/products/VIS-L.png", category: 'Fixations', price: 3000, oldprice: 3200, size: 'Large', brand: 'Brand C', color: 'Black', href: '#', ratings: '90%', reviews: '(30 reviews)' },
    { id: 46, name: 'Vis R76', image: "assets/images/products/VIS-R76.png", category: 'Fixations', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)' },
    { id: 47, name: 'Vis RF', image: "assets/images/products/VIS-RF.png", category: 'Fixations', price: 2500, oldprice: 2700, size: 'Large', brand: 'Brand B', color: 'Blue', href: '#', ratings: '85%', reviews: '(20 reviews)' },
    { id: 48, name: 'Vis RPF', image: "assets/images/products/VIS-RPF.png", category: 'Fixations', price: 3000, oldprice: 3200, size: 'Large', brand: 'Brand C', color: 'Black', href: '#', ratings: '90%', reviews: '(30 reviews)' },



    { id: 1, name: 'Compacteur à Plaque à Essence', image: "assets/images/products/COMPACTEUR À PLAQUE À ESSENCE_Plan de travail 1.png", category: 'Équipement', price: 2000, oldprice: 2200, size: 'Medium', brand: 'Brand A', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)' },
    { id: 2, name: 'Compacteur à Plaque Réversible à Essence', image: "assets/images/products/COMPACTEUR À PLAQUE RÉVERSIBLE À ESSENCE_Plan de travail 1.png", category: 'Équipement', price: 2500, oldprice: 2700, size: 'Large', brand: 'Brand B', color: 'Blue', href: '#', ratings: '85%', reviews: '(20 reviews)' },
    { id: 3, name: 'Contreplaqué Bâcléisé', image: "assets/images/products/CONTREPLAQUÉ BÂCLÉISÉ_Plan de travail 1.png", category: 'Matériaux', price: 3000, oldprice: 3200, size: 'Large', brand: 'Brand C', color: 'Black', href: '#', ratings: '90%', reviews: '(30 reviews)' },
    { id: 4, name: 'EBTL08001', image: "assets/images/products/EBTL08001.png", category: 'Équipement', price: 1800, oldprice: 2000, size: 'Medium', brand: 'Brand D', color: 'Red', href: '#', ratings: '70%', reviews: '(5 reviews)' },
    { id: 5, name: 'ECMX18011', image: "assets/images/products/ECMX18011.png", category: 'Équipement', price: 2200, oldprice: 2400, size: 'Medium', brand: 'Brand E', color: 'Green', href: '#', ratings: '75%', reviews: '(8 reviews)' },
    { id: 6, name: 'GAB-2', image: "assets/images/products/GAB-2.jpg", category: 'Équipement', price: 2000, oldprice: 2300, size: 'Medium', brand: 'Brand F', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)' },
    { id: 7, name: 'IMG 4706', image: "assets/images/products/IMG_4706.png", category: 'Équipement', price: 2600, oldprice: 2800, size: 'Large', brand: 'Brand G', color: 'Blue', href: '#', ratings: '85%', reviews: '(12 reviews)' },
    { id: 8, name: 'Mélangeur Électrique', image: "assets/images/products/MÉLANGEUR ÉLECTRIQUE_Plan de travail 1.png", category: 'Équipement', price: 2900, oldprice: 3100, size: 'Large', brand: 'Brand H', color: 'White', href: '#', ratings: '90%', reviews: '(15 reviews)' },
    { id: 9, name: 'Meuleuse de Sol', image: "assets/images/products/MEULEUSE DE SOL_Plan de travail 1.png", category: 'Équipement', price: 3300, oldprice: 3500, size: 'Large', brand: 'Brand I', color: 'Gray', href: '#', ratings: '85%', reviews: '(10 reviews)' },
    { id: 10, name: 'Pilonneuse à Essence EMTOP', image: "assets/images/products/PILONNEUSE À ESSENCE EMTOP_Plan de travail 1_Plan de travail 1.png", category: 'Équipement', price: 3400, oldprice: 3600, size: 'Large', brand: 'Brand J', color: 'Yellow', href: '#', ratings: '88%', reviews: '(11 reviews)' },
    { id: 11, name: 'Pilonneuse à Essence', image: "assets/images/products/PILONNEUSE À ESSENCE_Plan de travail 1.png", category: 'Équipement', price: 3100, oldprice: 3300, size: 'Large', brand: 'Brand K', color: 'Orange', href: '#', ratings: '90%', reviews: '(12 reviews)' },
    { id: 12, name: 'Poutrelle de Coffrage', image: "assets/images/products/POUTRELLE DE COFFRAGE_Plan de travail 1.png", category: 'Matériaux', price: 2700, oldprice: 2900, size: 'Medium', brand: 'Brand L', color: 'Brown', href: '#', ratings: '75%', reviews: '(8 reviews)' },
    { id: 13, name: 'Pulvérisateur', image: "assets/images/products/PULVERISATEUR_Plan de travail 1.png", category: 'Équipement', price: 2500, oldprice: 2700, size: 'Medium', brand: 'Brand M', color: 'Black', href: '#', ratings: '80%', reviews: '(10 reviews)' },
    { id: 14, name: 'Règle Alu Vibrante', image: "assets/images/products/RÈGLE ALU VIBRANTE_Plan de travail 1.png", category: 'Équipement', price: 2200, oldprice: 2400, size: 'Medium', brand: 'Brand N', color: 'Silver', href: '#', ratings: '78%', reviews: '(9 reviews)' },
    { id: 15, name: 'Scie à Sol à Essence', image: "assets/images/products/SCIE À SOL À ESSENCE_Plan de travail 1.png", category: 'Équipement', price: 3000, oldprice: 3200, size: 'Large', brand: 'Brand O', color: 'Red', href: '#', ratings: '85%', reviews: '(12 reviews)' },
    { id: 16, name: 'SFX6409', image: "assets/images/products/SFX6409.png", category: 'Équipement', price: 2100, oldprice: 2300, size: 'Medium', brand: 'Brand P', color: 'Blue', href: '#', ratings: '77%', reviews: '(7 reviews)' },
    { id: 17, name: 'SFX6410', image: "assets/images/products/SFX6410.png", category: 'Équipement', price: 2200, oldprice: 2400, size: 'Medium', brand: 'Brand Q', color: 'Green', href: '#', ratings: '80%', reviews: '(10 reviews)' },
    { id: 18, name: 'SFX6412', image: "assets/images/products/SFX6412.png", category: 'Équipement', price: 2300, oldprice: 2500, size: 'Medium', brand: 'Brand R', color: 'Black', href: '#', ratings: '82%', reviews: '(11 reviews)' },
    { id: 19, name: 'SFX6414', image: "assets/images/products/SFX6414.png", category: 'Équipement', price: 2400, oldprice: 2600, size: 'Medium', brand: 'Brand S', color: 'Gray', href: '#', ratings: '85%', reviews: '(13 reviews)' },
    { id: 20, name: 'SFX6415', image: "assets/images/products/SFX6415.png", category: 'Équipement', price: 2500, oldprice: 2700, size: 'Medium', brand: 'Brand T', color: 'White', href: '#', ratings: '88%', reviews: '(14 reviews)' },
    { id: 21, name: 'TRU-7F', image: "assets/images/products/TRU-7F.png", category: 'Équipement', price: 2600, oldprice: 2800, size: 'Medium', brand: 'Brand U', color: 'Blue', href: '#', ratings: '90%', reviews: '(16 reviews)' },
    { id: 22, name: 'TRU-8F', image: "assets/images/products/TRU-8F.png", category: 'Équipement', price: 2700, oldprice: 2900, size: 'Medium', brand: 'Brand V', color: 'Black', href: '#', ratings: '85%', reviews: '(12 reviews)' },
    { id: 23, name: 'TRU-9F', image: "assets/images/products/TRU-9F.png", category: 'Équipement', price: 2800, oldprice: 3000, size: 'Medium', brand: 'Brand W', color: 'Red', href: '#', ratings: '82%', reviews: '(10 reviews)' },
    { id: 24, name: 'Truelle Mécanique', image: "assets/images/products/TRUELLE MÉCANIQUE_Plan de travail 1.png", category: 'Équipement', price: 2900, oldprice: 3100, size: 'Medium', brand: 'Brand X', color: 'Orange', href: '#', ratings: '88%', reviews: '(14 reviews)' },

  ]
  );

  const [filteredProducts, setFilteredProducts] = useState(products);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const itemsPerPage = 12;

  const handleSearch = (e) => {
    e.preventDefault();
    const filtered = products.filter(product =>
      product.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredProducts(filtered);
    setCurrentPage(1); // Retour à la première page après la recherche
  };

  const applyFilters = (filters) => {
    let filtered = [...products];
  
    if (filters.categories && filters.categories.length > 0) {
      filtered = filtered.filter(product => filters.categories.includes(product.category));
    }
  
    if (filters.sizes && filters.sizes.length > 0) {
      filtered = filtered.filter(product => filters.sizes.includes(product.size));
    }
  
    if (filters.brands && filters.brands.length > 0) {
      filtered = filtered.filter(product => filters.brands.includes(product.brand));
    }
  
    if (filters.colors && filters.colors.length > 0) {
      filtered = filtered.filter(product => filters.colors.includes(product.color));
    }
  
    setFilteredProducts(filtered);
    setCurrentPage(1); // Retour à la première page après le filtrage
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastProduct = currentPage * itemsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);
  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);

  return (
    <div id="otherpage">
      <Header />
      <div className="App">
        <div className="container mt-5">
          <div className="page-header mb-5">
            <div className="container">
              <h1 className="page-title mb-0">Catalogue</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <ShopSidebar applyFilters={applyFilters} />
            </div>
            <div className="col-lg-9">
              <div className="search-bar mb-4 w-100">
                
                <form className="header-search hs-round d-none d-md-flex w-100" onSubmit={handleSearch}>
                  <input
                    type="text"
                    className="form-control"
                    name="search"
                    id="search"
                    placeholder="Rechercher des produits..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <button className="btn btn-search" type="submit">
                    <i className="w-icon-search"></i>
                  </button>
                </form>
              </div>
              <MainContent
                products={currentProducts}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                totalPages={totalPages}
                paginate={paginate}
                filteredCount={filteredProducts.length}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Shop;
