import React, { useState } from 'react';
import StepWizard from 'react-step-wizard';
import Header from './components/Header';
import Footer from './components/Footer';
const Step1 = ({ nextStep, formData, setFormData }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="container bg-white mb-5">
      <div className="row mb-9 p-5">
        <div className="col-lg-12 pr-lg-4 mb-4">
          <h3 className="title billing-title text-uppercase ls-10 pt-1 pb-3 mb-0">
            Étape 1
          </h3>
          <p>Information générale sur le client</p>
          <div className="row gutter-sm">
            <div className="col-md-12">
              <div className="form-group">
                <label>Vous êtes* :</label>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="clientType"
                    value="cabinet_veterinaire"
                    checked={formData.clientType === 'cabinet_veterinaire'}
                    onChange={handleChange}
                    required
                  />
                  <label className="form-check-label">
                    Un cabinet vétérinaire
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="clientType"
                    value="pharmacie"
                    checked={formData.clientType === 'pharmacie'}
                    onChange={handleChange}
                    required
                  />
                  <label className="form-check-label">
                    Une Pharmacie
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row gutter-sm">
            <div className="col-md-12 text-right">
              <a className="btn btn-primary" onClick={nextStep}>
                Suivant
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


const Step2 = ({ nextStep, previousStep, formData, setFormData }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="container bg-white mb-5">
      <div className="row mb-9 p-5">
        <div className="col-lg-12 pr-lg-4 mb-4">
          <h3 className="title billing-title text-uppercase ls-10 pt-1 pb-3 mb-0">
            Étape 1
          </h3>
          <p>Information générale sur le client</p>
          <div className="row gutter-sm">
            {/* <div className="col-md-6">
              <div className="form-group">
                <label>Titre *</label>
                <input
                  type="text"
                  className="form-control form-control-md"
                  name="titre"
                  value={formData.titre || ''}
                  onChange={handleChange}
                  required
                />
              </div>
            </div> */}
            <div className="col-md-12">
              <div className="form-group">
                <label>Libellé Client (Nom commercial) *</label>
                <input
                  type="text"
                  className="form-control form-control-md"
                  name="libelle-client"
                  value={formData["libelle-client"] || ''}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
          </div>
          <div className="row gutter-sm">
            <div className="col-md-6">
              <div className="form-group">
                <label>Siret (NCC) *</label>
                <input
                  type="text"
                  className="form-control form-control-md"
                  name="siret"
                  value={formData.siret || ''}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Code NAF (Régime fiscal) *</label>
                <input
                  type="text"
                  className="form-control form-control-md"
                  name="code-naf"
                  value={formData["code-naf"] || ''}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
          </div>
          <div className="row gutter-sm">
            <div className="col-md-12">
              <div className="form-group">
                <label>Êtes-vous exonéré d'AIRSI ? *</label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exonere-airs"
                      value="oui"
                      checked={formData["exonere-airs"] === "oui"}
                      onChange={handleChange}
                      required
                    />
                    <label className="form-check-label">Oui</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exonere-airs"
                      value="non"
                      checked={formData["exonere-airs"] === "non"}
                      onChange={handleChange}
                      required
                    />
                    <label className="form-check-label">Non</label>
                  </div>
                </div>
                <small className="form-text text-muted">
                  Seuls les structures affectées au Régime du Réel Normal d’imposition (RNI) ou au Régime du Réel simplifié d’imposition (RSI) sont exonérées d'AIRSI.
                </small>
              </div>
            </div>
          </div>
          <div className="row gutter-sm">
            <div className="col-md-12 text-right">
              <a className="btn btn-secondary mr-2" onClick={previousStep}>
                Précédent
              </a>
              <a className="btn btn-primary" onClick={nextStep}>
                Suivant
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Step3 = ({ nextStep, previousStep, formData, setFormData }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="container bg-white mb-5">
      <div className="row mb-9 p-5">
        <div className="col-lg-12 pr-lg-4 mb-4">
          <h3 className="title billing-title text-uppercase ls-10 pt-1 pb-3 mb-0">
            Étape 3
          </h3>
          <p>Information de facturation et livraison</p>
          <div className="row gutter-sm">
            <div className="col-md-6">
              <div className="form-group">
                <label>Ville de Facturation *</label>
                <input
                  type="text"
                  className="form-control form-control-md"
                  name="ville-facturation"
                  value={formData["ville-facturation"] || ''}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Adresse de livraison *</label>
                <input
                  type="text"
                  className="form-control form-control-md"
                  name="adresse-livraison"
                  value={formData["adresse-livraison"] || ''}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
          </div>
          <div className="row gutter-sm">
            <div className="col-md-6">
              <div className="form-group">
                <label>Adresse E-mail *</label>
                <input
                  type="email"
                  className="form-control form-control-md"
                  name="email"
                  value={formData.email || ''}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Téléphone Société *</label>
                <input
                  type="text"
                  className="form-control form-control-md"
                  name="telephone-societe"
                  value={formData["telephone-societe"] || ''}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
          </div>
          <div className="row gutter-sm">
            <div className="col-md-12 text-right">
              <a className="btn btn-secondary mr-2" onClick={previousStep}>Précédent</a>
              <a className="btn btn-primary" onClick={nextStep}>Suivant</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


const Step4 = ({ nextStep, previousStep, formData, setFormData }) => {
  const handleChange = (e) => {
    const { name, files } = e.target;
    setFormData({ ...formData, [name]: URL.createObjectURL(files[0]) });
  };

  return (
    <div className="container bg-white mb-5">
      <div className="row mb-9 p-5">
        <div className="col-lg-12 pr-lg-4 mb-4">
          <h3 className="title billing-title text-uppercase ls-10 pt-1 pb-3 mb-0">
            Étape 4
          </h3>
          <p>Télécharger les documents justificatifs</p>
          <div className="row gutter-sm">
            <div className="col-md-4">
              <div className="form-group">
                <label>CNI gérant*</label>
                <input
                  type="file"
                  className="form-control form-control-md"
                  name="cni-gerant"
                  onChange={handleChange}
                />
                {formData["cni-gerant"] && <img src={formData["cni-gerant"]} alt="CNI gérant" height={100} className="img-preview mb-5" />}
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>DFE*</label>
                <input
                  type="file"
                  className="form-control form-control-md"
                  name="dfe"
                  onChange={handleChange}
                />
                {formData.dfe && <img src={formData.dfe} alt="DFE" height={100} className="img-preview mb-5" />}
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>RCCM*</label>
                <input
                  type="file"
                  className="form-control form-control-md"
                  name="rccm"
                  onChange={handleChange}
                />
                {formData.rccm && <img src={formData.rccm} alt="RCCM" height={100} className="img-preview mb-5" />}
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Plan de localisation*</label>
                <input
                  type="file"
                  className="form-control form-control-md"
                  name="plan-localisation"
                  onChange={handleChange}
                />
                {formData["plan-localisation"] && <img src={formData["plan-localisation"]} alt="Plan de localisation" height={100} className="img-preview mb-5" />}
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Attestation d’exonération d’AIRSI*</label>
                <input
                  type="file"
                  className="form-control form-control-md"
                  name="attestation-airs"
                  onChange={handleChange}
                />
                {formData["attestation-airs"] && <img src={formData["attestation-airs"]} alt="Attestation d’exonération d’AIRSI" height={100} className="img-preview mb-5" />}
              </div>
            </div>
          </div>
          <div className="row gutter-sm">
            <div className="col-md-12 text-right">
              <a className="btn btn-secondary mr-2" onClick={previousStep}>Précédent</a>
              <a className="btn btn-primary" onClick={nextStep}>Suivant</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


const Summary = ({ previousStep, formData }) => (
  <div className="container bg-white mb-5">
    <div className="row mb-9 p-5">
      <div className="col-lg-12 pr-lg-4 mb-4">
        <h3 className="title billing-title text-uppercase ls-10 pt-1 pb-3 mb-0">
          Résumé
        </h3>
        <div className="row gutter-sm mb-4">
          <div className="col-md-12">
            <h4>Informations Générales</h4>
            <ul>
              <li><strong>Vous êtes :</strong> {formData.clientType === 'cabinet_veterinaire' ? 'Un cabinet vétérinaire' : 'Une Pharmacie'}</li>
              <li><strong>Libellé Client (Nom commercial):</strong> {formData["libelle-client"]}</li>
              <li><strong>Siret (NCC):</strong> {formData.siret}</li>
              <li><strong>Code NAF (Régime fiscal):</strong> {formData["code-naf"]}</li>
              <li><strong>Êtes-vous exonéré d'AIRSI ? :</strong> {formData["exonere-airs"] === "oui" ? 'Oui' : 'Non'}</li>
              <li><strong>Ville de Facturation:</strong> {formData["ville-facturation"]}</li>
              <li><strong>Adresse de livraison:</strong> {formData["adresse-livraison"]}</li>
              <li><strong>Adresse E-mail:</strong> {formData.email}</li>
              <li><strong>Téléphone Société:</strong> {formData["telephone-societe"]}</li>
            </ul>
          </div>
        </div>
        <div className="row gutter-sm mb-4">
          <div className="col-md-12">
            <h4>Documents Téléchargés</h4>
            <div className="row">
              {formData["cni-gerant"] && (
                <div className="col-lg-4 mb-3">
                  <strong>CNI gérant:</strong>
                  <img src={formData["cni-gerant"]} alt="CNI gérant" className="img-preview img-fluid" />
                </div>
              )}
              {formData.dfe && (
                <div className="col-lg-4 mb-3">
                  <strong>DFE:</strong>
                  <img src={formData.dfe} alt="DFE" className="img-preview img-fluid" />
                </div>
              )}
              {formData.rccm && (
                <div className="col-lg-4 mb-3">
                  <strong>RCCM:</strong>
                  <img src={formData.rccm} alt="RCCM" className="img-preview img-fluid" />
                </div>
              )}
              {formData["plan-localisation"] && (
                <div className="col-lg-4 mb-3">
                  <strong>Plan de localisation:</strong>
                  <img src={formData["plan-localisation"]} alt="Plan de localisation" className="img-preview img-fluid" />
                </div>
              )}
              {formData["attestation-airs"] && (
                <div className="col-lg-4 mb-3">
                  <strong>Attestation d’exonération d’AIRSI:</strong>
                  <img src={formData["attestation-airs"]} alt="Attestation d’exonération d’AIRSI" className="img-preview img-fluid" />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row gutter-sm">
          <div className="col-md-12 text-right">
            <a className="btn btn-secondary mr-2" onClick={previousStep}>Précédent</a>
            <button className="btn btn-success" type="submit">Save Client</button>
          </div>
        </div>
      </div>
    </div>
  </div>
);


const SaveClient = () => {
  const [formData, setFormData] = useState({});

  return (
    <>
      <div id="otherpage">
        <Header />
        <div className="page-content container">
        <div className="page-header mb-5 mt-5">
            <div className="container">
              <h1 className="page-title mb-0">Inscription client</h1>
            </div>
          </div>
          <form className="form checkout-form" action="#" method="post">
            <StepWizard>
              <Step1 formData={formData} setFormData={setFormData} />
              <Step2 formData={formData} setFormData={setFormData} />
              <Step3 formData={formData} setFormData={setFormData} />
              <Step4 formData={formData} setFormData={setFormData} />
              <Summary formData={formData} />
            </StepWizard>
          </form>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default SaveClient;
