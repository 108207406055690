// App.js
import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import AchatComponents from './pages/paiement/AchatComponents';
import IndexSite from './pages/siteweb/IndexSite';
//import About from './pages/siteweb/About';
import About  from './pages/siteweb/about.js';
import Shop from './pages/siteweb/Shop';
import ContactUs  from './pages/siteweb/ContactUs.js';
import Catalogue from './pages/catalogue/Catalogue'


import Detail from './pages/detail/Detail';
import WarningContent from './pages/Accueil/WarningContent';
import Accueil from './pages/Accueil/Accueil';
import Register from './pages/Register';
import CartComponent from './pages/CartComponent';
import Checkout from './pages/Checkout';
import DetailProductComponent from './pages/DetailProductComponent';
import SaveClient from './pages/SaveClient';
import LoginPopup from './pages/LoginPopup';

import DisplayData from './data_8sens/DisplayData.js';
import Contact from './pages/pageContact/Contact.js';
import PartnerList from './pages/partenaire/PartnerList.js';

function App() {
  return (
    <div>
      <Router basename="/">
        <Routes>
          <Route path="/extranet-home" element={<Accueil />} />
          <Route path="/" element={<IndexSite />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/catalogue" element={<Catalogue />} />


          <Route path="/shop" element={<Shop />} />
          <Route path="/cart" element={<CartComponent />} />
          <Route path="/checkout" element={<Checkout />} />

          <Route path="/detail-produit" element={<DetailProductComponent />} />
          <Route path="/saveclient" element={<SaveClient />} />
          {/* <Route path="/login" element={<LoginPopup />} /> */}
          <Route path="/paiement" element={<AchatComponents />} />
          <Route path="/display-data" element={<DisplayData />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/partenaire" element={<PartnerList />} />

          {/* Routes de tableau de bord avec sidebar */}
          {/* <Route element={<DashboardLayout />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/dashboard/factures/historique" element={<Orders />} /> 
            <Route path="/dashboard/commandes/historique" element={<Products />} /> 
            <Route path="/dashboard/performances/croissance" element={<SalesAnalytics />} /> 
            <Route path="/dashboard/commandes/acheter-nouveau" element={<RecentSales />} /> 
            <Route path="/dashboard/administration/informations" element={<Usersinfo />} /> 
            <Route path="/dashboard/customers" element={<Customers />} /> 
            <Route path="/dashboard/products" element={<Products />} /> 
          </Route> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
