import React, { useState, useRef, useEffect, useContext } from 'react';
import { useTheme } from '../../contexts/ThemeProvider';
import { NavLink } from 'react-router-dom';
import LogoHeader from './LogoHeader';
import { HeaderContext } from '../../contexts/HeaderContext'; // Importer le contexte
import DropdownMenu from './DropdownMenu'; // Importer le composant DropdownMenu
import Menu from './Menu';  // assurez-vous que le chemin est correct
import MainMenu from './menu/MainMenu';  // assurez-vous que le chemin est correct
import CategoryMenu from './CategoryMenu';  // assurez-vous que le chemin est correct
import Dropdown from './Dropdown';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { doConnexion } from '../../services/apiService';
import MainNav from './MainNav';
import { Link } from 'react-router-dom'; // Importation de Link de react-router-dom pour la navigation


const Header = ({ logo = "assets/images/logo.png" }) => {

  const fullUrl = localStorage.getItem("fullUrl");


  const { theme, toggleTheme } = useTheme();
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [isCartVisible, setIsCartVisible] = useState(false); // Nouvel état pour le panier
  const [cartItems, setCartItems] = useState([]); // État local pour les éléments du panier
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [showDropdown, setShowDropdown] = useState(false); // État pour la visibilité du dropdown
  const [encours, setEncours] = useState(null); // Nouvel état pour l'encours
  const searchInputRef = useRef(null);
  const searchIconRef = useRef(null);
  const cartIconRef = useRef(null); // Référence pour l'icône du panier

  // LOGIN SYSTEME
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const params = new URLSearchParams();
      params.append('mode', 'doConnexion');
      params.append('STR_UTILOGIN', email);
      params.append('STR_UTIPASSWORD', password);

      const response = await doConnexion(params);
      const userData = response.data;

      if (userData.code_statut === "1") {
        localStorage.setItem('user', JSON.stringify(userData));
        setEncours(userData.encours); // Stocker l'encours de l'utilisateur
        navigate('/extranet-home'); // Rediriger vers le tableau de bord après connexion réussie
      } else {
        setError(userData.desc_statut);
      }
    } catch (error) {
      setError('Erreur de connexion. Veuillez réessayer.');
    }
  };

  const { searchTerm, setSearchTerm, handleSearchSubmit } = useContext(HeaderContext); // Utiliser le contexte

  const toggleButton = () => {
    toggleTheme();
  };

  const toggleSearchVisibility = (event) => {
    event.preventDefault();
    setIsSearchVisible(!isSearchVisible);
    if (!isSearchVisible) {
      setTimeout(() => {
        searchInputRef.current.focus();
      }, 500); // delay to match the CSS transition duration
    }
    if (isCartVisible) {
      setIsCartVisible(false);
    }
  };

  const toggleCartVisibility = (event) => {
    event.preventDefault();
    setIsCartVisible(!isCartVisible);
    if (isSearchVisible) {
      setIsSearchVisible(false);
    }
  };

  const handleBlur = () => {
    // Add a timeout to allow click event on search icon to register before hiding the form
    setTimeout(() => {
      if (document.activeElement !== searchInputRef.current) {
        setIsSearchVisible(false);
      }
    }, 200);
  };

  const handleClickOutside = (event) => {
    if (
      searchInputRef.current &&
      !searchInputRef.current.contains(event.target) &&
      !searchIconRef.current.contains(event.target) &&
      cartIconRef.current &&
      !cartIconRef.current.contains(event.target)
    ) {
      setIsSearchVisible(false);
      setIsCartVisible(false); // Fermer le panier si clic en dehors
      setShowDropdown(false); // Fermer le dropdown si clic en dehors
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    setCartItems(storedCartItems);
  }, []);

  const user = JSON.parse(localStorage.getItem('user'));

  const isActiveFunc = (match, location) => {
    return match !== null;
  };

  const renderEncoursAlert = () => {
    const encours = 30000;
    if (encours === null) return null;

    if (encours > 10000) {
      return (
        <div className="alert alert-danger w-100 mx-auto" role="alert">
          Attention ! Votre encours est élevé : {encours} FCFA.
        </div>
      );
    } else if (encours > 5000) {
      return (
        <div className="alert alert-warning w-100 mx-auto" role="alert">
          Attention, votre encours est : {encours} FCFA.
        </div>
      );
    } else if (encours < 2000) {
      return (
        <div className="alert alert-success w-100 mx-auto" role="alert">
          Félicitations, votre encours est bas : {encours} FCFA.
        </div>
      );
    } else {
      return (
        <div className="alert alert-info  w-100 mx-auto" role="alert">
          Votre encours est : {encours} FCFA.
        </div>
      );
    }
  };

  return (
    <>
      <header className="header">
        <div className="header-top"> </div>
        <div
          className="header-middle sticky-header"
          data-sticky-options="{'mobile': true}"
        >
          <div className="container">
            <div className="header-left col-lg-2 w-auto pl-0">
              <button className="mobile-menu-toggler mr-2" type="button">
                <i className="fas fa-bars" />
              </button>
              <NavLink to={"/"}  className="logo"> 
                <img src={fullUrl + "assets/images/logo.png"} alt="Porto Logo" width={300} />
                <img className='logo-white' src={fullUrl + "assets/images/logo.png"} alt="Porto Logo"   />
              </NavLink>
            </div>
            <div className="header-right w-lg-max">
              <div className="header-icon header-icon header-search header-search-inline header-search-category w-lg-max ml-3 mr-xl-4">
                <a href="#" className="search-toggle" role="button">
                  <i className="icon-search-3" />
                </a>
                <form action="#" method="get">
                  <div className="header-search-wrapper">
                    <input
                      type="search"
                      className="form-control"
                      name="q"
                      id="q"
                      placeholder="Search..."
                      required=""
                    />
                    {/* <div className="select-custom">
                      <select id="cat" name="cat">
                        <option value="">All Categories</option>
                        <option value={4}>Fashion</option>
                        <option value={12}>- Women</option>
                        <option value={13}>- Men</option>
                        <option value={66}>- Jewellery</option>
                        <option value={67}>- Kids Fashion</option>
                        <option value={5}>Electronics</option>
                        <option value={21}>- Smart TVs</option>
                        <option value={22}>- Cameras</option>
                        <option value={63}>- Games</option>
                        <option value={7}>Home &amp; Garden</option>
                        <option value={11}>Motors</option>
                        <option value={31}>- Cars and Trucks</option>
                        <option value={32}>- Motorcycles &amp; Powersports</option>
                        <option value={33}>- Parts &amp; Accessories</option>
                        <option value={34}>- Boats</option>
                        <option value={57}>- Auto Tools &amp; Supplies</option>
                      </select>
                    </div> */}
                    <button className="btn icon-magnifier" type="submit" />
                  </div>
                </form>
              </div>
              <div className="header-contact d-none d-lg-flex align-items-center ml-auto pl-1  pr-xl-2">
                <i className="icon-phone-2" />
                <h6 className="pt-1">
                Contactez nous
                  <a href="tel:#" className="text-dark font1">
                  +225 07 17 77 77 73
                  </a>
                </h6>
              </div>
            
            </div>
          </div>
        </div>
        <div className="header-bottom sticky-header d-lg-block d-none" data-sticky-options="{'mobile': false}"
        >
          <div className="container">
            <div className="header-left">
              <a href="demo5.html" className="logo">
                <img src={fullUrl + "assets/images/logo-white.png"} alt="Porto Logo" />
              </a>
            </div>
            <Menu />
            
          </div>
        </div>
       
      </header>

      
    </>
  );
}

export default Header;
