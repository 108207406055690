import './menu.css'; // Assurez-vous de bien référencer le fichier CSS
import { NavLink } from 'react-router-dom';


const menuItems = [
  {
    label: "Home",
    href: "/",
    submenu: null,
  },
  { label: "A propos de nous", href: "/about" },
  // {
  //   label: "Categories",
  //   href: "demo5-shop.html",
  //   submenu: [
  //     {
  //       label: "VARIATION 1",
  //       items: [
  //         { label: "Fullwidth Banner", href: "category.html" },
  //         { label: "Boxed Slider Banner", href: "category-banner-boxed-slider.html" },
  //         { label: "Boxed Image Banner", href: "category-banner-boxed-image.html" },
  //         { label: "Left Sidebar", href: "category.html" },
  //         { label: "Right Sidebar", href: "category-sidebar-right.html" },
  //         { label: "Off Canvas Filter", href: "category-off-canvas.html" },
  //         { label: "Horizontal Filter1", href: "category-horizontal-filter1.html" },
  //         { label: "Horizontal Filter2", href: "category-horizontal-filter2.html" },
  //       ],
  //     },
  //     {
  //       label: "VARIATION 2",
  //       items: [
  //         { label: "List Types", href: "category-list.html" },
  //         { label: "Ajax Infinite Scroll", href: "category-infinite-scroll.html" },
  //         { label: "3 Columns Products", href: "category.html" },
  //         { label: "4 Columns Products", href: "category-4col.html" },
  //         { label: "5 Columns Products", href: "category-5col.html" },
  //         { label: "6 Columns Products", href: "category-6col.html" },
  //         { label: "7 Columns Products", href: "category-7col.html" },
  //         { label: "8 Columns Products", href: "category-8col.html" },
  //       ],
  //     },
  //   ],
  // },
  {
    label: "Products",
    href: "/shop",
  },
  // {
  //   label: "Pages",
  //   href: "#",
  //   submenu: [
  //     { label: "Wishlist", href: "wishlist.html" },
  //     { label: "Shopping Cart", href: "cart.html" },
  //     { label: "Checkout", href: "checkout.html" },
  //     { label: "Dashboard", href: "dashboard.html" },
     
  //     {
  //       label: "Blog",
  //       submenu: [
  //         { label: "Blog", href: "blog.html" },
  //         { label: "Blog Post", href: "single.html" },
  //       ],
  //     },
  //     { label: "Contact Us", href: "demo5-contact.html" },
  //     { label: "Login", href: "login.html" },
  //     { label: "Forgot Password", href: "forgot-password.html" },
  //   ],
  // },
  // {
  //   label: "Blog",
  //   href: "blog.html",
  //   submenu: null,
  // },
  // {
  //   label: "Elements",
  //   href: "#",
  //   submenu: [
  //     { label: "Accordion", href: "element-accordions.html" },
  //     { label: "Alerts", href: "element-alerts.html" },
  //     { label: "Animations", href: "element-animations.html" },
  //     { label: "Banners", href: "element-banners.html" },
  //     { label: "Buttons", href: "element-buttons.html" },
  //     { label: "Call to Action", href: "element-call-to-action.html" },
  //     { label: "Count Down", href: "element-countdown.html" },
  //     { label: "Counters", href: "element-counters.html" },
  //     { label: "Headings", href: "element-headings.html" },
  //     { label: "Icons", href: "element-icons.html" },
  //     { label: "Info box", href: "element-info-box.html" },
  //     { label: "Posts", href: "element-posts.html" },
  //     { label: "Products", href: "element-products.html" },
  //     { label: "Product Categories", href: "element-product-categories.html" },
  //     { label: "Tabs", href: "element-tabs.html" },
  //     { label: "Testimonials", href: "element-testimonial.html" },
  //   ],
  // },
  {
    label: "Nos catalogues",
    href: "/catalogue",
    submenu: null,
  },
  {
    label: "Nos contacts",
    href: "/contact",
    submenu: null,
  },
  // {
  //   label: "Buy Porto!",
  //   href: "https://1.envato.market/DdLk5",
  //   className: "float-right",
  //   target: "_blank",
  //   submenu: null,
  // },
  // {
  //   label: "Special Offer!",
  //   href: "#",
  //   className: "float-right mr-3",
  //   submenu: null,
  // },
];

const renderMenu = (items) => {
  return items.map((item, index) => (
    <li key={index} className={item.className || ""}>
      <NavLink to={item.href} activeClassName="active">
        {item.label}
      </NavLink>
      {item.submenu && (
        <ul className="submenu">
          {item.submenu.map((subitem, subIndex) => (
            <li key={subIndex}>
              <NavLink to={subitem.href}>
                {subitem.label}
              </NavLink>
              {subitem.items && (
                <ul className="submenu">
                  {subitem.items.map((subSubitem, subSubIndex) => (
                    <li key={subSubIndex}>
                      <NavLink to={subSubitem.href}>{subSubitem.label}</NavLink>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      )}
    </li>
  ));
};

const Menu = () => (
  <nav className="main-nav w-100 w-lg-max bg-primary">
    <ul className="menu">{renderMenu(menuItems)}</ul>
  </nav>
);

export default Menu;