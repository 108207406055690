import React from 'react';
import Product from './siteweb/Product';

const MainContent = ({ products, itemsPerPage, currentPage, totalPages, paginate, filteredCount }) => {
  return (
    <div>
      <div className="row">
        {products.map((product) => (
          <Product key={product.id} product={product} col_css="col-xl-3 col-lg-3 col-2"/>
        ))}
      </div>

      <div className="toolbox toolbox-pagination justify-content-between">
        <p className="showing-info mb-2 mb-sm-0">
          Showing <span>{(currentPage - 1) * itemsPerPage + 1}-{Math.min(currentPage * itemsPerPage, filteredCount)} of {filteredCount}</span> Products
        </p>
        <ul className="pagination">
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>Prev</button>
          </li>
          {[...Array(totalPages)].map((_, i) => (
            <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
              <button className="page-link" onClick={() => paginate(i + 1)}>{i + 1}</button>
            </li>
          ))}
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MainContent;
