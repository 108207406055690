// ProductCard.js
import React from 'react';
import { NavLink } from 'react-router-dom';

const ProductCard = ({ product, handleClick, userData }) => {
  return (
    <div className="col-xl-3 col-lg-3 col-2">
      <NavLink to={"/detail-produit"} onClick={() => handleClick(product.ArtID)}>
        <div className="product-wrap product text-center">
          <figure className="product-media w-100">
            <a href={product.href}>
              <img src={product.image || 'assets/images/products/ALBEN_ORAL 10_1L_PROFIL.jpg'} alt={product.ArtLib} width="216" height="243" />
            </a>
            <div className="product-label-group">
              <label className="product-label label-discount">Nouveau</label>
            </div>
            <div className="product-action-vertical">
              <a href="#" className="btn-product-icon btn-cart w-icon-cart" title="Ajouter au panier"></a>
              <a href="#" className="btn-product-icon btn-wishlist w-icon-heart" title="Add to wishlist"></a>
              <a href="#" className="btn-product-icon btn-quickview w-icon-search" title="Quickview"></a>
              <a href="#" className="btn-product-icon btn-compare w-icon-compare" title="Add to Compare"></a>
            </div>
          </figure>
          <div className="product-details">
            <h4 className="product-name"><a href={product.href}>{product.ArtLib}</a></h4>
            {userData ? (
              <div className="product-price">
                <ins className="new-price">{product.ArtPrixBase}</ins>
                <del className="old-price">{product.oldprice}</del>
              </div>
            ) : (
              <div className="product-price">
                {/* <span>Connectez-vous pour voir le prix</span> */}
              </div>
            )}
          </div>
        </div>
      </NavLink>
    </div>
  );
};

export default ProductCard;
