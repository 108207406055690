import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from '../components/Header'; // Importation du composant Header
import Footer from '../components/Footer'; 

const ContactezNous = () => {

  return (
    <>
      <div id='siteWebwrapper'>
        <div id="otherpage">
          <Header />
          <div className="App">
            <div id='site-web' className="container justify-content">
              <>
              <main className="main contact-two">
                <nav aria-label="breadcrumb" className="breadcrumb-nav mb-0">
                    <div className="container">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <a href="demo5.html">Accueil</a>
                        </li>
                        <li className="breadcrumb-item">
                          <a  >Pages</a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                          Contactez-nous
                        </li>
                      </ol>
                    </div>
                </nav>
                <div className=" bg-white mb-0 p-4">
                  <div className="row pt-1 mt-1 pb-2 pb-2">
                    <div className="col-md-6">
                      <h2 className="heading ls-0 mb-1">
                        <strong>Contactez-nous</strong>
                      </h2>
                      <form action="#">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group required-field">
                              <label htmlFor="contact-name">Votre nom</label>
                              <input type="text" className="form-control" id="contact-name" name="contact-name" required />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group required-field">
                              <label htmlFor="contact-email">Votre adresse email</label>
                              <input type="email" className="form-control" id="contact-email" name="contact-email" required />
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="contact-subject">Sujet</label>
                          <input type="text" className="form-control" id="contact-subject" name="contact-subject" />
                        </div>
                        <div className="form-group mb-0">
                          <label htmlFor="contact-message">Votre Message</label>
                          <textarea cols={30} rows={1} id="contact-message" className="form-control" name="contact-message" required defaultValue={""} />
                        </div>
                        <div className="form-footer">
                          <button type="submit" className="btn btn-primary">Envoyer le message</button>
                        </div>
                      </form>
                    </div>
                    <div className="col-md-6">
                      <h2 className="contact-title">
                        <strong>Entrer en contact</strong>
                      </h2>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur eget leo at velit imperdiet varius. In eu ipsum vitae velit congue iaculis vitae at risus. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                      <hr className="mt-3 mb-0" />
                      <div className="contact-info mb-2 pt-2">
                        <h2 className="contact-title">
                          <strong>Le bureau</strong>
                        </h2>
                        <div className="porto-sicon-box d-flex align-items-center">
                          <div className="porto-icon">
                            <i className="fas fa-map-marker-alt" />
                          </div>
                          <h3 className="porto-sicon-title">
                            <strong>Adresse :</strong> 1234 Rue Nom, Ville, États-Unis
                          </h3>
                        </div>
                        <div className="porto-sicon-box d-flex align-items-center">
                          <div className="porto-icon">
                            <i className="fa fa-phone" />
                          </div>
                          <h3 className="porto-sicon-title">
                            <strong>Téléphone :</strong> (123) 456-7890
                          </h3>
                        </div>
                        <div className="porto-sicon-box d-flex align-items-center">
                          <div className="porto-icon">
                            <i className="fa fa-envelope" />
                          </div>
                          <h3 className="porto-sicon-title">
                            <strong>Email :</strong> mail@example.com
                          </h3>
                        </div>
                      </div>
                      <hr className="mt-1 mb-0" />
                      <div className="contact-time pt-2">
                        <h2 className="contact-title">
                          <strong>Heures d'ouverture</strong>
                        </h2>
                        <div className="porto-sicon-box d-flex align-items-center">
                          <div className="porto-icon">
                            <i className="far fa-clock" />
                          </div>
                          <h3 className="porto-sicon-title">Lundi - Vendredi de 9h à 17h</h3>
                        </div>
                        <div className="porto-sicon-box d-flex align-items-center">
                          <div className="porto-icon">
                            <i className="far fa-clock" />
                          </div>
                          <h3 className="porto-sicon-title">Samedi - de 9h à 14h</h3>
                        </div>
                        <div className="porto-sicon-box d-flex align-items-center">
                          <div className="porto-icon">
                            <i className="far fa-clock" />
                          </div>
                          <h3 className="porto-sicon-title">Dimanche - Fermé</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
              </>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default ContactezNous;
